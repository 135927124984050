import CouponObj from '../coupons/couponObj';
import Helper from '../../utils/Helper';
import { mightyPointsToCurrency } from '../customer/mightyPointsUtils';
import { ICartItems } from '../cart/interfaces';
import ProductObj from '../products/productObj';
import CustomerObj from '../customer/customerObj';

type Month = "short" | "numeric" | "2-digit" | "long" | "narrow";
type Weekday = "short" | "long" | "narrow" | undefined;

export default class OrderObj {
  data: Record<string, any>;

  constructor(data: Record<string, any>) {
    this.data = data;
  }

  addGiftCardMeta = (
    recipientEmail: string,
    msg: string = '',
    isGift: boolean = false
  ) => {
    this.removeGiftCardMeta();

    this.data.meta_data.push({
      "key": "gift_receiver_email",
      "value": { '51165': [recipientEmail], '0': [] }
    });
    this.data.meta_data.push({
      "key": "gift_receiver_message",
      "value": { '51165': [msg], '0': [] }
    });
    this.data.meta_data.push({
      "key": "is_gift",
      "value": isGift ? "yes" : "no"
    });
  }

  addPointsToMeta = (points: number, discountCode: string, MPConversionRate?: any) => {
    const MightyPointToCurrency = MPConversionRate ?
      mightyPointsToCurrency(points, false, MPConversionRate) : mightyPointsToCurrency(points);
    this.removePointsFromMeta();
    this.data.meta_data.push({
      "key": "_wc_points_logged_redemption",
      "value": {
        "points": points,
        "amount": MightyPointToCurrency,
        "discount_code": discountCode
      }
    });
  }

  addSmartCouponToMeta = (coupon: CouponObj, cartTotal: number) => {
    this.removeSmartCouponFromMeta();

    let value: Record<string, any> = {}
    value[coupon.data.code] = coupon.getSmartCouponAmount(cartTotal);
    this.data.meta_data.push({
      "key": "smart_coupons_contribution",
      "value": value
    })

  }

  ageInSeconds = () => {
    // So far, we only use this to age out penging orders so, if date_modified
    // isn't present, we'll return 1 day in seconds;
    if (!('date_modified' in this.data)) return 24 * 3600;

    const dateNow = new Date();
    const timestampNow = dateNow.getTime();
    const orderDate = new Date(this.data.date_modified);
    const orderTimestamp = orderDate.getTime();

    return (timestampNow - orderTimestamp) / 1000;
  }

  getAuthNetTransId = () => {
    const meta = this.#getMetaItem("_authorize_net_trans_id");

    return meta ? meta.value : null;
  }

  getDate = (mLength: Month = 'short') => {
    let date;
    if (typeof this.data.date_created === 'object') {
      date = new Date(this.data.date_created.date);
    } else {
      date = new Date(this.data.date_created);
    }
    return date.toLocaleDateString('en-US',
      { month: mLength, day: 'numeric', year: 'numeric' });
  }

  getDeliveryAddress = () => {
    const shipping = this.data.shipping;
    return [shipping.address_1, shipping.address_2, shipping.city,
    shipping.state + ' ' + shipping.postcode].filter(Boolean).join(', ');
  }

  getDeliveryDateTime = () => {
    const meta = this.#getMetaItem("_delivery_date");

    if (!meta) return false;

    return new Date(meta.value + 'T00:00:00');
  }

  getFullDeliveryDate = () => {
    const meta = this.#getMetaItem("_delivery_date");

    if (!meta) return false;

    return new Date(meta.value);
  }

  getDeliveryDate = (length: Weekday = 'short') => {
    const meta = this.#getMetaItem("_delivery_date");

    if (!meta) return "";

    const date = new Date(meta.value + 'T00:00:00');
    return date.toLocaleDateString('en-US',
      { weekday: length, month: length, day: 'numeric' });
  }

  getPoints = () => {
    const meta = this.#getMetaItem("_wc_points");

    return meta ? meta.value : 0;
  }

  getEarnedPoints = () => {
    const meta = this.#getMetaItem("_wc_points_earned");

    return meta ? meta.value : 0;
  }

  // Returns comma separated list of product names
  getProductsString = () => {
    let result = '';
    this.data.line_items.forEach((item: Record<string, any>) => {
      if (!result) {
        result += item.name;
      } else {
        result += ', ' + item.name;
      }
    });
    return result;
  }

  getSmartCouponContribution = () => {
    const meta = this.#getMetaItem("smart_coupons_contribution");

    return meta ? parseFloat(meta.value) : 0;
  }

  getStripePaymentIntent = () => {
    const meta = this.#getMetaItem("_stripe_intent");

    if (!meta) return null;

    return meta.value;
  }


  getSubtotal = (formatted = false) => {
    let subtotal = 0;
    this.data.line_items.forEach((item: { subtotal: string }) => {
      subtotal += parseFloat(item.subtotal);
    });

    if (!formatted) return subtotal;

    return Helper.formattedCurrency(subtotal);
  }

  getTotalItems = () => {
    let total = 0;

    this.data.line_items.forEach((item: Record<string, any>) => {
      total += item.quantity;
    });

    return total;
  }

  isChargeable = () => {
    if (parseFloat(this.data.total) >= 0.5) {
      return true;
    }

    return false;
  }

  isModifiable = () => {
    if (this.data.status !== "processing") return false;

    const timeNow = new Date();
    const weekday = timeNow.getDay();
    const deliveryTime = this.getDeliveryDateTime();

    if (!deliveryTime) return false;

    const deliveryWeekday = deliveryTime.getDay();

    const deliveryHoursAway =
      (deliveryTime.getTime() - timeNow.getTime()) / (1000 * 3600);

    // Shouldn't happen, but just in case
    if (deliveryHoursAway < 0) return false;

    if (this.data.shipping_lines && this.data.shipping_lines[0].method_title.includes('UPS')) {
      const tuesdayWednesDayCutOff = weekday > 4 || weekday === 1 || weekday === 0;
      const thursdayCutOff = weekday === 2 || weekday === 3;
      const nextDeliveryDate = new Date(deliveryTime);

      const nextTuesday = new Date(timeNow);
      const daysUntilNextTuesday = (2 + 7 - weekday) % 7 || 7;
      nextTuesday.setDate(timeNow.getDate() + daysUntilNextTuesday);

      const nextWednesday = new Date(timeNow);
      const daysUntilNextWednesday = (3 + 7 - weekday) % 7 || 7;
      nextWednesday.setDate(timeNow.getDate() + daysUntilNextWednesday);

      const nextThursday = new Date(timeNow);
      const daysUntilNextThursday = (4 + 7 - weekday) % 7 || 7;
      nextThursday.setDate(timeNow.getDate() + daysUntilNextThursday);

      const isTuesdayWednesDayDelivery = [nextTuesday, nextWednesday].some(
        (day) => day.toDateString() === nextDeliveryDate.toDateString()
      );

      // If delivery is TUE or W, cutoff is Thursday midnight
      if (tuesdayWednesDayCutOff && isTuesdayWednesDayDelivery) {
        return false;
      }

      // If delivery is TH, cutoff is Monday midnight
      if (thursdayCutOff && nextThursday.toDateString() === nextDeliveryDate.toDateString()) {
        return false;
      }
    }

    if (deliveryHoursAway < 72) {
      // If delivery is W or TH, cutoff is Monday midnight
      if ([3, 4].includes(deliveryWeekday)) {
        if ([2, 3, 4].includes(weekday)) return false;

        return true;
      }

      // If delivery is Sa or Su, cutoff is Th, midnight
      if ([0, 6].includes(deliveryWeekday)) {
        if ([5, 6, 0].includes(weekday)) return false;

        return true;
      }

      // Shouldn't get here unless we change deliveries from w, th, sa, su.
      return false;
    }

    // Delivery is > 72 hours away.
    return true;
  }

  isRefundable = () => {
    if (!this.isModifiable()) return false;

    return Boolean(this.#getMetaItem("_authorize_net_trans_id"));
  }

  lineItemsToCartItems = (
    products: Array<Record<string, any>>,
    customer: CustomerObj,
    idNeeded = false,
    isCartItems = false,
  ) => {
    const cartItems: ICartItems = {};

    for (let lineItem of this.data.line_items) {
      let product = ProductObj.getById(products, lineItem.product_id);

      if (!product) continue;

      cartItems[product.data.id] = {
        ...(idNeeded && { id: lineItem.id }),
        product_id: product.data.id,
        product_price: customer.getProductPrice(product) as number,
        ...(isCartItems && { quantity: lineItem.quantity }),
        product_qty: lineItem.quantity
      }
    }
    return cartItems;
  }

  removeGiftCardMeta = () => {
    this.data.meta_data = this.data.meta_data.filter((meta_item: { key: string }) => {
      return !['gift_receiver_email', 'gift_receiver_message',
        'is_gift'].includes(meta_item.key);
    });
  }
  removePointsFromMeta = () => {
    this.data.meta_data = this.data.meta_data.filter((meta_item: { key: string }) => {
      return meta_item.key !== "_wc_points_logged_redemption";
    });
  }

  removeSmartCouponFromMeta = () => {
    this.data.meta_data = this.data.meta_data.filter((meta_item: { key: string }) => {
      return meta_item.key !== "smart_coupons_contribution";
    });
  }

  updateData = (newData: Record<string, any>) => {
    this.data.billing = { ...this.data.billing, ...newData.billing };
    this.data.shipping = { ...this.data.shipping, ...newData.shipping };
    this.data.shipping_lines[0] = { ...this.data.shipping_lines[0], ...newData.shipping_lines[0] };

    let result_li: Array<Record<string, any>> = [];

    this.data.line_items.forEach((order_li: Record<string, any>) => {
      let found_li = newData.line_items.find((new_item: Record<string, any>) => {
        return new_item.product_id === order_li.product_id;
      });
      if (found_li) {
        result_li.push({ ...order_li, ...found_li });
      } else {
        result_li.push({ ...order_li, quantity: 0 });
      }
    });

    newData.line_items.forEach((new_li: Record<string, any>) => {
      let found_li = this.data.line_items.find((order_li: Record<string, any>) => {
        return order_li.product_id === new_li.product_id;
      });

      if (!found_li) this.data.line_items.push(new_li);
    });
    this.data.line_items = result_li;
    this.data.customer_note = newData.customer_note;
    this.data.coupon_lines = [];
    delete this.data.status;
  }

  // -------------------------------------
  // S T A T I C   M E T H O D S
  // -------------------------------------

  static findOrder = (orders: Array<Record<string, any>>, orderId: number) => {
    let orderData = orders.find((order: Record<string, any>) =>
      Number(order.id) === orderId);

    if (orderData) {
      return new OrderObj(orderData);
    }

    return null;
  }

  static findRecentOrder = (orders: Array<Record<string, any>>) => {
    if (orders.length) {
      return new OrderObj(orders[0]);
    }

    return null;
  }

  static getPaymentMethodTitle = (creditCardProcessor: string) => {
    if (creditCardProcessor === "stripe") {
      return "Credit Card (Stripe)";
    }
    if (creditCardProcessor === "authorize.net") {
      return "Credit Card (Authorize.net)";
    }
    return "Unknown"
  }

  // -------------------------------------
  // P R I V A T E   M E T H O D S
  // -------------------------------------

  #getMetaItem = (key: string) => {
    return this.data.meta_data.find((meta_item: Record<string, any>) => {
      return meta_item.key === key;
    });
  }
}