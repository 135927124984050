import { useEffect, useMemo, useState } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MainBanner from '../components/MainBanner';
import NavBar from '../components/NavBar';
import AddressPage from '../pages/AddressPage';
import ChangePassword from '../features/customer/ChangePassword'
import HomePage from '../pages/HomePage';
import CareersPage from '../pages/CareersPage';
import CheckoutPage from '../pages/CheckoutPage';
import EditAccountPage from '../pages/EditAccountPage';
import GiftCardsPage from '../pages/GiftCardsPage';
import LostPasswordPage from '../pages/LostPasswordPage';
import MyAccountPage from '../pages/MyAccountPage';
import MyOrderPage from '../pages/MyOrderPage';
import MembershipsPage from '../pages/MembershipsPage';
import MightyPointsPage from '../pages/MightyPointsPage';
import MyMealPlanPage from '../pages/MyMealPlanPage';
import MyMealPlansPage from '../pages/MyMealPlansPage';
import MyOrdersPage from '../pages/MyOrdersPage';
import OrderPage from '../pages/OrderPage';
import OrderReceivedPage from '../pages/OrderReceivedPage';
import Page from '../pages/Page';
import ReferAFriendPage from '../pages/ReferAFriendPage';
import ReviewsPage from '../pages/ReviewsPage';
import SetPasswordPage from '../pages/SetPasswordPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
import CartObj from '../features/cart/cartObj';
import {
  cleanseCart, clearCart, loadCart,
  resetCart,
  selectCartCleanseComplete,
  selectCartCleanseError,
  selectCartIsLoaded,
  selectCartItems,
  selectCartUpdateInProgress,
  selectOrderInProgress,
  setCartItem,
  setCartZipcode,
} from '../features/cart/cartSlice';
import {
  loadProducts,
  selectProductLoadError,
  selectProducts,
  selectProductsPagesLoaded,
  selectProductsTotalPages
} from '../features/products/productsSlice';
import {
  getSession,
  logoutUser,
  selectLoggingOut,
  selectSessionIsLoaded,
  selectSessionIsLoading,
  selectSessionLoadError,
  selectToken,
  setAuthInfo
} from '../features/user/userSlice';
import {
  loadCustomer,
  resetCustomer,
  selectCustomer,
  selectCustomerEmail,
  selectCustomerIsLoaded,
  selectCustomerIsLoading,
  selectCustomerLoadError
} from '../features/customer/customerSlice';
import {
  loadCustomerDetails,
  selectCustomerDetailsIsLoaded,
  selectCustomerDetailsIsLoading,
  selectCustomerDetailsLoadError
} from '../features/customer/customerDetailSlice';
import {
  loadShippingMethods,
  selectShippingLoaded,
  selectShippingLoadError,
  selectShippingLoading
} from '../features/shipping/shippingSlice';
import {
  loadDeliveryInfo,
  selectDeliveryLoaded,
  selectDeliveryLoadError,
  selectDeliveryLoading
} from '../features/delivery/deliverySlice';
import './styles/app.scss';
import CartModal from '../features/cart/CartModal';
import Window from '../utils/Window';
import MobileFooter from '../components/MobileFooter';
import OurMissionPage from '../pages/OurMissionPage';
import HowItWorksPage from '../pages/HowItWorksPage';
import RewardsProgramPage from '../pages/RewardsProgramPage';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import ReactPixel from 'react-facebook-pixel';
import {
  resetReferrals,
} from '../features/referrals/referralsSlice';
import SharedMeals from '../features/meal_plans/SharedMeals';
import MaintenanceMode from './MaintenanceMode';
import { loadGeneralOptions, selectGeneralOptions, selectGeneralOptionsError, selectGeneralOptionsLoading, selectIsMobileRoute, setIPAddress, setIsLoading, setIsMobileNavToggled, setIsMobileRoute, setSavedCardData, setShowCart, setUtmParamsURL, setUtmShipping, setUtmThreshold } from '../features/mobile/mobileSlice';
import { loadEditAccountOptions } from '../features/customer/editaccountOptionsSlice';
import ConciergePage from '../pages/ConciergePage';
import FitDashboard from '../pages/MightyFit/FitDashBoard';
import DetailsPage from '../pages/MightyFit/FitPages/DetailsPage';
import TennisPage from '../pages/TennisPage';
import EssentialsPage from '../pages/ElementorPages/EssentialsPage';
import { filterProductsByCategory } from '../features/products/productUtils';
import CateringPage from '../pages/ElementorPages/CateringPage';
import AffiliatePartnerShip from '../pages/ElementorPages/AffiliatePartnerShip';
import EnterpricePartnerShip from '../pages/ElementorPages/EnterpricePartnerShip';
import MightyfitPartnerShip from '../pages/ElementorPages/MightyfitPartnerShip';
import SustainibilityPage from '../pages/ElementorPages/SustainibilityPage';
import MightyBucksGiftCardPage from '../features/products/MightyBucksGiftCardPage';
import MightyCheeseCake from '../pages/ElementorPages/MightyCheeseCake';
import NutritionTeam from '../pages/ElementorPages/NutritionTeam';
import TerpsPage from '../pages/ElementorPages/TerpsPage';
import * as Sentry from "@sentry/react";
import AuthNetAPI from '../API/authNetAPI';
import AutoshipWrapper from '../pages/AutoshipPages/AutoshipWrapper';
import AutoShipAPI from '../pages/AutoshipPages/core/autoshipAPI';
import { setAutoShipData, setNotifications, setPauseAutoship, setVideoTutorials } from '../pages/AutoshipPages/core/autoShipSlice';
import { persistor } from './store';
import PaymentMethodsPage from '../features/customer/PaymentMethodsPage';
import { loadCoupons } from '../features/coupons/couponsSlice';
import UserAPI from '../API/userAPI';
import MightyEducation from '../pages/ElementorPages/MightyEducation';
import UmdTerps from '../pages/ElementorPages/UmdTerps';
import VaCavaliers from '../pages/ElementorPages/VaCavaliers';
import { loadOrders, resetOrders, selectOrdersPagesLoaded } from '../features/orders/ordersSlice';
import { loadOrderPlannerOrders, resetOrderPlannerOrders, selectOrderPlannerOrdersPagesLoaded } from '../pages/AutoshipPages/core/orderPlannerOrdersSlice';

const isStaging = process.env.REACT_APP_WEB_URL === "https://shop.emmstaging.com/";

const traceTargetURL = isStaging ?
  /^https:\/\/shop.emmstaging\.com\// : /^https:\/\/mightymeals\.com\//
const dsnValue =
  isStaging ?
    "https://1a9a71947c6779362c10d4ceb69e2cd1@o4506653291249664.ingest.sentry.io/4506666487119872" :
    "https://0cc5a6621ed09e8bca3fe53bae031c59@o4506653291249664.ingest.sentry.io/4506653291380736";

Sentry.init({
  dsn: dsnValue,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", traceTargetURL],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Allow errors only from specific URLs
  allowUrls: [
    /https:\/\/(www\.)?mightymeals\.com/
  ],

  // Deny errors from specific URLs
  denyUrls: [
    /https:\/\/maps\.googleapis\.com\/maps\/api\/js/,
    /https:\/\/blog\.mightymeals\.com\//,
    /https:\/\/cdn-asset\.optimonk\.com/,
    /https:\/\/acsbapp\.com/,
    /https:\/\/static\.klaviyo\.com/
  ]
});

if (process.env.REACT_APP_GA4_MEASUREMENT_ID) {
  ReactGA.initialize(
    process.env.REACT_APP_GA4_MEASUREMENT_ID
  );
}

if (process.env.REACT_APP_FB_PIXEL_ID) {
  ReactPixel.init(
    process.env.REACT_APP_FB_PIXEL_ID,
    undefined,
    { autoConfig: true, debug: process.env.NODE_ENV !== 'production' }
  );
}

if (process.env.REACT_APP_TIKTOK_PIXEL_ID) {
  TiktokPixel.init(
    process.env.REACT_APP_TIKTOK_PIXEL_ID,
    undefined,
    { debug: process.env.REACT_APP_WEB_URL !== "https://mightymeals.com/" }
  );
}

const App: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const token: string = useSelector(selectToken);
  const email = useSelector(selectCustomerEmail);
  const loggingOut = useSelector(selectLoggingOut);
  const sessionLoaded = useSelector(selectSessionIsLoaded);
  const sessionLoading = useSelector(selectSessionIsLoading)
  const sessionLoadError = useSelector(selectSessionLoadError);
  const cartCleanseComplete = useSelector(selectCartCleanseComplete);
  const cartCleanseError = useSelector(selectCartCleanseError);
  const cartIsLoaded = useSelector(selectCartIsLoaded);
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const cartItems = useSelector(selectCartItems);
  const cart = useMemo(() => {
    return new CartObj(cartItems);
  }, [cartItems]);
  const orderInProgress = useSelector(selectOrderInProgress);
  const products = useSelector(selectProducts);
  const productPagesLoaded: number = useSelector(selectProductsPagesLoaded);
  const totalProductPages = useSelector(selectProductsTotalPages);
  const productLoadError: boolean = useSelector(selectProductLoadError);
  const custLoaded: boolean = useSelector(selectCustomerIsLoaded);
  const custLoading: boolean = useSelector(selectCustomerIsLoading);
  const custLoadError = useSelector(selectCustomerLoadError);
  const custData = useSelector(selectCustomer);
  const custDetailsLoaded: boolean = useSelector(selectCustomerDetailsIsLoaded);
  const custDetailsLoading: boolean = useSelector(selectCustomerDetailsIsLoading);
  const custDetailsLoadError = useSelector(selectCustomerDetailsLoadError);
  const shippingLoaded = useSelector(selectShippingLoaded);
  const shippingLoading = useSelector(selectShippingLoading);
  const shippingLoadError = useSelector(selectShippingLoadError);
  const deliveryLoaded = useSelector(selectDeliveryLoaded);
  const deliveryLoading = useSelector(selectDeliveryLoading);
  const deliveryLoadError = useSelector(selectDeliveryLoadError);
  const pages_loaded: number = useSelector(selectOrdersPagesLoaded);
  const orderPlanner_pages_loaded: number = useSelector(selectOrderPlannerOrdersPagesLoaded);
  const [maintenance, setMaintenance] = useState(false);
  const GiftCard = filterProductsByCategory(products, 'gift-card');
  const MightyBucksGiftCard = filterProductsByCategory(products, 'mighty-bucks-gift-card');
  const AppURL = useSelector(selectIsMobileRoute);
  const QURLParams = AppURL ? `/?${AppURL}` : '/';
  const AndURLParams = AppURL ? `&${AppURL}` : '';
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const isOptionsLoading = useSelector(selectGeneralOptionsLoading);
  const optionsError = useSelector(selectGeneralOptionsError);
  const setLoginInfo = localStorage.getItem('setAuthInfo');
  const searchParams = new URLSearchParams(location.search);
  const isUtmThreshold = location.search.includes('utm_threshold');
  const isUtmShipping = location.search.includes('utm_shipping');
  const utmThresholdValue = searchParams.get('utm_threshold') ?? undefined;
  const utmShippingValue = searchParams.get('utm_shipping') ?? undefined;

  useEffect(() => {
    setTimeout(() => {
      if (setLoginInfo) {
        const logInfo = JSON.parse(setLoginInfo);
        if (logInfo.token) {
          if (isTokenExpired(logInfo.token)) {
            handleLogout(logInfo.token);
          }
        } else {
          handleLogout();
        }
      }
    }, 3000);
  });

  //Set maintenance mode for the Site.
  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(resetOrders());
        dispatch(resetOrderPlannerOrders());
        if (location.search) {
          const queryString = location.search.substring(1);
          dispatch(setUtmParamsURL(queryString));
        }

        if (getGeneralOptions) {
          if (getGeneralOptions.is_maintenance_mode === true) setMaintenance(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isOptionsLoading && !optionsError) {
      dispatch(loadGeneralOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsLoading, optionsError])

  useEffect(() => {
    handleIPConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if ((isUtmShipping || isUtmThreshold) && getGeneralOptions) {
      const thresholdCharge = isUtmThreshold && getGeneralOptions?.regional_shipping_overrides_threshold?.find(
        (shipping: any) => shipping.ship_threshold_code === utmThresholdValue
      );

      const shippingCharge = isUtmShipping && getGeneralOptions?.regional_shipping_overrides_price?.find(
        (shipping: any) => shipping.ship_price_code === utmShippingValue
      );

      if (isUtmThreshold && thresholdCharge && utmThresholdValue) {
        dispatch(setUtmThreshold(thresholdCharge));
      }

      if (isUtmShipping && shippingCharge && utmShippingValue) {
        dispatch(setUtmShipping(shippingCharge));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUtmShipping, isUtmThreshold, getGeneralOptions]);

  useEffect(() => {
    if (setLoginInfo) {
      const logInfo = JSON.parse(setLoginInfo);
      if (logInfo.token) {
        if (isTokenExpired(logInfo.token)) {
          handleLogout(logInfo.token);
        } else {
          const currentDate = new Date().getTime();
          const storedDate = new Date(logInfo.dateStored).getTime();
          const timeDifference = currentDate - storedDate;
          const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          if (logInfo.isRememberMe === true && daysDifference <= 90) {
            loadCustomerUsingToken(logInfo);
          } else if (logInfo.isRememberMe === false && (daysDifference >= 0 && daysDifference <= 7)) {
            loadCustomerUsingToken(logInfo);
          }
        }
      } else {
        handleLogout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoginInfo])

  //Other code when maintenace is checked
  useEffect(() => {
    if (location.pathname !== '/order') {
      dispatch(setShowCart(false))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search])

  // Force re-render on resize
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  // Embed handling
  useEffect(() => {
    const handleContentLoaded = () => {
      const body = document.getElementsByTagName('body')[0];
      let bodyHeight = body.scrollHeight + "px";
      let thirdPartyCookies = false;

      // Determine if we are able to set a cookie
      document.cookie = "CookieName=TestCookie; samesite=None; secure";
      if (document.cookie.indexOf("TestCookie") !== -1) {
        thirdPartyCookies = true;
      }

      window.parent.postMessage({
        bodyHeight: bodyHeight,
        thirdPartyCookies: thirdPartyCookies
      }, "*");

      const resizeObserver = new ResizeObserver(entries => {
        var body_height = entries[0].target.clientHeight;
        window.parent.postMessage({
          bodyHeight: body_height,
          thirdPartyCookies: thirdPartyCookies
        }, "*");
      });

      resizeObserver.observe(document.body);
    }
    document.addEventListener("DOMContentLoaded", handleContentLoaded);

    return () => {
      document.removeEventListener('DOMContentLoaded', handleContentLoaded);
    }
  });

  useEffect(() => {
    if (!custLoaded || !('id' in custData)) return;

    if (process.env.REACT_APP_FB_PIXEL_ID) {
      ReactPixel.init(
        process.env.REACT_APP_FB_PIXEL_ID,
        {
          em: custData.billing.email,
          fn: custData.billing.first_name.toLowerCase(),
          ln: custData.billing.last_name.toLowerCase(),
          ph: '1' + custData.billing.phone.replace(/\D/g, ''),
          ge: '',
          db: '',
          ct: custData.billing.city.toLowerCase().replace(/\s+/g, ''),
          st: custData.billing.state.toLowerCase(),
          zp: custData.billing.postcode,
          country: 'us'
        },
        { autoConfig: true, debug: process.env.NODE_ENV !== 'production' }
      );
    }
    if (process.env.REACT_APP_GA4_MEASUREMENT_ID) {
      ReactGA.gtag(
        'set', 'user_properties', {
        email: custData.billing.email,
        first_name: custData.billing.first_name.toLowerCase(),
        last_name: custData.billing.last_name.toLowerCase(),
        billing_phone: '1' + custData.billing.phone.replace(/\D/g, ''),
        billing_city: custData.billing.city.toLowerCase().replace(/\s+/g, ''),
        billing_state: custData.billing.state.toLowerCase(),
        billing_zip: custData.billing.postcode
      });
    }

    // eslint-disable-next-line    
  }, [custLoaded]);

  // Google Analytics, Facebook Pixel page views, Dynamic SEO tags
  useEffect(() => {
    const ogUrlEl = document.getElementById('mm-og-url');
    if (ogUrlEl) {
      ogUrlEl.setAttribute('content', window.location.origin + location.pathname);
    }
    const linkEl = document.getElementById('mm-canonical-link');
    if (linkEl) {
      linkEl.setAttribute('href', window.location.origin + location.pathname)
    }
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    ReactPixel.pageView();
    TiktokPixel.pageView();
    // eslint-disable-next-line    
  }, [location.pathname, location.search]);

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    const userToken = localToken ? localToken : token;
    if (userToken && !sessionLoading && sessionLoaded && !sessionLoadError && !loggingOut) {
      dispatch(loadCustomer(userToken));
      dispatch(loadEditAccountOptions());
    }
    if (!sessionLoading && !sessionLoaded && !sessionLoadError && !loggingOut) {
      dispatch(getSession());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sessionLoading, sessionLoaded, sessionLoadError, loggingOut]);

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    const userToken = localToken ? localToken : token;
    if (userToken && !custLoading && !custLoaded && !custLoadError) {
      dispatch(loadCustomer(userToken));
      dispatch(loadEditAccountOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token, custLoading, custLoaded, custLoadError]);

  useEffect(() => {
    if (custData.email && !custDetailsLoading && !custDetailsLoaded && !custDetailsLoadError) {
      dispatch(loadCustomerDetails(custData.email));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, custData.email, custDetailsLoading, custDetailsLoaded, custDetailsLoadError]);

  useEffect(() => {
    async function fetchAuthData() {
      try {
        if (custData.email) {
          dispatch(setIsLoading(true));
          const CIMKey = process.env.REACT_APP_AUTH_CIM_KEY || '';
          const getSavedCardData = await AuthNetAPI.getUserCIM(custData.email, CIMKey);
          if (getSavedCardData) {
            dispatch(setSavedCardData(getSavedCardData));
            dispatch(setIsLoading(false));
          }
        }
      } catch (error) {
        console.error(error);
        dispatch(setIsLoading(false));
      }
    }
    fetchAuthData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, custData.email])

  useEffect(() => {
    if (token || sessionLoaded) {
      dispatch(loadCart(token));
    }
  }, [dispatch, token, sessionLoaded]);

  useEffect(() => {
    if (token && email) {
      dispatch(loadCoupons({ token, email }));
    }
  }, [token, email, dispatch]);

  useEffect(() => {
    if ((productPagesLoaded === 0 ||
      (totalProductPages && (productPagesLoaded < totalProductPages))) &&
      !productLoadError
    ) {
      dispatch(loadProducts(productPagesLoaded + 1));
    }
  }, [dispatch, productLoadError, productPagesLoaded, totalProductPages]);

  useEffect(() => {
    if (!shippingLoaded && !shippingLoading && !shippingLoadError) {
      dispatch(loadShippingMethods());
    }
  }, [shippingLoaded, shippingLoading, shippingLoadError, dispatch]);

  useEffect(() => {
    if (!deliveryLoaded && !deliveryLoading && !deliveryLoadError) {
      dispatch(loadDeliveryInfo());
    }
  }, [deliveryLoaded, deliveryLoading, deliveryLoadError, dispatch]);

  // Remove any disabled products that may be stuck in cart.
  useEffect(() => {
    if (
      !cartCleanseComplete && !cartCleanseError && cartIsLoaded && !cartUpdateInProgress &&
      !orderInProgress && totalProductPages &&
      (productPagesLoaded >= totalProductPages)
    ) {
      dispatch(cleanseCart({ token: token, products: products }));
    }
  },
    [cart, cartCleanseComplete, cartCleanseError, cartIsLoaded,
      cartUpdateInProgress, dispatch, orderInProgress, products,
      productPagesLoaded, token, totalProductPages
    ]);

  useEffect(() => {
    if (custData.id) {
      Promise.all([
        AutoShipAPI.getDietary(custData.id),
        AutoShipAPI.getUserNotifications(custData.id)
      ]).then(([dietaryResult, notificationsResult]) => {
        if (dietaryResult.status === "success") {
          dispatch(setAutoShipData(dietaryResult.data));
        }
        if (notificationsResult.status === "success") {
          dispatch(setNotifications(notificationsResult.data));
        }
      }).catch((e) => {
        console.log(e.message);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custData.id]);

  useEffect(() => {
    if (custData && custData?.meta_data) {
      const metakey = custData?.meta_data?.find((meta: any) => meta.key === "user_pause_order_planner");
      if (metakey?.value) {
        const isPaused = metakey?.value === "1" ? true : false;
        dispatch(setPauseAutoship(isPaused));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custData, custData.meta_data])

  useEffect(() => {
    if (token && !pages_loaded) {
      dispatch(loadOrders(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pages_loaded]);

  useEffect(() => {
    const handleOrderPlanner = async () => {
      if (token && !orderPlanner_pages_loaded && custData.id) {
        try {
          dispatch(loadOrderPlannerOrders(custData.id));
          const response = await AutoShipAPI.getVideoTutorials();
          dispatch(setVideoTutorials(response));
        } catch (error) {
          console.error("Failed to fetch video tutorials:", error);
        }
      }
    };

    handleOrderPlanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, orderPlanner_pages_loaded, custData.id]);

  useEffect(() => {
    const cartItemIds = Object.keys(cartItems);

    if (GiftCard && GiftCard[0] && GiftCard[0].id !== undefined) {
      const giftCardID = Number(GiftCard[0].id);

      const giftCardQTY = Object.values(cartItems).filter(
        (items: any) => Number(items.product_id) === giftCardID && Number(items.product_qty) > 1
      );

      const hasZeroPriceItem = Object.values(cartItems).some((items: any) => items.product_price === 0);

      if (giftCardQTY.length > 0) {
        UpdateQTYCartItems(giftCardQTY);
      }

      if (hasZeroPriceItem) {
        dispatch(clearCart(token));
      }
    }

    const isGiftCard = (item: string) =>
      GiftCard.some((giftCard) => Number(item) === Number(giftCard.id));

    const isMightyBucksGiftCard = (item: string) =>
      MightyBucksGiftCard.some((giftCard) => Number(item) === Number(giftCard.id));

    const giftCardInCart = cartItemIds.some(isGiftCard);
    const mightyBucksInCart = cartItemIds.some(isMightyBucksGiftCard);
    const otherProductsInCart = cartItemIds.some(
      (item) => !isGiftCard(item) && !isMightyBucksGiftCard(item)
    );

    if ((giftCardInCart || mightyBucksInCart) && otherProductsInCart) {
      if (giftCardInCart) {
        dispatch(clearCart(token))
      }
      if (mightyBucksInCart) {
        updateClassDeclaration(MightyBucksGiftCard, cartItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GiftCard, MightyBucksGiftCard, cartItems]);

  const UpdateQTYCartItems = async (giftCard: any) => {
    const searchParams = new URLSearchParams(location.search);
    const couponCode = searchParams.get('coupon-code');
    await dispatch(setCartItem({
      token: token,
      cart_item: {
        product_id: giftCard[0].product_id,
        product_qty: 1,
        product_price: giftCard[0].product_price
      }
    }));
    if (couponCode) {
      navigate(`/checkout?coupon-code=${couponCode}${AndURLParams}`);
    }
    else {
      navigate(`/checkout${QURLParams}`);
    }
  }

  const updateClassDeclaration = async (GiftCard: any, cartItems: any) => {
    const searchParams = new URLSearchParams(location.search);
    const couponCode = searchParams.get('coupon-code');
    await dispatch(clearCart(token))
    if (GiftCard) {
      const filteredMightyBucks = GiftCard.filter((item: any) => Object.keys(cartItems).some(product_id => Number(product_id) === Number(item.id)));
      await dispatch(setCartItem({
        token: token,
        cart_item: {
          product_id: filteredMightyBucks[0].id,
          product_qty: 1,
          product_price: filteredMightyBucks[0].price
        }
      }));
      if (couponCode) {
        navigate(`/checkout?coupon-code=${couponCode}${AndURLParams}`);
      }
      else {
        navigate(`/checkout${QURLParams}`);
      }
    }
  }

  const loadCustomerUsingToken = async (setLoginInfo: any) => {
    if (setLoginInfo) {
      dispatch(loadCustomer(setLoginInfo.token));
      dispatch(loadEditAccountOptions());
      localStorage.setItem('token', setLoginInfo.token);
      const setAuthLogInfo = {
        token: setLoginInfo.token,
        email: setLoginInfo.email,
        nicename: setLoginInfo.username,
        display_name: setLoginInfo.first_name,
        isRememberMe: setLoginInfo.loginRememberMe,
        dateStored: setLoginInfo.dateStored
      }
      localStorage.setItem('setAuthInfo', JSON.stringify(setAuthLogInfo));
      await dispatch(setAuthInfo({
        token: setLoginInfo.token,
        email: setLoginInfo.email,
        nicename: setLoginInfo.username,
        display_name: setLoginInfo.first_name
      }));
    }
  }

  const handleLogout = (token?: string) => {
    localStorage.removeItem('token');
    localStorage.removeItem('setAuthInfo');
    dispatch(setAuthInfo({
      token: '',
      email: '',
      nicename: '',
      display_name: ''
    }));
    dispatch(setIsMobileNavToggled(false));
    persistor.purge();
    dispatch({ type: "USER_LOGOUT" });
    navigate(`/my-account${QURLParams}`);
    dispatch(resetCart());
    dispatch(resetReferrals());
    dispatch(setCartZipcode(''));
    dispatch(resetCustomer());
    dispatch(resetOrderPlannerOrders());
    dispatch(loadGeneralOptions());
    if (token) {
      dispatch(logoutUser(token));
    }
  }

  const isTokenExpired = (token: string) => {
    const decodedToken = decodeJWT(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return decodedToken.exp < currentTime;
  }

  const decodeJWT = (token: string) => {
    // Split the token into its three parts
    const base64Url = token.split('.')[1]; // Payload is the second part
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    // Decode the base64 string
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const handleIPConfigs = async () => {
    try {
      if (!location.search.includes("?s=") && !location.search.includes("&s=")) {
        const ipInfoResponse = await UserAPI.getIPConfigs();

        if (ipInfoResponse && ipInfoResponse.ip && ipInfoResponse.region) {
          dispatch(setIPAddress(ipInfoResponse.ip));

          const utmSearch = location.search.includes('utm_source');
          const state = ipInfoResponse.region.toLowerCase().replace(/\s+/g, "-");

          const stateAbbreviations: Record<string, string> = {
            'alabama': 'AL', 'alaska': 'AK', 'arizona': 'AZ', 'arkansas': 'AR', 'california': 'CA',
            'colorado': 'CO', 'connecticut': 'CT', 'delaware': 'DE', 'florida': 'FL', 'georgia': 'GA',
            'hawaii': 'HI', 'idaho': 'ID', 'illinois': 'IL', 'indiana': 'IN', 'iowa': 'IA',
            'kansas': 'KS', 'kentucky': 'KY', 'louisiana': 'LA', 'maine': 'ME', 'maryland': 'MD',
            'massachusetts': 'MA', 'michigan': 'MI', 'minnesota': 'MN', 'mississippi': 'MS', 'missouri': 'MO',
            'montana': 'MT', 'nebraska': 'NE', 'nevada': 'NV', 'new-hampshire': 'NH', 'new-jersey': 'NJ',
            'new-mexico': 'NM', 'new-york': 'NY', 'north-carolina': 'NC', 'north-dakota': 'ND', 'ohio': 'OH',
            'oklahoma': 'OK', 'oregon': 'OR', 'pennsylvania': 'PA', 'rhode-island': 'RI', 'south-carolina': 'SC',
            'south-dakota': 'SD', 'tennessee': 'TN', 'texas': 'TX', 'utah': 'UT', 'vermont': 'VT',
            'virginia': 'VA', 'washington': 'WA', 'west-virginia': 'WV', 'wisconsin': 'WI', 'wyoming': 'WY',
            'washington-dc': 'DC', 'default': 'XX'
          };

          const stateAbbreviation = stateAbbreviations[state] || stateAbbreviations.default;

          const allowedStates = ['VA', 'MD', 'DC', 'XX'];
          const isDisallowedState = stateAbbreviation && !allowedStates.some(allowed => stateAbbreviation.includes(allowed));

          if (isDisallowedState) {

            const stateURL = utmSearch ? `utm_source=app&s=${stateAbbreviation}` : `s=${stateAbbreviation}`;

            dispatch(setIsMobileRoute(stateURL));
          }
        }
      }
    } catch (error) {
      console.error("IP Info API failed or returned an error:", error);
    }
  }

  return (
    <>
      {maintenance ? <MaintenanceMode /> :
        <div id="app" className='d-flex flex-column'>
          <ScrollToTop />
          <CartModal />
          <MainBanner />
          <NavBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path='/careers' element={<CareersPage />} />
            <Route path='/mightyfit' element={<FitDashboard />} />
            <Route path='/mightyfit/:workoutId' element={<DetailsPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/concierge" element={<ConciergePage />} />
            <Route path="/tennis" element={<TennisPage />} />
            <Route path="/checkout/order-received/:orderId" element={<OrderReceivedPage />} />
            <Route path="/how-it-works" element={<HowItWorksPage />} />
            <Route path="/my-account" element={<MyAccountPage />} />
            <Route path="/my-account/address" element={<AddressPage />} />
            <Route path="/my-account/edit-account" element={<EditAccountPage />} />
            <Route path="my-account/gift-cards" element={<GiftCardsPage />} />
            <Route path="/giftcards" element={<MightyBucksGiftCardPage />} />
            <Route path="my-account/lost-password" element={<LostPasswordPage />} />
            <Route path="my-account/set-password" element={<SetPasswordPage />} />
            <Route path="/my-account/mightypoints" element={<MightyPointsPage />} />
            <Route path="/my-account/my-meal-plans/:mealPlanId" element={<MyMealPlanPage />} />
            <Route path="/my-account/my-meal-plans" element={<MyMealPlansPage />} />
            <Route path="/my-account/my-membership-details" element={<MembershipsPage />} />
            <Route path="/my-account/orders/:orderId" element={<MyOrderPage />} />
            <Route path="/my-account/orders" element={<MyOrdersPage />} />
            <Route path="/my-account/refer-a-friend" element={<ReferAFriendPage />} />
            <Route path="/order" element={<OrderPage />} />
            <Route path="/order/r/:affiliateSlug" element={<OrderPage />} />
            <Route path="/our-mission" element={<OurMissionPage />} />
            <Route path="/pages/:pageId" element={<Page />} />
            <Route path="/product/:productSlug" element={<OrderPage />} />
            <Route path="/products/category/:categorySlug" element={<OrderPage />} />
            <Route path="/reviews" element={<ReviewsPage />} />
            <Route path="/rewards-program" element={<RewardsProgramPage />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
            <Route path="/sharedmeals/:mealPlanId/:sharedmealsSlug" element={<SharedMeals />} />
            <Route path="/supplements/" element={<EssentialsPage />} />
            <Route path="/essentials/" element={<EssentialsPage />} />
            <Route path="/catering/" element={<CateringPage />} />
            <Route path="/affiliate-partnerships/" element={<AffiliatePartnerShip />} />
            <Route path="/enterprise-partnerships/" element={<EnterpricePartnerShip />} />
            <Route path="/mightyfit-partnerships/" element={<MightyfitPartnerShip />} />
            <Route path="/sustainability/" element={<SustainibilityPage />} />
            <Route path="/mightycheesecake/" element={<MightyCheeseCake />} />
            <Route path="/nutrition/" element={<NutritionTeam />} />
            <Route path="/terps/" element={<TerpsPage />} />
            <Route path='/autoship/*' element={<AutoshipWrapper />} />
            <Route path="/my-account/change-password" element={<ChangePassword />} />
            <Route path="/my-account/*" element={<PaymentMethodsPage />} />
            <Route path="/mightyeducation/" element={<MightyEducation />} />
            <Route path="/mightyeducation/umd-terps" element={<UmdTerps />} />
            <Route path="/mightyeducation/va-cavaliers" element={<VaCavaliers />} />
          </Routes>
          <Footer />
          {(Window.isMobile() || Window.isTablet()) && !location.pathname.startsWith('/autoship') &&
            <MobileFooter />
          }
          {/* <FreshChat token={process.env.REACT_APP_FRESHCHAT_TOKEN} /> */}
        </div>
      }
    </>
  );
}

export default App;