import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { loadDeliveryInfo, selectDeliveryLoading } from '../../../features/delivery/deliverySlice';
import MyAccountPage from '../../MyAccountPage';
import { selectToken } from '../../../features/user/userSlice';
import MyAccountNav from '../../../components/MyAccountNav';
import AutoshipOrdersListPage from './AutoshipOrdersListPage';
import Window from '../../../utils/Window';
import AutoshipOrderListMobile from './AutoshipOrderListMobile';

export default function AutoshipOrders() {
  const dispatch = useDispatch();
  const [deliveryReloaded, setDeliveryReloaded] = useState(false);
  const deliveryLoading = useSelector(selectDeliveryLoading);
  const token = useSelector(selectToken);

  useEffect(() => {
    if (!deliveryLoading && !deliveryReloaded) {
      dispatch(loadDeliveryInfo());
      setDeliveryReloaded(true);
    }
  }, [deliveryLoading, deliveryReloaded, dispatch]);

  if (!token) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Container fluid="lg" className='address-page'>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9} className='d-flex flex-column'>
          {Window.isMobile() ? <AutoshipOrderListMobile /> : <AutoshipOrdersListPage />}
        </Col>
      </Row>
    </Container>
  );
}
