import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AutoShipPage from './AutoShipPage'
import AutoShipSettings from './components/AutoShipSettings'
import AutoshipOrders from './components/AutoshipOrders'
import AutoshipOrderPage from './components/AutoshipOrderPage'
import AutoshipMenuPage from './components/AutoshipMenuPage'
import { selectToken } from '../../features/user/userSlice'
import { useSelector } from 'react-redux'
import MyAccountPage from '../MyAccountPage'

const AutoshipWrapper = () => {
  const token: string = useSelector(selectToken);

  if (!token) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Routes>
      <Route index element={<AutoShipPage />} />
      <Route path="/settings" element={<AutoShipSettings />} />
      <Route path="/settings/:orderId" element={<AutoShipSettings />} />
      <Route path="/orders" element={<AutoshipOrders />} />
      <Route path="/menu" element={<AutoshipMenuPage />} />
      <Route path="/menu/:orderId" element={<AutoshipMenuPage />} />
      <Route path="/orders/:orderId" element={<AutoshipOrderPage />} />
    </Routes>
  )
}

export default AutoshipWrapper;
