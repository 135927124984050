import { useSelector } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { selectToken } from '../features/user/userSlice';
import { selectCustomer } from '../features/customer/customerSlice';
import CustomerObj from '../features/customer/customerObj';
import MyAccountNav from '../components/MyAccountNav';
import MightyPointsLog from '../features/customer/MightyPointsLog';
import MyAccountPage from './MyAccountPage';

export default function MightyPointsPage() {
  const token = useSelector(selectToken);
  const customer = new CustomerObj(useSelector(selectCustomer));

  if (!token) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Container fluid="lg" className='mightypoints-page'>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9} className='d-flex flex-column'>
          <Card className="my-5 font-barlow flex-fill" body>
            <div className='fs-1 fw-bold my-2'>My Mighty Points</div>
            <div className='fs-4'>
              You have <span className='fw-bold'>{customer.getMightyPoints()}</span> Mighty Points
            </div>
            {'mightypoints_log' in customer.data &&
              <MightyPointsLog customer={customer} />
            }
          </Card>
        </Col>
      </Row>
    </Container>      
  ); 
}