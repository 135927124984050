import { autoShipCreateOrder, autoShipDietaryPost, autoShipNotifications } from "./interface";

export default class AutoShipAPI {
    static async getDietary(
        userID: string
    ) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/user-diet-preferences?user_id=' + userID,
            {
                method: 'GET',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
            }
        ));

        if (!response.ok) {
            console.error('HTTP error while getting affiliate:', response);
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }

    static async postDietary(data: autoShipDietaryPost) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/user-diet-preferences',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    user_id: data.userId,
                    _autoship_meals_per_order: data.autoshipMealsPerOrder,
                    _autoship_diet: data.autoshipDiet,
                    _autoship_allergies: data.autoshipAllergies,
                    _autoship_meal_preferences: data.autoshipMealPreferences,
                })
            }
        ));

        if (!response.ok) {
            console.error('HTTP error while posting affiliate referral:', response);
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        const responseData = await response.json();
        return responseData;
    }

    static async getUserNotifications(
        userID: string
    ) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/user-autoship-notifications?user_id=' + userID,
            {
                method: 'GET',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
            }
        ));

        if (!response.ok) {
            console.error('HTTP error while getting affiliate:', response);
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }

    static async postUserNotifications(data: autoShipNotifications) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/user-autoship-notifications',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    user_id: data.userId,
                    _auto_add_weeks: data.autoshipAutoAddWeeks,
                    _autoship_email_notifications: data.autoshipEmailNotifications,
                    _autoship_text_msg_notifications: data.autoshipTextMsgNotifications,
                })
            }
        ));

        if (!response.ok) {
            console.error('HTTP error while posting affiliate referral:', response);
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        const responseData = await response.json();
        return responseData;
    }

    static async postAutoshipPickForMeOrder(data: autoShipCreateOrder) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/autoship-order-create',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    user_id: data.userId,
                    delivery_date: data.deliveryDate,
                })
            }
        ));

        if (!response.ok) {
            console.error('HTTP error while posting affiliate referral:', response);
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        const responseData = await response.json();
        return responseData;
    }

    static async createOrder(order: Object) {
        const url = process.env.REACT_APP_API_URL + 'wp-json/wc/v3/orders/batch';
        const headers = new Headers();
        headers.append('content-type', 'application/json');
        headers.append('Accept', 'application/json');

        const credentialsStaging = 'ck_28ad9686cda5482e7d01b453d9dd0ac3781ee60e:cs_66c82fe3151d9ade470bb7b5fa40ea2f08962cc8';
        const credentialsLive = 'ck_0f4c49c66c620ff62fd97489eb717cb4e3909dde:cs_2126e2d51e9ffbac66779cc96d6a429de2e82fef';
        const credentials = process.env.REACT_APP_API_URL === 'https://emmstaging.com/' ? credentialsStaging : credentialsLive;
        const base64Credentials = btoa(credentials);

        headers.append('Authorization', 'Basic ' + base64Credentials);

        try {
            const payload = { create: order };
            const response = await fetch(url, {
                method: 'POST',
                headers,
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                console.error('HTTP error while creating order. Status:', response.status);
                throw new Error(`HTTP error. status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error('An error occurred:', error);
            throw error;
        }
    }

    static async getAutoshipOrders(customerId: Number) {
        const url = process.env.REACT_APP_API_URL + 'wp-json/wc/v3/orders?customer=' + customerId + '&status=autoship,mmfailpayment,autoship-onhold';
        const headers = new Headers();
        headers.append('content-type', 'application/json');
        headers.append('Accept', 'application/json');

        const credentialsStaging = 'ck_28ad9686cda5482e7d01b453d9dd0ac3781ee60e:cs_66c82fe3151d9ade470bb7b5fa40ea2f08962cc8';
        const credentialsLive = 'ck_0f4c49c66c620ff62fd97489eb717cb4e3909dde:cs_2126e2d51e9ffbac66779cc96d6a429de2e82fef';
        const credentials = process.env.REACT_APP_API_URL === 'https://emmstaging.com/' ? credentialsStaging : credentialsLive;
        const base64Credentials = btoa(credentials);

        headers.append('Authorization', 'Basic ' + base64Credentials);

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                console.error('HTTP error while creating order. Status:', response.status);
                throw new Error(`HTTP error. status: ${response.status}`);
            }

            const totalPagesHeader = response.headers.get('x-wp-totalpages');
            const totalPages = totalPagesHeader ? parseInt(totalPagesHeader) : 0;

            const result = {
                total_pages: totalPages,
                json: await response.json()
            };
            return result;
        } catch (error) {
            console.error('An error occurred:', error);
            throw error;
        }
    }

    static async deleteAllAutoshipOrders(customerId: string) {
        const url = process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/user-turn-off-autoship?user_id=' + customerId;
        const headers = new Headers();
        headers.append('content-type', 'application/json');
        headers.append('Accept', 'application/json');

        const credentialsStaging = 'ck_28ad9686cda5482e7d01b453d9dd0ac3781ee60e:cs_66c82fe3151d9ade470bb7b5fa40ea2f08962cc8';
        const credentialsLive = 'ck_0f4c49c66c620ff62fd97489eb717cb4e3909dde:cs_2126e2d51e9ffbac66779cc96d6a429de2e82fef';
        const credentials = process.env.REACT_APP_API_URL === 'https://emmstaging.com/' ? credentialsStaging : credentialsLive;
        const base64Credentials = btoa(credentials);

        headers.append('Authorization', 'Basic ' + base64Credentials);

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                console.error('HTTP error while creating order. Status:', response.status);
                throw new Error(`HTTP error. status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error('An error occurred:', error);
            throw error;
        }
    }

    static async stripeSavedPayments(
        email: string,
        paymentMethodId: string,
    ) {
        let response = await (fetch(
            process.env.REACT_APP_SERVER_URL + '/stripe_saved_payment',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    email: email,
                    paymentMethod_id: paymentMethodId,
                })
            }
        ));
        if (!response.ok) {
            console.error('HTTP error while capture saved payments:', response);
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }

    static async updateStripeUserCIM(eMail: string, customerID: string, paymentMethodId: string, CIMKey: string) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/update_cim',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    email: eMail,
                    mm_stripe_customer_id: customerID,
                    mm_stripe_payment_method_id: paymentMethodId,
                    cim_key: CIMKey,
                })
            }
        ));
        if (!response.ok) {
            console.error('HTTP error while updating user cim:', response);
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }

    static async pauseAutoshipOrders(userId: string, pause: string) {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/pause-autoship-orders',
            {
                method: 'POST',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                body: JSON.stringify({
                    userid: userId,
                    user_pause_order_planner: pause,
                })
            }
        ));
        if (!response.ok) {
            console.error('HTTP error while updating user cim:', response);
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }

    static async getVideoTutorials() {
        let response = await (fetch(
            process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/mm-video-tutorials',
            {
                method: 'GET',
                headers: { 'content-type': 'application/json;charset=UTF-8' },
            }
        ));

        if (!response.ok) {
            console.error('HTTP error while getting affiliate:', response);
            throw new Error(`HTTP error. status: ${response.status}`);
        }
        return await response.json();
    }
}