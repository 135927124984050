import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Navbar, Nav, Badge, Row, Col, Dropdown, Tab, Tabs } from 'react-bootstrap';
import {
  // logoutUser,
  selectToken,
  setAuthInfo
} from '../features/user/userSlice';
import { persistor } from '../app/store';
import {
  setShowProductFilters,
  selectShowProductFilters,
  selectShowCart,
  setShowCart,
  selectIsMobileNavToggled,
  setIsMobileNavToggled,
  selectIsMobileRoute,
  loadGeneralOptions
} from '../features/mobile/mobileSlice';
import {
  resetCart, selectCartIsLoaded,
  selectCartItems,
  setCartZipcode
} from '../features/cart/cartSlice';
import CartDropdown from '../features/cart/CartDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faBagShopping, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import CartObj from '../features/cart/cartObj';
import ProductFilterTags from '../features/products/filters/ProductFilterTags';
import Window from '../utils/Window';
import SortFilter from '../features/products/filters/SortFilter';
import classnames from 'classnames';
import NavLogoMobile from './NavLogoMobile';
import NavLogoDesktop from './NavLogoDesktop';
import { addDietaryRestriction, clearDietaryRestrictions, selectProductDietRestrictionsFilter, setProductCalorieFilter, setProductCarbFilter, setProductDietFilter, setProductFatFilter, setProductProteinFilter, setProductProteinTypeFilter, setProductSearchFilter, setProductSortFilter, setProductTypeFilter } from '../features/products/productsSlice';
import { resetReferrals } from '../features/referrals/referralsSlice';
import { selectDietaryPlans } from '../features/dietary_plans/dietarySlice';
import { Image } from 'react-bootstrap';
import logoWhite from '../assets/images/mightyMeals-white.svg';
import { resetOrderPlannerOrders, selectOrderPlannerOrders } from '../pages/AutoshipPages/core/orderPlannerOrdersSlice';
import { resetCustomer } from '../features/customer/customerSlice';

export default function NavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const showProductFilters = useSelector(selectShowProductFilters);
  const cartIsLoaded = useSelector(selectCartIsLoaded);
  const cart = new CartObj(useSelector(selectCartItems));
  const showCart = useSelector(selectShowCart);
  const [expanded, setExpanded] = useState(false);
  const token = useSelector(selectToken);
  const filterValue = useSelector(selectProductDietRestrictionsFilter);
  const dietaryPlans = useSelector(selectDietaryPlans);
  const isMobileToggled = useSelector(selectIsMobileNavToggled);
  const orders: Array<Record<string, any>> = useSelector(selectOrderPlannerOrders);
  const autoshipOrders = orders.filter((order, index, self) => index === self.findIndex((o) => o.id === order.id));
  const AppURL = useSelector(selectIsMobileRoute);
  const QURLParams = AppURL ? `/?${AppURL}` : '/';
  const AndURLParams = AppURL ? `&${AppURL}` : '';
  const isMobile = Window.isMobile();
  const isTablet = Window.isTablet();
  const isMobileDevice = location.search.includes('utm_source=app');
  const isOrderPage = location.pathname.startsWith("/order");
  const isGiftCardPage = location.pathname.includes('/giftcards');
  const isReviewPage = location.pathname.startsWith('/reviews');
  const howItWorksPage = location.pathname.startsWith('/how-it-works');
  const ourMissionPage = location.pathname.startsWith('/our-mission');
  const cateringPage = location.pathname.startsWith('/catering');
  const sustainabilityPage = location.pathname.startsWith('/sustainability');
  const affiliatePartnerShipPage = location.pathname.startsWith('/affiliate-partnerships');
  const enterPrisePartnerShipPage = location.pathname.startsWith('/enterprise-partnerships');
  const mightyFitPartnerShipPage = location.pathname.startsWith('/mightyfit-partnerships');
  const isMightyFitPage = !mightyFitPartnerShipPage && location.pathname.startsWith('/mightyfit');
  const umdTerpsPage = location.pathname.includes('/umd-terps');
  const vaCavaliersPage = location.pathname.includes('/va-cavaliers');
  const mightyEducationPage = (!umdTerpsPage && !vaCavaliersPage) && location.pathname.startsWith('/mightyeducation');
  const mightyFitNutritionPage = location.pathname.startsWith('/nutrition');
  const helpPage = location.pathname.startsWith('/help');
  const myAccountPage = location.pathname.startsWith('/my-account');
  const isOrderPageText = isOrderPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const isGiftCardPageText = isGiftCardPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isMightyFitPageText = isMightyFitPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const isMightyFitNutritionPageText = mightyFitNutritionPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const isReviewPageText = isReviewPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const howItWorksPageText = howItWorksPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const ourMissionPageText = ourMissionPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const cateringPageText = cateringPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const sustainabilityPageText = sustainabilityPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const affiliatePartnerShipPageText = affiliatePartnerShipPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const enterPrisePartnerShipPageText = enterPrisePartnerShipPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const mightyFitPartnerShipPageText = mightyFitPartnerShipPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const mightyEducationPageText = mightyEducationPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const umdTerpsPageText = umdTerpsPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const vaCavaliersPageText = vaCavaliersPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const helpPageText = helpPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const myAccountPageText = myAccountPage ? ((isMobile || isTablet) ? "text-success" : "text-black") : ((isMobile || isTablet) ? "" : "text-secondary");
  const tabsRefs = useRef<HTMLDivElement>(null);
  const autoshipRedirect = (autoshipOrders && autoshipOrders.length > 0) ?
    `/autoship${QURLParams}` : `/autoship/orders${QURLParams}`;

  useEffect(() => {
    if (isMobileDevice) {
      setExpanded(isMobileToggled);
    }
  }, [isMobileDevice, isMobileToggled])

  useEffect(() => {
    if (isOrderPage || isGiftCardPage) {
      window.scrollTo(0, 0);
    }
  }, [isGiftCardPage, isOrderPage]);

  useEffect(() => {
    setTimeout(() => {
      if (showCart && cart.isEmpty()) {
        dispatch(setShowCart(false));
      }
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCart])
  const handleHelpClick = () => {
    setExpanded(false);
    dispatch(setIsMobileNavToggled(false));
    window.open("https://mightymeals.freshdesk.com/support/home", '_blank');
  }
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('setAuthInfo');
    dispatch(setAuthInfo({
      token: '',
      email: '',
      nicename: '',
      display_name: ''
    }));
    setExpanded(false);
    dispatch(setIsMobileNavToggled(false));
    persistor.purge();
    dispatch({ type: "USER_LOGOUT" });
    navigate(`/my-account${QURLParams}`, { state: { Tabkey: 'login' } });
    dispatch(resetCart());
    dispatch(resetReferrals());
    dispatch(setCartZipcode(''));
    dispatch(resetCustomer());
    dispatch(resetOrderPlannerOrders());
    dispatch(loadGeneralOptions());
  }

  const handleOrderFilter = () => {
    setExpanded(false);
    dispatch(setIsMobileNavToggled(false));
    dispatch(setProductTypeFilter('all'));
    if (token) {
      const reduxValues: string[] = filterValue;
      const DBValues: string[] = dietaryPlans[0]?.dietary_plan;

      if (reduxValues.length === 0) {
        // eslint-disable-next-line array-callback-return
        DBValues?.map((item: any) => {
          dispatch(addDietaryRestriction(item));
        })
      } else if (reduxValues.length > 0) {
        dispatch(clearDietaryRestrictions());
        DBValues?.forEach(value => {
          if (!reduxValues.includes(value)) {
            reduxValues?.push(value);
          }
        });
        // eslint-disable-next-line array-callback-return
        reduxValues?.map((items: any) => {
          dispatch(addDietaryRestriction(items));
        })
      }
    }
  }

  const handleClick = (event: any) => {
    event.target.nextSibling.classList.toggle('d-none');
    event.target.children[0].children[0].classList.toggle('rotate-90');
  };

  const handleMobileCartOnClick = () => {
    if ((isMobile || isTablet) && (location.pathname === '/checkout' || location.pathname === '/checkout/')) {
      dispatch(setShowCart(false));
      navigate(`/order${QURLParams}`);
    } else {
      if (isOrderPage || isGiftCardPage) {
        dispatch(setShowCart(true));
      }
      else {
        if (cart.getItemCount() === 0) {
          navigate(`/order${QURLParams}`);
        }
        else {
          dispatch(setShowCart(true));
        }
      }
    }
  }

  const handleMobileCartOnBlur = () => {
    if (location.pathname !== '/checkout' && location.pathname !== '/checkout/') {
      dispatch(setShowCart(cart.isEmpty() ? false : showCart));
    }
  }

  const handleQuickFilter = (key: any) => {
    let actionsToDispatch: any[] = [];

    switch (key) {
      case 'all':
      case 'bulk':
      case 'breakfast':
      case 'snacks':
      case 'desserts':
        actionsToDispatch = [
          setProductTypeFilter(key),
          setProductProteinTypeFilter('all'),
          setProductDietFilter('all'),
          clearDietaryRestrictions(),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter(''),
          setProductSortFilter('none')
        ];
        break;
      case 'snacks-desserts':
      case 'low-carb':
      case 'under-500-cal':
        actionsToDispatch = [
          setProductDietFilter(key),
          setProductTypeFilter('all'),
          setProductProteinTypeFilter('all'),
          clearDietaryRestrictions(),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter(''),
          setProductSortFilter('none')
        ];
        break;
      case 'protein_desc':
        actionsToDispatch = [
          setProductSortFilter(key),
          setProductTypeFilter('all'),
          setProductProteinTypeFilter('all'),
          setProductDietFilter('all'),
          clearDietaryRestrictions(),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter('')
        ];
        break;
      case 'gluten-free':
      case 'vegan':
        actionsToDispatch = [
          setProductTypeFilter('all'),
          setProductProteinTypeFilter('all'),
          setProductDietFilter('all'),
          addDietaryRestriction(key),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter(''),
          setProductSortFilter('none')
        ];
        break;
      case 'vegetarian':
        actionsToDispatch = [
          setProductTypeFilter('all'),
          setProductProteinTypeFilter(key),
          setProductDietFilter('all'),
          clearDietaryRestrictions(),
          setProductCalorieFilter({ min: 0, max: 1200 }),
          setProductCarbFilter({ min: 0, max: 250 }),
          setProductFatFilter({ min: 0, max: 100 }),
          setProductProteinFilter({ min: 0, max: 150 }),
          setProductSearchFilter(''),
          setProductSortFilter('none')
        ];
        break;
      default:


        break;
    }

    actionsToDispatch.forEach(action => dispatch(action));
  };

  const handleMobFilterButton = (direction: 'left' | 'right') => {

    if (tabsRefs.current) {
      const container = tabsRefs.current;
      const ulElement = container.querySelector('ul');
      const scrollAmount = container.clientWidth / 2;
      if (ulElement) {
        const newScrollLeft = direction === 'left'
          ? ulElement.scrollLeft - scrollAmount
          : ulElement.scrollLeft + scrollAmount;

        ulElement.scrollTo({
          left: newScrollLeft,
          behavior: 'smooth'
        });

        // Add event listener to update disabled state after scrolling
        ulElement.addEventListener('scroll', updateMobNavButtons);
      }
    }
  };

  const updateMobNavButtons = () => {
    if (tabsRefs.current) {
      const container = tabsRefs.current;
      const ulElement: any = container.querySelector('ul');
      const leftNav: any = document.querySelector('.left-nav-mob');
      const rightNav: any = document.querySelector('.right-nav-mob');

      if (ulElement.scrollLeft === 0) {
        leftNav.classList.add('disabled');
      } else {
        leftNav.classList.remove('disabled');
      }

      if (ulElement.scrollLeft + ulElement.clientWidth >= ulElement.scrollWidth) {
        rightNav.classList.add('disabled');
      } else {
        rightNav.classList.remove('disabled');
      }
    }
  };

  useEffect(() => {
    updateMobNavButtons();
  });

  return (
    <>
      <Navbar expanded={expanded} expand="lg" sticky="top" className={`shadow zindex-45 ${location.pathname.startsWith('/autoship/menu') && 'isautoshipmenunav'}`}>
        <Container fluid className={`${!isMobileDevice ? 'flex-wrap justify-content-between py-2 px-4' : ''}`}>
          {!isMobileDevice && <div className='d-flex justify-content-between align-align-items-center col-12 col-lg-auto'>
            <Navbar.Brand as={Link} to={`${QURLParams}`} className='w-25 me-0'>
              {Window.isMobile() ? <NavLogoMobile /> : <NavLogoDesktop />}
            </Navbar.Brand>
            <div className={classnames('wrap-btn d-flex d-lg-none justify-content-end align-items-center', {
              'w-lg-25': Window.isMobile()
            })}>
              <>
                {token &&
                  <div
                    className='cart-icon d-flex align-items-center position-relative d-inline-block px-3'
                    onClick={() => navigate(`/autoship/orders${QURLParams}`)}>
                    <svg
                      version="1.1"
                      id="svg9"
                      width="30"
                      height="30"
                      viewBox="0 0 682.66669 682.66669"
                      xmlns="http://www.w3.org/2000/svg"
                      className='cursor-pointer'
                    >
                      <defs id="defs13">
                        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath23">
                          <path d="M 0,512 H 512 V 0 H 0 Z" id="path21" />
                        </clipPath>
                      </defs>
                      <g id="g15" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
                        <g id="g17">
                          <g id="g19" clipPath="url(#clipPath23)">
                            <g id="g25" transform="translate(452,452)">
                              <path
                                d="m 0,0 c 22.091,0 40,-17.909 40,-40 v -352 c 0,-22.091 -17.909,-40 -40,-40 h -392 c -22.091,0 -40,17.909 -40,40 v 352 c 0,22.091 17.909,40 40,40 z"
                                style={{
                                  fill: 'none',
                                  stroke: '#000000',
                                  strokeWidth: 40,
                                  strokeLinecap: 'butt',
                                  strokeLinejoin: 'miter',
                                  strokeMiterlimit: 10,
                                  strokeDasharray: 'none',
                                  strokeOpacity: 1,
                                }}
                                id="path27"
                              />
                            </g>
                            <path
                              d="M 416,96 H 256 v 160 h 160 z"
                              style={{
                                fill: 'none',
                                stroke: '#000000',
                                strokeWidth: 40,
                                strokeLinecap: 'butt',
                                strokeLinejoin: 'miter',
                                strokeMiterlimit: 10,
                                strokeDasharray: 'none',
                                strokeOpacity: 1,
                              }}
                              id="path29"
                            />
                            <g id="g31" transform="translate(336,256)">
                              <path
                                d="M 0,0 V -60"
                                style={{
                                  fill: 'none',
                                  stroke: '#000000',
                                  strokeWidth: 40,
                                  strokeLinecap: 'butt',
                                  strokeLinejoin: 'miter',
                                  strokeMiterlimit: 10,
                                  strokeDasharray: 'none',
                                  strokeOpacity: 1,
                                }}
                                id="path33"
                              />
                            </g>
                            <g id="g35" transform="translate(20,336)">
                              <path
                                d="M 0,0 H 472"
                                style={{
                                  fill: 'none',
                                  stroke: '#000000',
                                  strokeWidth: 40,
                                  strokeLinecap: 'butt',
                                  strokeLinejoin: 'miter',
                                  strokeMiterlimit: 10,
                                  strokeDasharray: 'none',
                                  strokeOpacity: 1,
                                }}
                                id="path37"
                              />
                            </g>
                            <g id="g39" transform="translate(96,512)">
                              <path
                                d="M 0,0 V -120"
                                style={{
                                  fill: 'none',
                                  stroke: '#000000',
                                  strokeWidth: 40,
                                  strokeLinecap: 'butt',
                                  strokeLinejoin: 'miter',
                                  strokeMiterlimit: 10,
                                  strokeDasharray: 'none',
                                  strokeOpacity: 1,
                                }}
                                id="path41"
                              />
                            </g>
                            <g id="g43" transform="translate(416,512)">
                              <path
                                d="M 0,0 V -120"
                                style={{
                                  fill: 'none',
                                  stroke: '#000000',
                                  strokeWidth: 40,
                                  strokeLinecap: 'butt',
                                  strokeLinejoin: 'miter',
                                  strokeMiterlimit: 10,
                                  strokeDasharray: 'none',
                                  strokeOpacity: 1,
                                }}
                                id="path45"
                              />
                            </g>
                            <g id="g47" transform="translate(256,512)">
                              <path
                                d="M 0,0 V -120"
                                style={{
                                  fill: 'none',
                                  stroke: '#000000',
                                  strokeWidth: 40,
                                  strokeLinecap: 'butt',
                                  strokeLinejoin: 'miter',
                                  strokeMiterlimit: 10,
                                  strokeDasharray: 'none',
                                  strokeOpacity: 1,
                                }}
                                id="path49"
                              />
                            </g>
                            <g id="g51" transform="translate(136,216)">
                              <path
                                d="M 0,0 H 40"
                                style={{
                                  fill: 'none',
                                  stroke: '#000000',
                                  strokeWidth: 40,
                                  strokeLinecap: 'square',
                                  strokeLinejoin: 'miter',
                                  strokeMiterlimit: 10,
                                  strokeDasharray: 'none',
                                  strokeOpacity: 1,
                                }}
                                id="path53"
                              />
                            </g>
                            <g id="g55" transform="translate(96,136)">
                              <path
                                d="M 0,0 H 80"
                                style={{
                                  fill: 'none',
                                  stroke: '#000000',
                                  strokeWidth: 40,
                                  strokeLinecap: 'square',
                                  strokeLinejoin: 'miter',
                                  strokeMiterlimit: 10,
                                  strokeDasharray: 'none',
                                  strokeOpacity: 1,
                                }}
                                id="path57"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>}
                <Button
                  className='position-relative rounded-circle btn-cart me-3 d-md-none'
                  variant="success"
                  disabled={showCart}
                  onClick={handleMobileCartOnClick}
                  onBlur={handleMobileCartOnBlur}
                >
                  <FontAwesomeIcon icon={faBagShopping} size={'1x' as SizeProp} />
                  <Badge
                    pill
                    bg='primary'
                    className='position-absolute top-md-0 start-md-100 translate-middle cart-badge'
                  >
                    {cartIsLoaded ? cart.getItemCount() : 0}
                  </Badge>
                </Button>
                <Button
                  aria-controls="navbar-nav"
                  aria-label="Toggle navigation"
                  data-toggle="collapse"
                  onClick={() => { setExpanded(!expanded); dispatch(setIsMobileNavToggled(false)) }}
                  className="p-0 bg-transparent border-0"
                >
                  <span>
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="18" cy="18" r="17.75" stroke="#2B2B2B" strokeWidth="0.5" />
                      <circle cx="18" cy="18" r="14" fill="#2B2B2B" />
                      <path d="M13 18.3334H23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M13 15H23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M13 21.6666H23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Button>
              </>
            </div>
          </div>}
          <div className={classnames('d-flex flex-column flex-wrap flex-lg-row flex-lg-grow-1 navbar-wrap px-4 px-lg-0', {
            'isOpen': expanded
          })}>
            <div className="menu-top d-flex flex-wrap d-lg-none justify-content-between mb-3">
              <Navbar.Brand as={Link} to={`${QURLParams}`} className='w-50 m-0'>
                <Image
                  className="mm-logo border-0"
                  width="180"
                  src={logoWhite}
                  alt="MightyMeals Logo"
                />
              </Navbar.Brand>

              <div>
                <Button
                  className='position-relative rounded-circle btn-cart me-3 d-md-none'
                  variant="success"
                  onClick={() => dispatch(setShowCart(!showCart))}
                  onBlur={() => dispatch(setShowCart(cart.isEmpty() ? !showCart : true))}
                >
                  <FontAwesomeIcon icon={faBagShopping} size={'1x' as SizeProp} />
                  <Badge
                    pill
                    bg='primary'
                    className='position-absolute top-md-0 start-md-100 translate-middle border border-light cart-badge'
                  >
                    {cartIsLoaded ? cart.getItemCount() : 0}
                  </Badge>
                </Button>

                <Button
                  aria-controls="navbar-nav"
                  aria-label="Toggle navigation"
                  data-toggle="collapse"
                  onClick={() => { setExpanded(!expanded); dispatch(setIsMobileNavToggled(false)) }}
                  className="p-0 bg-transparent border-0"
                >
                  <span>
                    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="18.5" cy="18.5" r="18" stroke="white" />
                      <path d="M24.0001 13L13 24.0001" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M13 13L24.0001 24.0001" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Button>
              </div>
            </div>
            <Nav className='menu-bottom d-flex flex-lg-wrap mx-auto align-items-lg-center'>
              <ul className="m-0 p-0 d-lg-flex flex-wrap list-unstyled text-uppercase">
                <li>
                  <Nav.Link
                    as={Link}
                    to={`/order${QURLParams}`}
                    className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${isOrderPageText}`}
                    onClick={() => handleOrderFilter()}
                  >
                    Menu
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link
                    as={Link}
                    to={`/giftcards${QURLParams}`}
                    className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${isGiftCardPageText}`}
                    onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                  >
                    GIFT CARDS & DISCOUNTS
                  </Nav.Link>
                </li>
                {token &&
                  <li className='aboutNav-link'>
                    <span onClick={handleClick} className='d-lg-none mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex text-white cursor-pointer'>
                      MIGHTYFIT
                      <span className="d-lg-none pe-none">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
                          <path d="M3.99995 8L12 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9.33328 5.33337L12 8.00005L9.33328 10.6667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </span>
                    <ul className='d-none m-0 p-0 d-lg-flex flex-wrap list-unstyled'>
                      {(Window.isMobile() || Window.isTablet()) &&
                        <>
                          <li>
                            <Nav.Link
                              as={Link}
                              to={`/mightyfit${QURLParams}`}
                              className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${isMightyFitPageText}`}
                              onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                            >
                              FITNESS VIDEOS
                              <span className="d-lg-none">
                                <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                              </span>
                            </Nav.Link>
                          </li>
                          <li>
                            <Nav.Link
                              as={Link}
                              to={`/nutrition?utm_source=main-nav${AndURLParams}`}
                              className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${isMightyFitNutritionPageText}`}
                              onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                            >
                              FREE NUTRITIONAL ADVICE
                              <span className="d-lg-none">
                                <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                              </span>
                            </Nav.Link>
                          </li>
                        </>
                      }
                      {!(Window.isMobile() || Window.isTablet()) &&
                        <li className='nav-dropdown'>
                          <Dropdown align="end">
                            <Dropdown.Toggle
                              id="account-dropdown"
                              size="lg"
                              variant="white"
                              className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex align-items-center text-secondary nav-link 
                              ${isMightyFitPage ? isMightyFitPageText :
                                  mightyFitNutritionPage ? isMightyFitNutritionPageText : ''}`}>
                              MIGHTYFIT
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item as={Link} to={`/mightyfit${QURLParams}`} className={`${isMightyFitPageText}`}>
                                FITNESS VIDEOS
                              </Dropdown.Item>
                              <Dropdown.Item as={Link} to={`/nutrition?utm_source=main-nav${AndURLParams}`} className={`${isMightyFitNutritionPageText}`}>
                                FREE NUTRITIONAL ADVICE
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>}
                    </ul>
                  </li>
                }
                {!token && <><li>
                  <Nav.Link
                    as={Link}
                    to={`/reviews${QURLParams}`}
                    className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${isReviewPageText}`}
                    onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                  >
                    REVIEWS
                  </Nav.Link>
                </li>
                  <li>
                    <Nav.Link
                      as={Link}
                      to={`/how-it-works${QURLParams}`}
                      className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${howItWorksPageText}`}
                      onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                    >
                      HOW IT WORKS
                    </Nav.Link>
                  </li></>}
                <li className='aboutNav-link'>
                  <span onClick={handleClick} className='d-lg-none mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex text-white cursor-pointer'>
                    About
                    <span className="d-lg-none pe-none">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
                        <path d="M3.99995 8L12 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.33328 5.33337L12 8.00005L9.33328 10.6667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </span>
                  <ul className='d-none m-0 p-0 d-lg-flex flex-wrap list-unstyled'>
                    {(Window.isMobile() || Window.isTablet()) &&
                      <>
                        <li>
                          <Nav.Link
                            as={Link}
                            to={`/catering${QURLParams}`}
                            className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${cateringPageText}`}
                            onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                          >
                            CATERING
                            <span className="d-lg-none">
                              <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                            </span>
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                            as={Link}
                            to={`/our-mission${QURLParams}`}
                            className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${ourMissionPageText}`}
                            onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                          >
                            OUR MISSION
                            <span className="d-lg-none">
                              <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                            </span>
                          </Nav.Link>
                        </li>
                        {token && <li>
                          <Nav.Link
                            as={Link}
                            to={`/how-it-works${QURLParams}`}
                            className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${howItWorksPageText}`}
                            onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                          >
                            HOW IT WORKS
                            <span className="d-lg-none">
                              <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                            </span>
                          </Nav.Link>
                        </li>}
                        <li>
                          <Nav.Link
                            as={Link}
                            to={`/sustainability${QURLParams}`}
                            className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${sustainabilityPageText}`}
                            onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                          >
                            SUSTAINABILITY
                            <span className="d-lg-none">
                              <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                            </span>
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                            as={Link}
                            to={`/enterprise-partnerships${QURLParams}`}
                            className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${enterPrisePartnerShipPageText}`}
                            onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                          >
                            ENTERPRISE PARTNERSHIPS
                            <span className="d-lg-none">
                              <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                            </span>
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                            as={Link}
                            to={`/affiliate-partnerships${QURLParams}`}
                            className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${affiliatePartnerShipPageText}`}
                            onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                          >
                            AFFILIATE PARTNERSHIPS
                            <span className="d-lg-none">
                              <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                            </span>
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                            as={Link}
                            to={`/mightyfit-partnerships${QURLParams}`}
                            className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${mightyFitPartnerShipPageText}`}
                            onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                          >
                            MIGHTYFIT PARTNERSHIPS
                            <span className="d-lg-none">
                              <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                            </span>
                          </Nav.Link>
                        </li>
                        <li className='aboutNav-link'>
                          <span onClick={handleClick} className='d-lg-none mx-lg-2 mx-xl-3 fw-400 py-2 p-lg-0 justify-content-between d-flex text-white cursor-pointer'>
                            MIGHTYEDUCATION
                            <span className="d-lg-none pe-none">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
                                <path d="M3.99995 8L12 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.33328 5.33337L12 8.00005L9.33328 10.6667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </span>
                          </span>
                          <ul className='d-none m-0 p-0 d-lg-flex flex-wrap list-unstyled'>
                            <li>
                              <Nav.Link
                                as={Link}
                                to={`/mightyeducation${QURLParams}`}
                                className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${mightyEducationPageText}`}
                                onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                              >
                                OVERVIEW
                                <span className="d-lg-none">
                                  <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                                </span>
                              </Nav.Link>
                            </li>
                            <li>
                              <Nav.Link
                                as={Link}
                                to={`/mightyeducation/umd-terps${QURLParams}`}
                                className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${umdTerpsPageText}`}
                                onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                              >
                                Case Study: Maryland Terrapins
                                <span className="d-lg-none">
                                  <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                                </span>
                              </Nav.Link>
                            </li>
                            <li>
                              <Nav.Link
                                as={Link}
                                to={`/mightyeducation/va-cavaliers${QURLParams}`}
                                className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${vaCavaliersPageText}`}
                                onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                              >
                                Case Study: Virginia Cavaliers
                                <span className="d-lg-none">
                                  <FontAwesomeIcon icon={faArrowRight} size={"sm" as SizeProp} ></FontAwesomeIcon>
                                </span>
                              </Nav.Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    }
                    {!(Window.isMobile() || Window.isTablet()) &&
                      <li className='nav-dropdown'>
                        <Dropdown align="end">
                          <Dropdown.Toggle
                            id="account-dropdown"
                            size="lg"
                            variant="white"
                            className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex align-items-center text-secondary nav-link 
                          ${(token && howItWorksPage) ? howItWorksPageText :
                                cateringPage ? cateringPageText :
                                  ourMissionPage ? ourMissionPageText :
                                    sustainabilityPage ? sustainabilityPageText :
                                      affiliatePartnerShipPage ? affiliatePartnerShipPageText :
                                        enterPrisePartnerShipPage ? enterPrisePartnerShipPageText :
                                          mightyFitPartnerShipPage ? mightyFitPartnerShipPageText :
                                            umdTerpsPage ? umdTerpsPageText :
                                              vaCavaliersPage ? vaCavaliersPageText :
                                                mightyEducationPage ? mightyEducationPageText : ''}`}>
                            ABOUT
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item as={Link} to={`/catering${QURLParams}`} className={`${cateringPageText}`}>
                              CATERING
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={`/our-mission${QURLParams}`} className={`${ourMissionPageText}`}>
                              OUR MISSION
                            </Dropdown.Item>
                            {token && <Dropdown.Item as={Link} to={`/how-it-works${QURLParams}`} className={`${howItWorksPageText}`}>
                              HOW IT WORKS
                            </Dropdown.Item>}
                            <Dropdown.Item as={Link} to={`/sustainability${QURLParams}`} className={`${sustainabilityPageText}`}>
                              SUSTAINABILITY
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={`/enterprise-partnerships${QURLParams}`} className={`${affiliatePartnerShipPageText}`}>
                              ENTERPRISE PARTNERSHIPS
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={`/affiliate-partnerships${QURLParams}`} className={`${enterPrisePartnerShipPageText}`}>
                              AFFILIATE PARTNERSHIPS
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={`/mightyfit-partnerships${QURLParams}`} className={`${mightyFitPartnerShipPageText}`}>
                              MIGHTYFIT PARTNERSHIPS
                            </Dropdown.Item>
                            <li className='text-secondary dropdown-item cursor-pointer'>
                              <Dropdown align="end">
                                <Dropdown.Toggle
                                  id="account-dropdown"
                                  size="lg"
                                  variant="white"
                                  className={`fw-400 medudropdown text-secondary 
                                    ${umdTerpsPage ? umdTerpsPageText :
                                      vaCavaliersPage ? vaCavaliersPageText :
                                        mightyEducationPage ? mightyEducationPageText : ''}`}>
                                  MIGHTYEDUCATION
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='menuedu'>
                                  <Dropdown.Item as={Link} to={`/mightyeducation${QURLParams}`} className={`${mightyEducationPageText}`}>
                                    OVERVIEW
                                  </Dropdown.Item>
                                  <Dropdown.Item as={Link} to={`/mightyeducation/umd-terps${QURLParams}`} className={`${umdTerpsPageText}`}>
                                    Case Study: Maryland Terrapins
                                  </Dropdown.Item>
                                  <Dropdown.Item as={Link} to={`/mightyeducation/va-cavaliers${QURLParams}`} className={`${vaCavaliersPageText}`}>
                                    Case Study: Virginia Cavaliers
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>}
                  </ul>
                </li>
                <li>
                  <Nav.Link
                    className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${helpPageText}`}
                    onClick={handleHelpClick}
                  >
                    HELP
                  </Nav.Link>
                </li>
              </ul>
              <div className="d-lg-none w-100 my-3 account-menu">
                {token &&
                  <Nav.Link
                    as={Link}
                    to={`/my-account${QURLParams}`}
                    className={`mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0 justify-content-between d-flex ${myAccountPageText} ${!Window.isMobile() ? 'my-3' : ''}`}
                    onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                  >
                    MY ACCOUNT
                  </Nav.Link>
                }
                {!token &&
                  <Link
                    to={`/my-account${QURLParams}`}
                    className="btn btn-success font-barlow fw-600 w-100"
                    onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
                  >
                    Login / Sign Up
                  </Link>}
                {token &&
                  <Link
                    role="button"
                    className="btn btn-success font-barlow fw-600 w-100"
                    onClick={() => { handleLogout(); }} to={`/my-account${QURLParams}`}>
                    Logout
                  </Link>}
              </div>
            </Nav>
          </div>
          <Nav className='d-none d-lg-flex ms-auto'>
            {token &&
              <div
                className='cart-icon d-flex align-items-center position-relative d-inline-block p-1'
                onClick={() => navigate(autoshipRedirect)}>
                <svg
                  version="1.1"
                  id="svg9"
                  width="30"
                  height="30"
                  viewBox="0 0 682.66669 682.66669"
                  xmlns="http://www.w3.org/2000/svg"
                  className='cursor-pointer'
                >
                  <defs id="defs13">
                    <clipPath clipPathUnits="userSpaceOnUse" id="clipPath23">
                      <path d="M 0,512 H 512 V 0 H 0 Z" id="path21" />
                    </clipPath>
                  </defs>
                  <g id="g15" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
                    <g id="g17">
                      <g id="g19" clipPath="url(#clipPath23)">
                        <g id="g25" transform="translate(452,452)">
                          <path
                            d="m 0,0 c 22.091,0 40,-17.909 40,-40 v -352 c 0,-22.091 -17.909,-40 -40,-40 h -392 c -22.091,0 -40,17.909 -40,40 v 352 c 0,22.091 17.909,40 40,40 z"
                            style={{
                              fill: 'none',
                              stroke: '#000000',
                              strokeWidth: 40,
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeMiterlimit: 10,
                              strokeDasharray: 'none',
                              strokeOpacity: 1,
                            }}
                            id="path27"
                          />
                        </g>
                        <path
                          d="M 416,96 H 256 v 160 h 160 z"
                          style={{
                            fill: 'none',
                            stroke: '#000000',
                            strokeWidth: 40,
                            strokeLinecap: 'butt',
                            strokeLinejoin: 'miter',
                            strokeMiterlimit: 10,
                            strokeDasharray: 'none',
                            strokeOpacity: 1,
                          }}
                          id="path29"
                        />
                        <g id="g31" transform="translate(336,256)">
                          <path
                            d="M 0,0 V -60"
                            style={{
                              fill: 'none',
                              stroke: '#000000',
                              strokeWidth: 40,
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeMiterlimit: 10,
                              strokeDasharray: 'none',
                              strokeOpacity: 1,
                            }}
                            id="path33"
                          />
                        </g>
                        <g id="g35" transform="translate(20,336)">
                          <path
                            d="M 0,0 H 472"
                            style={{
                              fill: 'none',
                              stroke: '#000000',
                              strokeWidth: 40,
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeMiterlimit: 10,
                              strokeDasharray: 'none',
                              strokeOpacity: 1,
                            }}
                            id="path37"
                          />
                        </g>
                        <g id="g39" transform="translate(96,512)">
                          <path
                            d="M 0,0 V -120"
                            style={{
                              fill: 'none',
                              stroke: '#000000',
                              strokeWidth: 40,
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeMiterlimit: 10,
                              strokeDasharray: 'none',
                              strokeOpacity: 1,
                            }}
                            id="path41"
                          />
                        </g>
                        <g id="g43" transform="translate(416,512)">
                          <path
                            d="M 0,0 V -120"
                            style={{
                              fill: 'none',
                              stroke: '#000000',
                              strokeWidth: 40,
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeMiterlimit: 10,
                              strokeDasharray: 'none',
                              strokeOpacity: 1,
                            }}
                            id="path45"
                          />
                        </g>
                        <g id="g47" transform="translate(256,512)">
                          <path
                            d="M 0,0 V -120"
                            style={{
                              fill: 'none',
                              stroke: '#000000',
                              strokeWidth: 40,
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeMiterlimit: 10,
                              strokeDasharray: 'none',
                              strokeOpacity: 1,
                            }}
                            id="path49"
                          />
                        </g>
                        <g id="g51" transform="translate(136,216)">
                          <path
                            d="M 0,0 H 40"
                            style={{
                              fill: 'none',
                              stroke: '#000000',
                              strokeWidth: 40,
                              strokeLinecap: 'square',
                              strokeLinejoin: 'miter',
                              strokeMiterlimit: 10,
                              strokeDasharray: 'none',
                              strokeOpacity: 1,
                            }}
                            id="path53"
                          />
                        </g>
                        <g id="g55" transform="translate(96,136)">
                          <path
                            d="M 0,0 H 80"
                            style={{
                              fill: 'none',
                              stroke: '#000000',
                              strokeWidth: 40,
                              strokeLinecap: 'square',
                              strokeLinejoin: 'miter',
                              strokeMiterlimit: 10,
                              strokeDasharray: 'none',
                              strokeOpacity: 1,
                            }}
                            id="path57"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>}
            {token &&
              <Nav.Link
                as={Link}
                to={`/my-account${QURLParams}`}
                className={classnames('mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400  py-2 p-lg-0 border-0', {
                  'text-black': Window.isMobile() ||
                    location.pathname.startsWith('/my-account') ||
                    location.pathname.startsWith('/autoship/orders'),
                  'my-3': !Window.isMobile()
                })}
                onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
              >
                MY ACCOUNT
              </Nav.Link>
            }
            {Window.isMobile() ?
              <Button
                className='text-dark position-relative me-auto d-none d-lg-block'
                variant="link"
                disabled={showCart}
                onClick={() => handleMobileCartOnClick()}
              >
                <FontAwesomeIcon icon={faBagShopping} size={'2x' as SizeProp} />
                <Badge
                  pill
                  bg='secondary'
                  className='position-absolute top-md-0 start-md-100 translate-middle'
                >
                  {cartIsLoaded ? cart.getItemCount() : 0}
                </Badge>
              </Button>
              :
              <CartDropdown />
            }
            {!token &&
              <Nav.Link
                as={Link}
                state={{ Tabkey: 'login' }}
                to={`/my-account${QURLParams}`}
                className={classnames('mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0', {
                  'text-black': Window.isMobile(),
                  'my-3': !Window.isMobile()
                })}
                onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
              >
                LOGIN
              </Nav.Link>}
            {!token &&
              <Nav.Link
                as={Link}
                state={{ Tabkey: 'register' }}
                to={`/my-account${QURLParams}`}
                className={classnames('mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0', {
                  'text-black': Window.isMobile(),
                  'my-3': !Window.isMobile()
                })}
                onClick={() => { setExpanded(false); dispatch(setIsMobileNavToggled(false)) }}
              >
                SIGN UP
              </Nav.Link>}
            {token &&
              <Nav.Link
                role="button"
                className={classnames('mx-lg-2 mx-xl-3 font-league-gothic fs-26px fw-400 py-2 p-lg-0', {
                  'text-black': Window.isMobile(),
                  'my-3': !Window.isMobile()
                })}
                onClick={() => { handleLogout() }}
              >
                LOGOUT
              </Nav.Link>}
          </Nav>
        </Container>
      </Navbar>
      {location.pathname.startsWith('/order') &&
        <>
          <div className='d-md-none px-4 pb-3 pt-3 product-filters'>
            <div className="product-filters-list" ref={tabsRefs}>
              <Tabs
                defaultActiveKey="all"
                id="product-filters-tabs"
                className="mb-3"
                onSelect={handleQuickFilter}
              >
                <Tab eventKey="all" title="All Meals"></Tab>
                <Tab eventKey="bulk" title="A la carte"></Tab>
                <Tab eventKey="snacks" title="Snacks"></Tab>
                <Tab eventKey="desserts" title="Dessert"></Tab>
                <Tab eventKey="low-carb" title="Low Carb"></Tab>
                <Tab eventKey="under-500-cal" title="Under 500 Calories"></Tab>
                <Tab eventKey="protein_desc" title="High Protein"></Tab>
                <Tab eventKey="vegan" title="Vegan"></Tab>
                <Tab eventKey="vegetarian" title="Vegetarian"></Tab>
                <Tab eventKey="gluten-free" title="Gluten Free"></Tab>
                <Tab eventKey="breakfast" title="Breakfast"></Tab>
              </Tabs>
              <div className="product-filters-navs">
                <div
                  className="left-nav-mob nav arrow-left arrow-both swiper-button-prev"
                  onClick={() => handleMobFilterButton('left')}
                >
                </div>
                <div
                  className="right-nav-mob nav arrow-right arrow-both swiper-button-next"
                  onClick={() => handleMobFilterButton('right')}>
                </div>
              </div>
            </div>
            <div className='pb-3'>
              <ProductFilterTags />
            </div>

            <Row className='product-btn-part'>
              <Col xs={6}>
                <Button
                  variant='dark'
                  className='font-glegoo fs-16px w-100'
                  disabled={showProductFilters}
                  onClick={() => dispatch(setShowProductFilters(true))}
                >
                  Filter By
                  <FontAwesomeIcon
                    className='ms-2'
                    icon={faFilter}
                  ></FontAwesomeIcon>
                </Button>
              </Col>
              <Col xs={6}>
                <SortFilter />
              </Col>
            </Row>
          </div>
        </>
      }
      {expanded &&
        <div className='fixed-top bg-black w-100 h-100 zindex-40 opacity-50'></div>
      }
    </>

  );
}
