import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import DeliveryObj from '../delivery/deliveryObj';
import { selectDeliveryData } from '../delivery/deliverySlice';
import {
  selectCartCoupon, selectCartDeliveryDate,
  selectCartZipcode,
  setCartDeliveryDate
} from './cartSlice';
import DatePicker from 'react-datepicker';
import CartObj from './cartObj';
import { useFormikContext } from 'formik';
import { selectShippingMethods } from '../shipping/shippingSlice';
import { FormValues } from '../../components/checkout-form/interfaces';
import ShippingMethodObj from '../shipping/shippingMethodObj';
import { selectCustomer } from '../customer/customerSlice';
import { selectProducts } from '../products/productsSlice';
import { useLocation } from 'react-router-dom';
import { selectUtmShipping, selectUtmThreshold } from '../mobile/mobileSlice';

interface Props {
  cart: CartObj;
}
export default function DeliveryDate({ cart }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const formik = useFormikContext<FormValues>();
  const [errorMsg, setErrorMsg] = useState('');
  const cartDeliveryDate = useSelector(selectCartDeliveryDate);
  const customer = useSelector(selectCustomer);
  const products = useSelector(selectProducts);
  const coupon = useSelector(selectCartCoupon);
  const [val, setVal] = useState(cartDeliveryDate);
  const zip = useSelector(selectCartZipcode);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deliveryInfo = new DeliveryObj(useSelector(selectDeliveryData));
  const shippingMethods = useSelector(selectShippingMethods);
  const utmThreshold = useSelector(selectUtmThreshold);
  const utmShipping = useSelector(selectUtmShipping);
  const [shippingMethodbyZip, setShippingMethodByZip] = useState<ShippingMethodObj[]>([]);
  const city = formik?.values?.use_shipping ? formik?.values?.shipping_city :
    formik?.values?.billing_city;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let isShipping: ShippingMethodObj[] = [];
  const isUtmThreshold = (utmThreshold && utmThreshold.ship_threshold_value) ? true : false;
  const isUtmShipping = (utmShipping && utmShipping.ship_price_value) ? true : false;
  const utmThresholdValue = utmThreshold && utmThreshold.ship_threshold_value;
  const utmShippingValue = utmShipping && utmShipping.ship_price_value;

  useEffect(() => {
    if (location.state !== null && location.state.date) {
      setVal(location.state.date);
    }
  }, [location])

  useEffect(() => {
    getShippingMethodDates(zip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip])

  useEffect(() => {
    if (cartDeliveryDate) {
      if (!deliveryInfo.dateIsAvailable(new Date(cartDeliveryDate), '', zip, shippingMethodbyZip[0])) {
        setErrorMsg('That date is unavailable, Please choose another.');
      } else {
        setErrorMsg('');
      }
      setVal(new Date(cartDeliveryDate));
    }
  }, [city, cartDeliveryDate, shippingMethodbyZip, zip, deliveryInfo])

  const getShippingMethodDates = (zipCode: any) => {
    let matchedMethods = [];

    let hasFreeDelivery = false;

    for (const method of shippingMethods) {
      const sm = new ShippingMethodObj(method);
      if (sm.isMatch(zipCode, cart, products, coupon, customer, isUtmThreshold, utmThresholdValue)) {
        if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
          hasFreeDelivery = false;
          matchedMethods.push(sm);
        } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
          if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
            hasFreeDelivery = true;
            matchedMethods.push(sm);
          } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {
            matchedMethods.push(sm);
            if (sm.data.ups_delivery_method === true && isShipping.length === 0) {
              isShipping.push(sm);
            }
          }
        }

      }
    }
    const UPSshipping = matchedMethods.filter(sm => sm.data.ups_delivery_method === true);

    if (hasFreeDelivery) {
      matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
    }

    if (isUtmThreshold || isUtmShipping) {
      if (matchedMethods?.length > 0) {
        const originalMethod = matchedMethods[0];

        const newData = JSON.parse(JSON.stringify({
          ID: originalMethod.data.ID,
          title: originalMethod.data.title,
          cost: originalMethod.data.cost,
          ups_delivery_method: originalMethod.data.ups_delivery_method,
          condition_groups: originalMethod.data.condition_groups
        }));

        if (utmThresholdValue !== "0" && isUtmThreshold) {
          const subtotalGroup = newData.condition_groups[0]?.find((group: { condition: string; }) => group.condition === "subtotal");
          const otherGroups = newData.condition_groups[0]?.filter((group: { condition: string; }) => group.condition !== "subtotal") || [];

          if (subtotalGroup) {
            newData.condition_groups[0] = [
              ...otherGroups,
              { ...subtotalGroup, value: utmThresholdValue }
            ];
          }
        }

        if (utmShippingValue && newData.cost !== "0" && newData.ups_delivery_method === true) {
          newData.cost = utmShippingValue;
        }

        const updatedMethod = new ShippingMethodObj({
          ...originalMethod,
          data: newData
        });

        updatedMethod.data = newData;

        matchedMethods = [updatedMethod];
      }
    }

    if (UPSshipping.length > 0) {
      setShippingMethodByZip(UPSshipping);
    } else {
      setShippingMethodByZip(matchedMethods);
    }
  }

  const isDeliveryDay = (date: Date) => {
    const today = new Date();
    const blockedDates = deliveryInfo.getBlockedDates(city, zip);

    if (shippingMethodbyZip[0]?.data.ups_delivery_method === true) {
      const isDisabledTuesday = blockedDates.filter((blockedDate: any) => {
        const blockedDay = new Date(blockedDate).getDay();
        return blockedDay === 2;
      });

      if (isDisabledTuesday.length > 0) {
        let isNextWednesdayEnabled = false;

        isDisabledTuesday.forEach((tuesday: any) => {
          const disabledTuesday = new Date(tuesday);
          const nextWednesday = new Date(tuesday);
          nextWednesday.setDate(disabledTuesday.getDate() + 1);

          if (date.toDateString() === nextWednesday.toDateString()) {
            isNextWednesdayEnabled = true;
          }
        });

        if (isNextWednesdayEnabled) {
          const nextWednesday = new Date(today);
          nextWednesday.setDate(today.getDate() + ((3 + 7 - today.getDay()) % 7));
          if (date.toDateString() === nextWednesday.toDateString()) {
            return false;
          }
          return true;
        }
      }

      if (today.getDay() === 2 || today.getDay() === 3) {
        const nextThursday = new Date(today);
        const nextFriday = new Date(today);
        nextThursday.setDate(today.getDate() + ((4 + 7 - today.getDay()) % 7));
        nextFriday.setDate(today.getDate() + ((5 + 7 - today.getDay()) % 7));
        if (date.toDateString() === nextThursday.toDateString() || date.toDateString() === nextFriday.toDateString()) {
          return false;
        }
      } else if (today.getDay() > 4 || today.getDay() === 1 || today.getDay() === 0) {
        const nextWednesday = new Date(today);
        const nextTuesday = new Date(today);
        nextTuesday.setDate(today.getDate() + ((2 + 7 - today.getDay()) % 7));
        nextWednesday.setDate(today.getDate() + ((3 + 7 - today.getDay()) % 7));
        if (date.toDateString() === nextTuesday.toDateString()) {
          return false;
        }
        if (date.toDateString() === nextWednesday.toDateString()) {
          return false;
        }
      }

      return deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]).includes(date.getDay());
    } else {
      return deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]).includes(date.getDay());
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let date = e as unknown as Date;
    setVal(date);
    setErrorMsg('');
    if (!deliveryInfo.dateIsAvailable(new Date(date), '', zip, shippingMethodbyZip[0])) {
      setErrorMsg("That date is unavailable, Please choose another.");
      return;
    }
    dispatch(setCartDeliveryDate(date));
  }

  return (
    <div className='delivery-date'>
      <hr />
      <div className='delivery-date-label'>Your Delivery Date:</div>
      <Form.Control
        as={DatePicker}
        style={{ width: '100%' }}
        id="delivery_date"
        type="text"
        isInvalid={Boolean(errorMsg)}
        isValid={Boolean(cartDeliveryDate)}
        autoComplete="foo"
        calendarStartDay={1}
        filterDate={isDeliveryDay}
        excludeDates={deliveryInfo.getBlockedDates('', zip, shippingMethodbyZip[0])}
        selected={(val === undefined || val === null) ? val : new Date(val)}
        minDate={deliveryInfo.getStartDate(zip)}
        maxDate={deliveryInfo.getEndDate()}
        onChange={handleChange}
      />
      {errorMsg &&
        <div className='text-danger fs-13px mt-1'>
          {errorMsg}
        </div>
      }
    </div>
  )
}