import Helper from "../../utils/Helper";
import CartObj from "../cart/cartObj";
import CustomerObj from '../customer/customerObj';
import ProductObj from "../products/productObj";
export default class CouponObj {
  data: Record<string, any>;

  constructor(couponObj: Record<string, any>) {
    this.data = couponObj;
  }

  getCartSubtotalDiscount = (
    cart: CartObj,
    products: Array<Record<string, any>>,
    formatted = false,
    customer?: CustomerObj
  ) => {
    let result = 0;
    const cartSubtotal = cart.getSubtotal(null, null, null) as number;
    if (this.data.discount_type === 'fixed_product') {
      result = this.#getFixedProductDiscount(cart, products);
    } else if (this.data.discount_type === 'fixed_cart') {
      let couponAmt = parseFloat(this.data.amount);
      if (couponAmt >= cartSubtotal) {
        result = cartSubtotal;
      } else {
        result = couponAmt;
      }
    } else if (this.data.discount_type === 'percent_product' || this.data.discount_type === 'percent') {
      if (this.data.code.startsWith('ref')) {
        if (this.data.meal_limit) {
          const discountedTotal = customer ?
            cart.getSubtotal(this, products, customer) as number :
            cart.getSubtotal(this, products, null) as number;
          result = cartSubtotal - discountedTotal;
        } else {
          const cartSubtotal = cart.getSubtotal(null, null, null) as number;
          result = cartSubtotal * (this.data.amount / 100);
        }
      } else {
        let percentage = parseFloat(this.data.amount);
        if (this.data.product_ids?.length > 0) {
          result = percentage *
            (cart.getProductSubtotal(this.data.product_ids) as number) / 100.0;
        } else if (this.data.product_categories?.length > 0) {
          result = percentage * (cart.getProductCatSubtotal(
            this.data.product_categories, products) as number) / 100.0;
        } else {
          result = percentage * cartSubtotal / 100.0;
        }
      }
    }

    if (!formatted) return result;

    return Helper.formattedCurrency(result);
  }

  getCartTotalDiscount = (
    cartTotal: number,
    formatted = false
  ) => {
    let result;
    if (this.data.discount_type === 'percent') {
      let percentage = parseFloat(this.data.amount);
      result = percentage * cartTotal / 100.0;
    } else {
      result = 0;
    }
    if (!formatted) return result;

    return Helper.formattedCurrency(result);
  }

  getCartPercentDiscount = (
    products: Array<Record<string, any>>,
    cartItems: Record<string, any>,
    coupon: CouponObj,
    formatted = false,
    customer?: CustomerObj
  ) => {
    let discount = 0;
    let totalDiscountableQty = 0;
    let remainingDiscountableQty = 0;
    const discountAmount = coupon && coupon.data.amount ? Number(coupon.data.amount) : 0;
    const limitQTY = coupon && coupon.data.meal_limit ?
      Number(coupon.data.meal_limit) : 0;

    const sortedItems = Object.values(cartItems).sort((a, b) => {
      const productA = ProductObj.getById(products, a.product_id);
      const productB = ProductObj.getById(products, b.product_id);
      const priceA = productA && customer && customer.getProductPrice(productA, false);
      const priceB = productB && customer && customer.getProductPrice(productB, false);
      return Number(priceA) - Number(priceB);
    });

    const productList = customer ? sortedItems : Object.values(cartItems);

    for (const item of productList) {
      const product = ProductObj.getById(products, item.product_id);
      const isCouponApplicable = product && product.data.categories && product.data.categories.some((category: any) =>
        coupon.data.product_categories.includes(category.id)
      );

      if (isCouponApplicable) {
        remainingDiscountableQty = Math.min(limitQTY - totalDiscountableQty, cartItems[product.data.id].product_qty);
        totalDiscountableQty += remainingDiscountableQty;

        if ((product.data.price * remainingDiscountableQty) >= 100) {
          discount += (product.data.price * remainingDiscountableQty);
        } else {
          const QTY = limitQTY === 0 ? cartItems[product.data.id].product_qty : remainingDiscountableQty;
          discount += (product.data.price * QTY) * (discountAmount / 100);
        }

        if (totalDiscountableQty >= limitQTY) break;
      }
    }

    if (!formatted) return discount;

    return Helper.formattedCurrency(discount);
  }

  getExpiryDate = () => {
    if (!this.data.expiry_date) {
      return "Never expires";
    }
    return new Date(this.data.expiry_date).toLocaleDateString(
      'en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  }

  getSmartCouponAmount = (
    cartTotal: number,
    formatted = false
  ) => {
    let couponAmt = this.data.amount as number;
    let result;

    if (couponAmt >= cartTotal) {
      result = cartTotal;
    } else {
      // Minimum Stripe CC charge is 50 cents
      let difference = cartTotal - couponAmt;
      if (difference < 0.5) {
        result = cartTotal - 0.5;
      } else {
        result = couponAmt;
      }
    }

    if (!formatted) return result;

    if (this.data.discount_type === 'percent_product') return couponAmt;

    return Helper.formattedCurrency(result);
  }

  getTitle = () => {
    // let result = this.getAmount(true) + ' ';
    let result = '';

    switch (this.data.discount_type) {
      case 'fixed_cart':
        result += this.getAmount(true) + ' CART DISCOUNT';
        break;
      case 'percent':
        result += this.getPercentAmount() + ' OFF CART DISCOUNT';
        break;
      case 'smart_coupon':
        result += this.getAmount(true) + ' GIFT CARD';
        break;
      default:
        result += this.getAmount(true) + ' DISCOUNT';
    }

    return result;
  }

  getAmount = (formatted = false) => {
    if (!formatted) return parseFloat(this.data.amount);

    return Helper.formattedCurrency(this.data.amount);
  }

  getPercentAmount = () => {
    return parseFloat(this.data.amount) + '%';
  }

  getNumUses = (customer: CustomerObj) => {
    if (!('id' in customer.data)) return 0;

    return this.data.used_by.reduce((numUses: number, custId: string) => {
      return customer.data.id === parseInt(custId) ? numUses + 1 : numUses;
    }, 0);
  }

  hasNewUserRestriction = () => {
    let meta = this.#metaItem('sc_restrict_to_new_user');
    if (meta && meta.value === "yes") return true;

    meta = this.#metaItem('_wjecf_first_purchase_only');

    return meta ? meta.value === "yes" : false;
  }

  getEarnedPoints = () => {
    const meta = this.#metaItem("_wc_points_modifier");
    return meta ? meta.value : 0;
  }

  isExpired = () => {
    if (!this.data.date_expires) return false;

    const expiryDate = new Date(this.data.date_expires);
    const dateToday = new Date();

    if (dateToday.getTime() > expiryDate.getTime()) return true;

    return false;
  }

  isPointsCoupon = (customer: CustomerObj) => {
    return this.data.code === customer.data.mightypoints_discount_code;
  }

  isPreTax = () => {
    if (['fixed_cart', 'fixed_product'].includes(this.data.discount_type)) {
      return true;
    }
    if (
      this.data.discount_type === 'percent' &&
      ((this.data.product_ids && this.data.product_ids.length > 0) ||
        (this.data.product_categories && this.data.product_categories.length > 0))
    ) {
      return true;
    }
    return false;
  }

  validate = (
    email: string,
    customer: CustomerObj,
    cart: CartObj,
    products: Array<Record<string, any>>
  ) => {
    let result = { error: false, message: '' };

    if (!this.validateAgainstEmail(email)) {
      result.error = true;
      result.message = 'Coupon "' + this.data.code + '" is invalid.';
      return result;
    }

    if (this.isExpired()) {
      result.error = true;
      result.message = 'That coupon has expired';
      return result;
    }

    result = this.validateAgainstCustomer(customer);
    if (result.error) return result;

    result = this.validateUsage(customer);
    if (result.error) return result;

    return this.validateAgainstCart(cart, products);
  }

  validateAgainstCart = (cart: CartObj, products: Array<Record<string, any>>) => {
    let result;

    result = this.#validateAgainstCartMinimum(cart);
    if (result.error) return result;

    result = this.#validateAgainstCartProducts(cart, products);

    return result;
  }

  validateAgainstCustomer = (customer: CustomerObj) => {
    let result = { error: false, message: '' };

    if (customer.hasMembership() && this.data.discount_type !== 'smart_coupon') {
      result.error = true;
      result.message = 'That discount is not valid for membership discount holders.';
      return result;
    }

    if (!this.hasNewUserRestriction()) return result;

    if (customer.isNew()) return result;

    if (this.data.used_by && this.data.usage_limit_per_user) {
      const usageCount = this.data.used_by.filter((id: number) => id === Number(customer.data.id)).length;
      const usageLimit = Number(this.data.usage_limit_per_user);
      if (usageCount < usageLimit) {
        return result;
      }
    }

    result.error = true;
    result.message = "That coupon is restricted to new customers.";
    return result;
  }

  validateAgainstEmail = (email: string) => {
    if (!this.data.email_restrictions ||
      this.data.email_restrictions.length === 0) return true;

    if (!email) return false;

    for (const restrictedEmail of this.data.email_restrictions) {
      if (restrictedEmail.toLocaleLowerCase() === email.toLowerCase()) {
        return true;
      }
    }

    return false;
  }

  validateUsage = (customer: CustomerObj) => {
    let result = { error: false, message: '' };

    if (
      (this.data.usage_limit && this.data.usage_count >= this.data.usage_limit) ||
      (this.data.usage_limit_per_user && this.getNumUses(customer) >= this.data.usage_limit_per_user)
    ) {
      result.error = true;
      result.message = "Coupon usage limit has been reached.";
      return result;
    }

    return result;
  }

  // -------------------------------------
  // P R I V A T E   M E T H O D S
  // -------------------------------------

  #getFixedProductDiscount = (
    cart: CartObj,
    products: Array<Record<string, any>>,
  ) => {
    let discount = 0;
    let maxItems = this.data.limit_usage_to_x_items;
    let itemsApplied = 0;
    const cartCopy = new CartObj(JSON.parse(JSON.stringify(cart.items))); // Deep copy

    if (maxItems === 0) maxItems = 1000; // Basically unlimited

    cartCopy.removeProductsWithoutCategoryID(this.data.product_categories, products);

    while (!cartCopy.isEmpty() && itemsApplied < maxItems) {
      let [productId, productPrice] = cartCopy.getCheapestProductID();
      if (productId && productPrice) {
        discount += productPrice;
        cartCopy.decrementProductQtyByID(productId);
        itemsApplied += 1;
      }
    }

    return discount;
  }

  #metaItem = (key: string) => {
    if (!('meta_data' in this.data)) return null;
    return this.data.meta_data.find((meta_item: { key: string }) => {
      return meta_item.key === key;
    });
  }

  #validateAgainstCartMinimum = (cart: CartObj) => {
    let result = { error: false, message: '' };

    if (!this.data.minimum_amount) return result;

    let minAmt = parseFloat(this.data.minimum_amount);

    if (isNaN(minAmt) || minAmt <= 0) return result;

    let difference = minAmt - (cart.getSubtotal(null, null, null) as number);

    if (difference <= 0) return result;

    result.error = true;
    result.message = "You need to add " +
      Helper.formattedCurrency(difference) +
      " more to your cart in order to use that coupon";
    return result;
  }

  #validateAgainstCartProducts = (
    cart: CartObj,
    products: Array<Record<string, any>>
  ) => {
    let result;

    result = this.#validateAgainstCartProductsByCat(cart, products);

    if (result.error) return result;

    return this.#validateAgainstCartProductsById(cart, products);
  }

  #validateAgainstCartProductsById = (
    cart: CartObj,
    products: Array<Record<string, any>>
  ) => {
    let result = { error: false, message: '' };
    const { meta_data } = this.data;
    const freeProductsMeta = meta_data?.find((meta: any) => meta.key === '_wjecf_free_product_ids');

    if (
      !('product_ids' in this.data) ||
      this.data.product_ids.length === 0
    ) {
      return result;
    }

    for (let productId of this.data.product_ids) {
      if (cart.hasProductWithID(String(productId))) return result;
    }

    if (freeProductsMeta) {
      return result;
    }

    result.error = true;
    result.message = "Sorry, that coupon is not applicable to your selected cart items.";
    return result;

  }

  // #validateAgainstCartProductsByCat = (
  //   cart: CartObj, 
  //   products: Array<Record<string, any>>
  // ) => {
  //   let result = { error: false, message: '' };

  //   if (
  //     !('product_categories' in this.data) ||
  //     this.data.product_categories.length === 0
  //   ) {
  //     return result;
  //   }

  //   for(let catetoryID of this.data.product_categories) {
  //     if (cart.hasProductWithCategoryID(catetoryID, products)) return result;
  //   }

  //   if(products.slug = 'gift-card'){
  //     result.error = false;
  //     // result.message = "Sorry, that coupon is not applicable to your selected cart items.";
  //   }else{
  //     result.error = true;
  //     result.message = "Sorry, that coupon is not applicable to your selected cart items.";
  //   }
  //   // products.slug = 'gift-card'

  //   // result.error = true;
  //   // result.message = "Sorry, that coupon is not applicable to your selected cart items.";
  //   return result;
  // }

  #validateAgainstCartProductsByCat = (
    cart: CartObj,
    products: Array<Record<string, any>>
  ) => {
    let result = { error: false, message: '' };

    if (
      !('product_categories' in this.data) ||
      this.data.product_categories.length === 0
    ) {
      return result;
    }

    for (let catetoryID of this.data.product_categories) {
      if (cart.hasProductWithCategoryID(catetoryID, products)) return result;
    }

    let hasGiftCard = false;
    for (let product of products) {
      if (product.slug === 'gift-card') {
        hasGiftCard = true;
        break;
      }
    }

    if (hasGiftCard) {
      result.error = false;
      // result.message = "Sorry, that coupon is not applicable to your selected cart items.";
    } else {
      result.error = true;
      result.message = "Sorry, that coupon is not applicable to your selected cart items.";
    }

    return result;
  }

  // -------------------------------------
  // S T A T I C   M E T H O D S
  // -------------------------------------

  static getTotal = (coupons: Array<Record<string, any>>, formatted = false) => {
    let total = 0;

    for (let cData of coupons) {
      const coupon = new CouponObj(cData);
      total += (coupon.getAmount() as number);
    }
    if (!formatted) return total;

    return Helper.formattedCurrency(total);
  }

  static createCoupon = (coupon: Record<string, any> | null) => {
    if (!coupon) return null;

    return new CouponObj(coupon);
  }
}
