import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../AutoshipPages/core/autoShipPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { format, addMonths } from 'date-fns';
import Window from "../../utils/Window";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { selectCartCoupon, selectCartItems } from '../../features/cart/cartSlice';
import CartObj from '../../features/cart/cartObj';
import { resetCustomer, selectCustomer, selectCustomerIsLoading } from '../../features/customer/customerSlice';
import { selectShippingMethods } from '../../features/shipping/shippingSlice';
import DeliveryObj from '../../features/delivery/deliveryObj';
import { selectDeliveryData } from '../../features/delivery/deliverySlice';
import { selectProducts } from '../../features/products/productsSlice';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoShipAPI from './core/autoshipAPI';
import OrderObj from '../../features/orders/orderObj';
import {
    selectAutoShipData,
    selectCalendarIndex,
    selectIsBulkOrder,
    selectNotifications,
    selectPauseAutoship,
    selectPreviousDate,
    selectSelectedDate,
    selectSelectedDates,
    selectSelectedZipCode,
    selectVideoTutorials,
    setCalendarIndex,
    setIsBulkOrder,
    setNotifications,
    setOrderPlanType,
    setPauseAutoship,
    setPreviousDate,
    setSelectedDate,
    setSelectedDates,
    setSelectedZipCode,
    setTurnOnAutoship
} from './core/autoShipSlice';
import DeliveryAPI from '../../API/deliveryAPI';
import { selectToken } from '../../features/user/userSlice';
import { faClose, faWarning } from '@fortawesome/free-solid-svg-icons';
import { selectCustomerDetails } from '../../features/customer/customerDetailSlice';
import pickmyself from '../../assets/images/person-icon.svg';
import AutoShipSettings from './components/AutoShipSettings';
import AutoshipSelectPage from './components/AutoshipSelectPage';
import Loader from '../../components/Loader';
import OrdersAPI from '../../API/ordersAPI';
import { selectGeneralOptions, selectIsMobileRoute, selectUtmShipping, selectUtmThreshold } from '../../features/mobile/mobileSlice';
import { resetOrderPlannerOrders, selectOrderPlannerOrders, selectOrderPlannerOrdersLoadComplete, selectOrderPlannerOrdersPagesLoaded, selectUpdatingOrderPlannerOrder, setUpdatingOrderPlannerOrder, updateOrderPlannerOrderDeliveryDate } from './core/orderPlannerOrdersSlice';
import AccordionFAQs from './components/AccordionFAQs';
import { selectOrders } from '../../features/orders/ordersSlice';

const AutoShipPage: React.FC = () => {
    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const allOrders: Array<Record<string, any>> = useSelector(selectOrders);
    const orders = useSelector(selectOrderPlannerOrders);
    const ordersLoaded = useSelector(selectOrderPlannerOrdersLoadComplete);
    const pages_loaded: number = useSelector(selectOrderPlannerOrdersPagesLoaded);
    const getPrevDate = useSelector(selectPreviousDate);
    const selectedDate = useSelector(selectSelectedDate);
    const selectedDates = useSelector(selectSelectedDates);
    const selectedZipCode = useSelector(selectSelectedZipCode);
    const calendarIndex = useSelector(selectCalendarIndex);
    const updatingOrder = useSelector(selectUpdatingOrderPlannerOrder);
    const customer = useSelector(selectCustomer);
    const custLoading = useSelector(selectCustomerIsLoading);
    const products = useSelector(selectProducts);
    const coupon = useSelector(selectCartCoupon);
    const shippingMethods = useSelector(selectShippingMethods);
    const notifications = useSelector(selectNotifications);
    const deliveryInfo = new DeliveryObj(useSelector(selectDeliveryData));
    const cartItems = useSelector(selectCartItems);
    const dietary = useSelector(selectAutoShipData);
    const isBulkOrder = useSelector(selectIsBulkOrder);
    const customerDetails = useSelector(selectCustomerDetails);
    const utmThreshold = useSelector(selectUtmThreshold);
    const utmShipping = useSelector(selectUtmShipping);
    const AppURL = useSelector(selectIsMobileRoute);
    const getGeneralOptions = useSelector(selectGeneralOptions);
    const isAutoshipPaused = useSelector(selectPauseAutoship);
    const videoTutorials = useSelector(selectVideoTutorials);
    const urlParams = AppURL ? `/?${AppURL}` : '';
    const [multiDates, setMultiDates] = useState<Date[]>([]);
    const [autoAddWeeks, setAutoAddWeeks] = useState(notifications?._auto_add_weeks === '1');
    const [isChangedAlready, setIsChangedAlready] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [addCalendarBox, setAddCalendarBox] = useState(false);
    const [editCalendarBox, setEditCalendarBox] = useState(false);
    const [turnOffAutoship, setTurnOffAutoship] = useState(false);
    const [autoshipSetting, setAutoshipSetting] = useState(false);
    const [autoshipSelectPage, setAutoshipSelectPage] = useState(false);
    const [pauseAutoshipModal, setPauseAutoshipModal] = useState(false);
    const [editAddressModal, setEditAddressModal] = useState(false);
    const [tutorialsModal, setTutorialsModal] = useState(false);
    const [currentOrderId, setCurrentOrderId] = useState('');
    const [shippingMethodbyZip, setShippingMethodByZip] = useState<ShippingMethodObj[]>([]);
    const cart = useMemo(() => {
        return new CartObj(cartItems);
    }, [cartItems]);
    const zip = selectedZipCode ? selectedZipCode :
        customer?.shipping?.postcode ? customer?.shipping?.postcode : customer?.billing?.postcode;
    const city = customer?.shipping?.city ? customer?.shipping?.city : customer?.billing?.city;
    const completedOrders = allOrders.filter((order: any) => {
        const isProcessing = order.status === 'completed'
        return isProcessing;
    });
    const processingOrders = allOrders.filter((order: any) => {
        const isProcessing = order.status === 'processing'
        return isProcessing;
    });
    const plannedOrders = orders.filter((order: any) => {
        return order.status === 'autoship' || order.status === 'autoship-onhold';
    });
    const autoshipFailed = orders.filter((order: any) => {
        return order.status === 'mmfailpayment';
    });
    let isShipping: ShippingMethodObj[] = [];
    const prevOrderDate = selectedDate && (selectedDate !== null || selectedDate !== undefined)
        ? new Date(selectedDate) : new Date();
    const prevLocation = location.state && location.state.pathName;
    const UpdateDateAvailable = location.state && location.state.changeDate === true;
    const customerUserData = customerDetails?.user_data;
    const customerImage = customerUserData?.imageUrl;
    const order = OrderObj.findRecentOrder(orders);
    const orderDate = order?.getDeliveryDateTime();
    const zipCode = order?.data.shipping.postcode ? order?.data.shipping.postcode :
        order?.data.billing.postcode ? order?.data.billing.postcode : zip;
    const isUtmThreshold = (utmThreshold && utmThreshold.ship_threshold_value) ? true : false;
    const isUtmShipping = (utmShipping && utmShipping.ship_price_value) ? true : false;
    const utmThresholdValue = utmThreshold && utmThreshold.ship_threshold_value;
    const utmShippingValue = utmShipping && utmShipping.ship_price_value;
    const isDeskTop = !(Window.isMobile() || Window.isTablet());
    const MightyPointsRate = getGeneralOptions?.order_planner_points_multiplier;

    useEffect(() => {
        if (location.state && location.state.isCancel === true) {
            window.scrollTo(0, 0);
            setAutoshipSetting(false);
        }
    }, [location.state])

    useEffect(() => {
        const fetchBillingInfo = async () => {
            const billing_info = await Object.keys(customer).length > 0 ? customer.billing : {};
            const billing = {
                first_name: !!billing_info.first_name && billing_info.first_name.trim(),
                last_name: !!billing_info.last_name && billing_info.last_name.trim(),
                address_1: !!billing_info.address_1 && billing_info.address_1.trim(),
                city: !!billing_info.city && billing_info.city.trim(),
                state: !!billing_info.state && billing_info.state.trim(),
                postcode: !!billing_info.postcode && billing_info.postcode.trim(),
                country: 'US',
                phone: !!billing_info.phone && billing_info.phone.trim(),
                email: !!billing_info.email && billing_info.email.trim()
            };
            const anyNullOrEmpty = await Object.values(billing).some(value => value === false);

            if (anyNullOrEmpty && !custLoading) {
                setEditAddressModal(true);
            } else {
                setEditAddressModal(false);
            }
        }

        fetchBillingInfo();
    }, [customer, custLoading])

    useEffect(() => {
        if (isBulkOrder && plannedOrders.length > 0) {
            dispatch(setIsBulkOrder(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBulkOrder, ordersLoaded, pages_loaded, plannedOrders.length])

    useEffect(() => {
        const getAddBox = localStorage.getItem('autoship_add_calendar_box');
        const getEditBox = localStorage.getItem('autoship_edit_calendar_box');
        if (getAddBox === null || getAddBox === 'true') {
            setAddCalendarBox(true);
        }
        if (getEditBox === null || getEditBox === 'true') {
            setEditCalendarBox(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const isAutoshipPage = location.pathname.startsWith('/autoship');
        const isAutoshipStart = location.search.startsWith('?start=true');
        if (isAutoshipPage && isAutoshipStart && customer.id && ordersLoaded) {
            handleTurnOnAutoship();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, customer.id, ordersLoaded])

    useEffect(() => {
        const addOrUpdatePseudoElements = () => {
            if (customerImage && !customerImage.includes('userdefault.png')) {
                const imageUrl = customerImage.includes('userdefault.png') ? pickmyself : customerImage;

                const maxMidth1200px = window.matchMedia('(max-width: 1200px)').matches;
                const maxMidth500px = window.matchMedia('(max-width: 500px)').matches;
                const iconSize = maxMidth500px ? '16px' : maxMidth1200px ? '20px' : '26px';

                const updatePseudoElement = (element: any, image: any) => {
                    let pseudoElement = element.querySelector('.pseudo-element');

                    if (!pseudoElement) {
                        pseudoElement = document.createElement('div');
                        pseudoElement.classList.add('pseudo-element');
                        element.appendChild(pseudoElement);
                    }

                    Object.assign(pseudoElement.style, {
                        position: 'absolute',
                        width: iconSize,
                        height: iconSize,
                        borderRadius: '50%',
                        backgroundImage: `url(${image})`,
                        bottom: '0',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                    });
                };

                const elements = document.querySelectorAll<HTMLElement>('.pickformyself-autoship');
                const availableElements = document.querySelectorAll<HTMLElement>('.available-add-deliverydays');
                availableElements.forEach((element) => {
                    const pseudoElement = element.querySelector('.pseudo-element');
                    if (pseudoElement) {
                        pseudoElement.parentElement?.removeChild(pseudoElement);
                    }
                });
                elements.forEach((element) => updatePseudoElement(element, imageUrl));
            }
        };

        addOrUpdatePseudoElements();

        const observer = new MutationObserver(() => {
            addOrUpdatePseudoElements();
        });

        const targetNode = document.querySelector('.react-datepicker');
        if (targetNode) {
            observer.observe(targetNode, { childList: true, subtree: true });
        }

        const resizeHandler = () => addOrUpdatePseudoElements();
        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
            observer.disconnect();
        };
    }, [customerImage, updatingOrder, pages_loaded, turnOffAutoship, autoshipSetting, autoshipSelectPage, pauseAutoshipModal]);

    useEffect(() => {
        getShippingMethodDates(zip);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zip])

    useEffect(() => {
        const availableDatesFromAPI = deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]);
        if (shippingMethodbyZip.length > 0 && zip && !isLoading) {
            const findIndex = (calendarIndex && calendarIndex !== '') ? Number(calendarIndex) : selectedDate === null ?
                availableDatesFromAPI[0] : prevOrderDate?.getDay();
            const currIndex = availableDatesFromAPI.includes(findIndex) ? findIndex : availableDatesFromAPI[0];
            if (!calendarIndex || calendarIndex === '') {
                dispatch(setCalendarIndex(currIndex));
            }
            handleAutoAddWeeks(currIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingMethodbyZip, isLoading])

    const isDeliveryDay = (date: Date) => {
        const today = new Date();
        const blockedDates = deliveryInfo.getBlockedDates(city, zip);
        const updateDate = (location.state && location.state.date) ? new Date(location.state.date) : null;

        const isValidDate = (d: any) => d instanceof Date && !isNaN(d.getTime());
        const toDateObject = (d: any) => isValidDate(d) ? d : new Date(d);

        const hasDeadlinePassed = (day: number, deadlineHour: number) => {
            if (today.getDay() === day) {
                const deadline = new Date(today);
                deadline.setHours(deadlineHour, 0, 0, 0);
                return today > deadline;
            }
            return false;
        };

        if (hasDeadlinePassed(1, 22) || hasDeadlinePassed(4, 22)) {
            if (date.getDay() === 1 || date.getDay() === 4) {
                return false;
            }
        }

        if (updateDate && updateDate.toDateString() === date.toDateString()) {
            return false;
        }

        if (updateDate !== null && (
            (multiDates && multiDates.some(d => toDateObject(d).toDateString() === date.toDateString())) ||
            (selectedDates && selectedDates.some((d: any) => toDateObject(d).toDateString() === date.toDateString()))
        )) {
            return false;
        }

        if (shippingMethodbyZip[0]?.data.ups_delivery_method === true) {
            const isDisabledTuesday = blockedDates.filter((blockedDate: any) => {
                const blockedDay = toDateObject(blockedDate).getDay();
                return blockedDay === 2;
            });

            if (isDisabledTuesday.length > 0) {
                let isNextWednesdayEnabled = false;

                isDisabledTuesday.forEach((tuesday: any) => {
                    const disabledTuesday = toDateObject(tuesday);
                    const nextWednesday = new Date(tuesday);
                    nextWednesday.setDate(disabledTuesday.getDate() + 1);

                    if (date.toDateString() === nextWednesday.toDateString()) {
                        isNextWednesdayEnabled = true;
                    }
                });

                if (isNextWednesdayEnabled) {
                    return true;
                }
            }

            if (today.getDay() === 2 || today.getDay() === 3) {
                const nextThursday = new Date(today);
                const nextFriday = new Date(today);
                nextThursday.setDate(today.getDate() + ((4 + 7 - today.getDay()) % 7));
                nextFriday.setDate(today.getDate() + ((5 + 7 - today.getDay()) % 7));
                if (date.toDateString() === nextThursday.toDateString() || date.toDateString() === nextFriday.toDateString()) {
                    return false;
                }
            } else if (today.getDay() > 4 || today.getDay() === 1 || today.getDay() === 0) {
                const nextWednesday = new Date(today);
                const nextTuesday = new Date(today);
                nextTuesday.setDate(today.getDate() + ((2 + 7 - today.getDay()) % 7));
                nextWednesday.setDate(today.getDate() + ((3 + 7 - today.getDay()) % 7));
                if (date.toDateString() === nextTuesday.toDateString()) {
                    return false;
                }
                if (date.toDateString() === nextWednesday.toDateString()) {
                    return false;
                }
            }

            return deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]).includes(date.getDay());
        } else {
            return deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]).includes(date.getDay());
        }
    };

    const getWeek = (date: Date): string => {
        const onejan = new Date(date.getFullYear(), 0, 1);
        const dayOffset = (date.getDay() + 6) % 7;
        const weekNumber = Math.ceil(((date.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() - dayOffset + 1) / 7);
        return `${date.getFullYear()}-W${weekNumber}`;
    };

    const filterOrdersByDate = (orders: any[], date: Date | null) => {
        if (!date) return [];
        const targetTime = new Date(date).getTime();
        return orders.filter((order: { id: any }) => {
            const orderObj = OrderObj.findOrder(orders, Number(order.id));
            const getDate = orderObj?.getDeliveryDateTime();
            return getDate && getDate.getTime() === targetTime;
        });
    };

    const handleDateChange = async (date: [Date | null, Date | null], event: React.SyntheticEvent<any, Event> | undefined) => {
        const selecteDate = date[0];
        if (selecteDate) {
            dispatch(setSelectedDate(selecteDate));
        }

        const updateDate = (location.state && location.state.date) ? location.state.date : null;
        if (updateDate && selecteDate && updateDate.toDateString() !== selecteDate.toDateString()) {
            let updateDates: Date[] = [];

            if (!isChangedAlready) {
                setIsChangedAlready(true);
                updateDates = multiDates.filter((d: Date) => d.toDateString() !== updateDate.toDateString());
                updateDates.push(selecteDate);
            } else {
                updateDates = [...multiDates.slice(0, -1), selecteDate];
            }

            setMultiDates(updateDates);
        } else {
            const getCurrentOrder = filterOrdersByDate(plannedOrders, selecteDate);
            const completed = filterOrdersByDate(completedOrders, selecteDate);
            const processing = filterOrdersByDate(processingOrders, selecteDate);
            const failedOrders = filterOrdersByDate(autoshipFailed, selecteDate);

            if ((completed.length > 0 || processing.length > 0) && getCurrentOrder.length === 0) {
                setCurrentOrderId('');
                setAutoshipSelectPage(false);
            } else if (getCurrentOrder.length > 0 && failedOrders.length === 0) {
                setCurrentOrderId(getCurrentOrder[0].id);
                setAutoshipSelectPage(true);
            } else if (failedOrders.length === 0) {
                setCurrentOrderId('');
                setAutoshipSelectPage(true);
            }
        }

        if (plannedOrders.length > 0) {
            dispatch(setTurnOnAutoship(false));
        }
    };

    const getDayClassNames = (date: Date) => {
        const getBlockedDates = deliveryInfo?.getBlockedDates('', zip);
        const isBlockedDates = getBlockedDates?.some((blockedDate: Date) => blockedDate.toDateString() === date.toDateString());
        const availableDates = deliveryInfo?.getStartDate(zip) < date && addMonths(new Date(), 2) > date;
        const updateDate = (location.state && location.state.date) ? location.state.date : null;
        const updateDateIncluded = updateDate && selectedDate && new Date(selectedDate).toDateString() === date.toDateString();
        const ordersDateString = format(date, 'EEE, MMM d');
        const planned = plannedOrders.filter((order: any) => {
            const orderObj = new OrderObj(order);
            return orderObj.getDeliveryDate() === ordersDateString;
        });

        const onHoldOrder = planned.some((order: any) => {
            const onhold = order.status === "autoship-onhold";
            return !!onhold;
        });

        const pickForMyself = planned.some((order: any) => {
            const metaData = order.meta_data.find((meta: any) =>
                meta.key === '_autoship_order_type' && meta.value === 'pick_for_myself'
            );
            return !!metaData;
        });

        const pickForMe = planned.some((order: any) => {
            const metaData = order.meta_data.find((meta: any) =>
                meta.key === '_autoship_order_type' && meta.value === 'pick_for_me'
            );
            return !!metaData;
        });

        
        if (autoshipFailed.length > 0) {
            const failedPayments = autoshipFailed.filter((order: any) => {
                const orderObj = new OrderObj(order);
                return orderObj.getDeliveryDate() === ordersDateString;
            });
            if (failedPayments.length > 0) {
                return 'available-deliverydays';
            }
        }

        if (updateDate && updateDate.toDateString() === date.toDateString()) {
            return 'editable-deliverydays';
        }

        if (updateDateIncluded) {
            return `${isAutoshipPaused ? 'paused-bg-f98f50' : 'resume-bg-00b156'} auto-deliverydays`;
        }

        if (planned.length > 0) {
            if (onHoldOrder) {
                const bgOrder = pickForMyself ? 'pickformyself-autoship' :
                    pickForMe ? 'pickforme-autoship' : 'order-planner-orders';
                return `paused-bg-f98f50 ${bgOrder}`;
            }
            if (pickForMyself) {
                return `${isAutoshipPaused ? 'paused-bg-f98f50' : 'resume-bg-00b156'} pickformyself-autoship`;
            } else if (pickForMe) {
                return `${isAutoshipPaused ? 'paused-bg-f98f50' : 'resume-bg-00b156'} pickforme-autoship`;
            }
            return `${isAutoshipPaused ? 'paused-bg-f98f50' : 'resume-bg-00b156'} order-planner-orders`;
        }

        if (completedOrders.length > 0) {
            const completed = completedOrders.filter((order: any) => {
                const orderObj = new OrderObj(order);
                return orderObj.getDeliveryDate() === ordersDateString;
            });
            if (completed.length > 0) {
                return 'resume-bg-00b156 completed-autoship';
            }
        }

        if (processingOrders.length > 0) {
            const processing = processingOrders.filter((order: any) => {
                const orderObj = new OrderObj(order);
                return orderObj.getDeliveryDate() === ordersDateString;
            });
            if (processing.length > 0) {
                return 'resume-bg-00b156 processing-autoship';
            }
        }
        
        if (isDeliveryDay(date) && !isBlockedDates && availableDates) {
            const curreWeek = getWeek(new Date());
            const selectedWeek = getWeek(new Date(date));
            if (isAutoshipPaused) {
                if (!updateDate && selectedWeek !== curreWeek) {
                    return 'paused-deliverydays';
                } else {
                    return 'pause-available-deliverydays';
                }
            } else {
                if (!updateDate && selectedWeek !== curreWeek) {
                    return 'available-add-deliverydays';
                } else {
                    return 'available-deliverydays';
                }
            }
        }

        return '';
    };

    const getShippingMethodDates = (zipCode: any) => {
        let matchedMethods = [];

        let hasFreeDelivery = false;

        for (const method of shippingMethods) {
            const sm = new ShippingMethodObj(method);
            if (sm.isMatch(zipCode, cart, products, coupon, customer, isUtmThreshold, utmThresholdValue)) {
                if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
                    hasFreeDelivery = false;
                    matchedMethods.push(sm);
                } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
                    if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
                        hasFreeDelivery = true;
                        matchedMethods.push(sm);
                    } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {
                        matchedMethods.push(sm);
                        if (sm.data.ups_delivery_method === true && isShipping.length === 0) {
                            isShipping.push(sm);
                        }
                    }
                }
            }
        }
        const UPSshipping = matchedMethods.filter(sm => sm.data.ups_delivery_method === true);

        if (hasFreeDelivery) {
            matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
        }

        if (cart.isEmpty()) {
            matchedMethods = matchedMethods.filter(sm => (sm.data.title !== "Free Email Delivery"));
        }

        if (isUtmThreshold || isUtmShipping) {
            if (matchedMethods?.length > 0) {
                const originalMethod = matchedMethods[0];

                const newData = JSON.parse(JSON.stringify({
                    ID: originalMethod.data.ID,
                    title: originalMethod.data.title,
                    cost: originalMethod.data.cost,
                    ups_delivery_method: originalMethod.data.ups_delivery_method,
                    condition_groups: originalMethod.data.condition_groups
                }));

                if (utmThresholdValue !== "0" && isUtmThreshold) {
                    const subtotalGroup = newData.condition_groups[0]?.find((group: { condition: string; }) => group.condition === "subtotal");
                    const otherGroups = newData.condition_groups[0]?.filter((group: { condition: string; }) => group.condition !== "subtotal") || [];

                    if (subtotalGroup) {
                        newData.condition_groups[0] = [
                            ...otherGroups,
                            { ...subtotalGroup, value: utmThresholdValue }
                        ];
                    }
                }

                if (utmShippingValue && newData.cost !== "0" && newData.ups_delivery_method === true) {
                    newData.cost = utmShippingValue;
                }

                const updatedMethod = new ShippingMethodObj({
                    ...originalMethod,
                    data: newData
                });

                updatedMethod.data = newData;

                matchedMethods = [updatedMethod];
            }
        }

        if (UPSshipping.length > 0) {
            setShippingMethodByZip(UPSshipping);
        } else {
            setShippingMethodByZip(matchedMethods);
        }
    }

    const handleAutoAddWeeks = (index: number) => {
        const today = new Date();

        if (prevOrderDate === null || prevOrderDate === undefined) {
            setMultiDates([]);
        }

        const setDates: Date[] = [];
        const maxDeliveryDate = addMonths(new Date(), 2);
        let currentDate = new Date(today);

        currentDate.setDate(today.getDate() + (index - today.getDay() + 7) % 7);
        const getBlockedDates = deliveryInfo.getBlockedDates('', zip);

        while (currentDate <= maxDeliveryDate && setDates.length < 8) {
            if (index === currentDate.getDay() && isDeliveryDay(currentDate) &&
                currentDate.toDateString() !== today.toDateString()) {
                if (!getBlockedDates.some((blockedDate: Date) =>
                    blockedDate.toDateString() === currentDate.toDateString())) {
                    if ((getPrevDate && getPrevDate instanceof Date &&
                        getPrevDate.toDateString() !== currentDate.toDateString()) &&
                        getPrevDate < currentDate) {
                        setDates.push(new Date(currentDate));
                    } else {
                        setDates.push(new Date(currentDate));
                    }
                }
            }
            currentDate.setDate(currentDate.getDate() + 7);
        }

        const weekOfAutoShipOrders = new Set<string>();
        const setIncludedDates: Date[] = [];

        plannedOrders.forEach((order: any) => {
            const orderObj = order.id ? OrderObj.findOrder(orders, Number(order.id)) : null;
            const metaData = order.meta_data.find((meta: any) => meta.key === '_delivery_date');
            if (metaData && metaData.value) {
                const orderDate = orderObj?.getDeliveryDateTime();
                const orderDeliveryDate = orderDate ? orderDate : new Date(metaData.value);
                const selectedWeek = getWeek(orderDeliveryDate);

                if (!getBlockedDates.some((blockedDate: Date) => blockedDate.toDateString() === orderDeliveryDate.toDateString())) {
                    setIncludedDates.push(orderDeliveryDate);
                    weekOfAutoShipOrders.add(selectedWeek);
                }
            }
        });

        const filteredDates = setDates.filter((date: Date) => {
            const week = getWeek(date);
            return !weekOfAutoShipOrders.has(week);
        });

        if (getPrevDate && getPrevDate instanceof Date) {
            const filterIncludedDates = filteredDates.filter((currDate: Date) => getPrevDate.toDateString() !== currDate.toDateString())
            setIncludedDates.push(...filterIncludedDates);
        } else {
            setIncludedDates.push(...filteredDates);
        }

        if ((selectedDate === null || prevOrderDate === null) &&
            (selectedDate !== setDates[0] ||
                prevOrderDate !== setDates[0])) {
            dispatch(setSelectedDate(setDates[0]));
        }
        const updateDate = (location.state && location.state.date) ? new Date(location.state.date) : null;

        if (!updateDate) {
            setMultiDates(setIncludedDates);
            dispatch(setSelectedDates(setIncludedDates));
        } else if (selectedDates) {
            setMultiDates(selectedDates);
        }

        const canNavigate = (!dietary?._autoship_meals_per_order ||
            plannedOrders.length === 0) && ordersLoaded && pages_loaded &&
            !isBulkOrder && !isLoading && !isBusy && !custLoading && !updatingOrder;

        if (canNavigate) {
            navigate(`/autoship/settings${urlParams}`);
        }
    };

    const handleApplyChanges = async () => {
        setIsBusy(true);
        const updateDate = location.state?.date || null;
        if (!selectedDate || !updateDate) {
            dispatch(setSelectedDate(new Date(selectedDate)));
            dispatch(setSelectedDates(multiDates));
            navigate(`/autoship${urlParams}`, { state: { isChanged: true } });
            setIsBusy(false);
            return;
        }

        const ordersDateString = format(updateDate, 'EEE, MMM d');
        const planned = plannedOrders.filter((order: any) => {
            const orderObj = new OrderObj(order);
            return orderObj.getDeliveryDate() === ordersDateString;
        });

        if (planned.length === 0) {
            dispatch(setSelectedDate(new Date(selectedDate)));
            dispatch(setSelectedDates(multiDates));
            navigate(`/autoship${urlParams}`, { state: { isChanged: true } });
            setIsBusy(false);
            return;
        }

        dispatch(setUpdatingOrderPlannerOrder(true));
        const order = planned[0];
        const deliveryDate = new Date(selectedDate).getFullYear().toString() + '-' +
            ('0' + (new Date(selectedDate).getMonth() + 1)).slice(-2) + '-' +
            ('0' + new Date(selectedDate).getDate()).slice(-2);

        try {
            const response = await DeliveryAPI.updateOrderDeliveryDate(token, order.id, customer.id, deliveryDate);
            OrdersAPI.createOrderNote(token, order.id, "Customer changed delivery date.");
            await dispatch(updateOrderPlannerOrderDeliveryDate({ op_order_id: order.id, op_delivery_date: response }));
        } catch (error) {
            console.log(error);
        } finally {
            const updateDate = (location.state && location.state.date) ? location.state.date : null;
            if (updateDate && new Date(selectedDate) && updateDate.toDateString() !== new Date(selectedDate).toDateString()) {
                const updateDates = multiDates.filter((d: Date) => d.toDateString() !== updateDate.toDateString());
                dispatch(setSelectedDate(new Date(selectedDate)));
                dispatch(setSelectedDates([...updateDates, selectedDate]));
            }
            navigate(prevLocation ? prevLocation : `/autoship${urlParams}`, { state: { isChanged: true } });
            dispatch(setUpdatingOrderPlannerOrder(false));
            setIsBusy(false);
        }
    };

    const handleCancleChanges = () => {
        setMultiDates(selectedDates ? selectedDates : []);
        dispatch(setSelectedDate(location?.state?.date));
        dispatch(setSelectedDates(selectedDates));
        navigate(prevLocation ? prevLocation : `/autoship${urlParams}`, { state: { isChanged: location?.state?.isChanged } });
    }

    const handleAutoAddWeeksChange = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked ? '1' : '';
        setIsBusy(true);
        setAutoAddWeeks(e.target.checked);
        AutoShipAPI.postUserNotifications({
            userId: customer.id,
            autoshipAutoAddWeeks: checked,
            autoshipEmailNotifications: notifications._autoship_email_notifications,
            autoshipTextMsgNotifications: notifications._autoship_text_msg_notifications,
        }).then((result) => {
            dispatch(setNotifications(result.data));
            const availableDatesFromAPI = deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]);
            const findIndex = selectedDate === null ?
                availableDatesFromAPI[0] : new Date(selectedDate)?.getDay();
            const currIndex = availableDatesFromAPI.includes(findIndex) ? findIndex : availableDatesFromAPI[0];
            handleAutoAddWeeks(currIndex);
        }).finally(() => {
            setIsBusy(false);
        });
    }

    const handleCrossIcon = () => {
        if (UpdateDateAvailable) {
            localStorage.setItem('autoship_edit_calendar_box', 'false');
            setEditCalendarBox(false);
        } else {
            localStorage.setItem('autoship_add_calendar_box', 'false');
            setAddCalendarBox(false);
        }
    }

    const handleBoxIcon = () => {
        if (UpdateDateAvailable) {
            localStorage.setItem('autoship_edit_calendar_box', 'true');
            setEditCalendarBox(true);
        } else {
            localStorage.setItem('autoship_add_calendar_box', 'true');
            setAddCalendarBox(true);
        }
    }

    const handleTurnOffAutoship = async () => {
        setIsBusy(true);
        dispatch(setUpdatingOrderPlannerOrder(true));
        try {
            const response = await AutoShipAPI.deleteAllAutoshipOrders(customer.id);
            if (response.status === 'success') {
                await dispatch(resetOrderPlannerOrders());
                setAutoAddWeeks(false);
                setTurnOffAutoship(false);
                dispatch(setTurnOnAutoship(false));
                navigate(`/autoship/orders${urlParams}`);
            }
        } catch (e: any) {
            console.log(e, "Error while turning off autoship");
        } finally {
            setIsBusy(false);
            dispatch(setUpdatingOrderPlannerOrder(false));
        }
    }

    const handleTurnOnAutoship = async () => {
        setIsLoading(true);
        try {
            if (plannedOrders.length <= 0) {
                await AutoShipAPI.postUserNotifications({
                    userId: customer.id,
                    autoshipAutoAddWeeks: '1',
                    autoshipEmailNotifications: '1',
                    autoshipTextMsgNotifications: '1',
                }).then((response) => {
                    if (response.status === "success") {
                        dispatch(setNotifications(response.data));
                    }
                });
            }
        } catch (e: any) {
            console.log(e, "Error while Turning on Autoship");
        } finally {
            if (plannedOrders.length <= 0) {
                dispatch(setTurnOnAutoship(true));
                dispatch(setOrderPlanType("pick_for_myself"));
                dispatch(setPreviousDate(orderDate));
                dispatch(setSelectedDate(orderDate));
                dispatch(setSelectedZipCode(zipCode));
            }
            navigate(`/autoship${urlParams}`);
            setIsLoading(false);
        }
    }

    const handlePauseAutoship = async () => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        setIsBusy(true);
        dispatch(setUpdatingOrderPlannerOrder(true));
        setPauseAutoshipModal(false);
        try {
            const status = isAutoshipPaused ? '0' : '1';
            const response = await AutoShipAPI.pauseAutoshipOrders(customer.id, status);
            if (response.status === 'success') {
                await dispatch(resetOrderPlannerOrders());
                dispatch(setPauseAutoship(!isAutoshipPaused));
            }
        } catch (e: any) {
            console.log(e, "Error while turning off autoship");
        } finally {
            await dispatch(resetCustomer());
            await dispatch(setUpdatingOrderPlannerOrder(false));
            setIsBusy(false);
            setIsLoading(false);
        }
    }

    return (
        <>
            {pages_loaded && !isLoading && MightyPointsRate && Number(MightyPointsRate) > 1 &&
                <div className='pointsmultihead mt-3'>
                    <Row className="align-items-stretch maincolumn me-0">
                        <Col xs={11} sm={11} md={11} className='pointsmultibanner'>
                            <Row className={`${Window.isMobile() ? 'p-2' : 'p-3'} align-items-center`}>
                                <Col xs={6} sm={6} className='text-end'>
                                    <span className='pointsactivatetxt font-barlow fw-bold'>
                                        {Number(MightyPointsRate)}X POINTS ACTIVATED
                                    </span>
                                </Col>
                                <Col xs={6} sm={6} className={`${isDeskTop ? 'text-start' : ''}`}>
                                    <span className='pointsforordertxt'>Earn&nbsp;
                                        <span className='font-barlow fw-bold'>
                                            {Number(MightyPointsRate)}X POINTS </span>
                                        for every order placed with the Order Planner.</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1} sm={1} md={1} className='ps-0'>
                            <div className='pointsmultiImage'></div>
                        </Col>
                    </Row>
                </div>}

            <Container className='autoshipContainer'>
                {(!pages_loaded || isLoading) &&
                    <div className='pageloader'>
                        <Loader />
                    </div>
                }
                <div className={`${(!pages_loaded || isLoading) ? 'blur-bg' : ''}`}>
                    <Modal show={editAddressModal}>
                        <Modal.Body>
                            <p>You haven't provided your billing or shipping details yet.
                                We need those details to start your Order Planner.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-dark"
                                disabled={updatingOrder}
                                onClick={() => navigate(`/my-account/address${urlParams}`, { state: { autoShip: true } })}>
                                {updatingOrder ?
                                    <>
                                        Please wait ...&nbsp;&nbsp;
                                        <Spinner animation="border" as="span" size="sm" />
                                    </> : 'Edit Address'}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={autoshipSetting} onHide={() => setAutoshipSetting(false)}>
                        <Modal.Header className='border-bottom-0' closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <AutoShipSettings />
                        </Modal.Body>
                    </Modal>
                    <Modal className='modalwidth mt-5' show={autoshipSelectPage} onHide={() => setAutoshipSelectPage(false)}>
                        <Modal.Header className='border-bottom-0' closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <AutoshipSelectPage
                                orderId={currentOrderId}
                                autoshipSelectPage={autoshipSelectPage}
                                setAutoshipSelectPage={setAutoshipSelectPage}
                                setMultiDates={setMultiDates} />
                        </Modal.Body>
                    </Modal>
                    <Modal show={turnOffAutoship} onHide={() => setTurnOffAutoship(false)}>
                        <Modal.Header className='border-bottom-0' closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Turning off AutoShip will cancel all future orders.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-dark" disabled={updatingOrder} onClick={() => setTurnOffAutoship(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="danger"
                                className='text-white'
                                disabled={updatingOrder || isBusy}
                                onClick={handleTurnOffAutoship}>
                                {(updatingOrder || isBusy) ?
                                    <>
                                        Please wait ...&nbsp;&nbsp;
                                        <Spinner animation="border" as="span" size="sm" />
                                    </> : 'Turn off order planner'}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={pauseAutoshipModal} onHide={() => setPauseAutoshipModal(false)}>
                        <Modal.Header className='border-bottom-0' closeButton>
                        </Modal.Header>
                        <Modal.Body className='text-center'>
                            <FontAwesomeIcon
                                color='#f98f50'
                                className='cursor-pointer'
                                size='4x'
                                icon={faWarning}
                            ></FontAwesomeIcon>
                            <p className='fs-22px font-barlow fw-bold'>
                                {isAutoshipPaused ? 'Order Planner Paused' : 'Pause Order Planner'}
                            </p>
                            {isAutoshipPaused ?
                                <p>You have paused the Order Planner. <br />You can resume at any time.</p>
                                : <p>Are you sure want to pause order planner?</p>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-dark" disabled={updatingOrder} onClick={() => setPauseAutoshipModal(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant={isAutoshipPaused ? 'success' : undefined}
                                style={!isAutoshipPaused ? { backgroundColor: '#f98f50', borderColor: '#f98f50' } : {}}
                                className="text-white"
                                disabled={isBusy}
                                onClick={handlePauseAutoship}
                            >
                                {(updatingOrder || isBusy) ?
                                    <>
                                        Please wait ...&nbsp;&nbsp;
                                        <Spinner animation="border" as="span" size="sm" />
                                    </> : isAutoshipPaused ? 'Resume Order Planner' : 'Pause Order Planner'}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {videoTutorials && videoTutorials.length > 0 &&
                        <Modal show={tutorialsModal} onHide={() => setTutorialsModal(false)}>
                            <Modal.Header className='border-bottom-0' closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='d-flex justify-content-center align-items-center pb-2'>
                                    <span className='fs-26px text-capitalize fw-400 font-league-gothic fs-45px'>
                                        {videoTutorials[0].tutorial_title}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-center pb-2">
                                    <video width="100%" className='border border-black border-1 rounded' controls>
                                        <source src={videoTutorials[0].tutorial_video_url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div className='d-flex justify-content-center align-items-center pb-2'>
                                    <AccordionFAQs accordionItems={videoTutorials} />
                                </div>
                            </Modal.Body>
                        </Modal>}
                    {UpdateDateAvailable ?
                        <div className='d-flex justify-content-center align-items-center mt-3 autoshipPage pb-2'>
                            <span className='autoshipPageHeader'>UPDATE DELIVERY DATES</span>
                        </div> :
                        <div className='d-flex justify-content-center align-items-center mt-3 autoshipPage pb-2'>
                            <span className='autoshipPageHeader'>Order Planner Calendar</span>
                        </div>}
                    {((addCalendarBox && !UpdateDateAvailable) || (editCalendarBox && UpdateDateAvailable)) &&
                        <div className='d-flex justify-content-center align-items-center box-alert'>
                            <Alert variant="secondary" className='text-center my-3 pb-2'>
                                <FontAwesomeIcon className='info-icon' icon={faCircleInfo} size="sm" color={'green'} />
                                <span className='d-flex justify-content-center align-items-center alert-text flex-wrap'>
                                    {addCalendarBox && !UpdateDateAvailable &&
                                        `Welcome to your personal order planner calendar. We have added 8 orders to your calendar based on your selected settings.
                                    You can edit each order by clicking on a solid green square or add additional orders by clicking (+) icon below each date.`}
                                    {editCalendarBox && UpdateDateAvailable &&
                                        `Want to change your delivery dates? Not a problem!
                                            Select one green square at a time and move it to your desired delivery date.
                                            Once you're finished, apply your changes!
                                        `}
                                    {videoTutorials && videoTutorials.length > 0 &&
                                        <Button
                                            size="sm"
                                            variant={'dark'}
                                            style={{
                                                backgroundColor: '#212529',
                                            }}
                                            className={`text-white mt-2 p-1 ${Window.isMobile() ? 'w-50' : 'w-25'}`}
                                            onClick={() => setTutorialsModal(true)}
                                        >
                                            Tutorials
                                        </Button>}
                                </span>
                                <div className='settingclose'>
                                    <FontAwesomeIcon
                                        color='black'
                                        className='cursor-pointer'
                                        onClick={handleCrossIcon}
                                        icon={faClose}
                                    ></FontAwesomeIcon>
                                </div>
                            </Alert>
                        </div>}
                    <div className='option-col'>
                        <Row className='d-flex justify-content-between align-items-center'>
                            <Col className='d-flex align-items-center flex-grow-1 justify-content-center'>
                                <span className='fs-12px d-flex align-items-center'>
                                    <div className="green-boxes box-part"></div>
                                    Current Orders
                                </span>
                            </Col>
                            {!UpdateDateAvailable && (
                                <Col className='d-flex align-items-center flex-grow-1 justify-content-center'>
                                    <span className='fs-12px d-flex align-items-center'>
                                        <div className="gray-boxes box-part plus-gray-box"></div>
                                        New Order
                                    </span>
                                </Col>
                            )}
                            {UpdateDateAvailable && (
                                <Col className='d-flex align-items-center flex-grow-1 justify-content-center'>
                                    <span className='fs-12px d-flex align-items-center'>
                                        <div className="yellow-boxes box-part"></div>
                                        Selected Order
                                    </span>
                                </Col>
                            )}
                            <Col className='d-flex align-items-center flex-grow-1 justify-content-center'>
                                <span className='fs-12px d-flex align-items-center'>
                                    <div className="green-boxes box-part completed-box"></div>
                                    Completed Orders
                                </span>
                            </Col>
                            <Col className='d-flex align-items-center flex-grow-1 justify-content-center'>
                                <span className='fs-12px d-flex align-items-center'>
                                    <div className="green-boxes box-part processing-box"></div>
                                    Processing Orders
                                </span>
                            </Col>
                            <Col className='d-flex align-items-center flex-grow-1 justify-content-center'>
                                <span className='fs-12px d-flex align-items-center'>
                                    <div className="orange-boxes box-part"></div>
                                    Paused Orders
                                </span>
                            </Col>
                            <Col className='d-flex align-items-center flex-grow-1 justify-content-center'>
                                <span className='fs-12px d-flex align-items-center'>
                                    <div className="gray-boxes box-part"></div>
                                    Dates&nbsp;&nbsp;&nbsp;&nbsp;
                                    {((UpdateDateAvailable && !editCalendarBox) || (!UpdateDateAvailable && !addCalendarBox)) && (
                                        <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            size="lg"
                                            className='cursor-pointer'
                                            onClick={handleBoxIcon}
                                        />
                                    )}
                                </span>
                            </Col>
                        </Row>
                    </div>

                    <div className='calender-box d-flex flex-column justify-content-center align-items-center pb-4'>
                        <div className='calenderwrapper'>
                            <DatePicker
                                autoComplete="date"
                                calendarStartDay={1}
                                filterDate={isDeliveryDay}
                                excludeDates={deliveryInfo.getBlockedDates('', zip)}
                                open
                                monthsShown={2}
                                minDate={deliveryInfo.getStartDate(zip)}
                                maxDate={addMonths(new Date(), 2)}
                                onChange={handleDateChange}
                                onFocus={e => { e.target.blur(); }}
                                disabledKeyboardNavigation
                                dayClassName={getDayClassNames}
                                inline
                                selectsRange
                            />
                            <div className={`${Window.isMobile() ? 'calenderMobileFooter' : 'calenderDeskFooter'}`}>
                                <div className='d-flex justify-content-center align-items-center tooltip-cal'>
                                    <Form.Check
                                        type="switch"
                                        className='mx-2 d-flex justify-content-center align-items-center'
                                        checked={autoAddWeeks}
                                        disabled={isBusy}
                                        onChange={(e: any) => handleAutoAddWeeksChange(e)}
                                    />
                                    <span className='fs-6'>{isBusy ?
                                        <>
                                            Auto Add Weeks&nbsp;&nbsp;
                                            <Spinner
                                                animation="border"
                                                as="span"
                                                size="sm"
                                            />
                                        </>
                                        : 'Auto Add Weeks'}
                                    </span>
                                    <OverlayTrigger
                                        trigger={['hover', 'focus']}
                                        placement="top"
                                        overlay={

                                            <Tooltip id="tooltip-auto-add-weeks tooltip-cal" >
                                                Never run out of meals! Turn on "Auto Add Weeks" to keep your calendar filled with orders up to 8 weeks in advance & to ensure you always have fresh, delicious meals on hand.
                                            </Tooltip>
                                        }
                                    >
                                        <div className='text-black rounded-circle cursor-pointer ps-2'>
                                            <FontAwesomeIcon icon={faCircleInfo} size="sm" />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (location.state && location.state.changeDate === true) ?
                            <div className='d-flex justify-content-center align-items-center pb-4'>
                                <Button
                                    size="sm"
                                    variant="success"
                                    className='mx-3 text-white'
                                    disabled={isBusy || updatingOrder || !pages_loaded || isLoading}
                                    onClick={handleApplyChanges}
                                >
                                    {updatingOrder ?
                                        <>
                                            APPLYING CHANGES ...&nbsp;
                                            <Spinner
                                                animation="border"
                                                as="span"
                                                size="sm"
                                            />
                                        </>
                                        : 'APPLY CHANGES'}
                                </Button>

                                <Button
                                    size="sm"
                                    variant="danger"
                                    className='mx-3 text-white'
                                    disabled={isBusy || updatingOrder || !pages_loaded || isLoading}
                                    onClick={handleCancleChanges}
                                >
                                    CANCEL
                                </Button>
                            </div> :
                            <div className='d-flex btn-both-wrap justify-content-center align-items-center pb-4'>
                                <Button
                                    size="sm"
                                    variant="dark"
                                    disabled={isBusy || updatingOrder || !pages_loaded || isLoading}
                                    style={{
                                        backgroundColor: '#212529',
                                    }}
                                    onClick={() => {
                                        setAutoshipSetting(true);
                                        if (plannedOrders.length > 0) {
                                            dispatch(setTurnOnAutoship(false));
                                        }
                                    }}
                                >
                                    ORDER PLANNER SETTINGS
                                </Button>
                                <Button
                                    size="sm"
                                    variant="danger"
                                    className='text-white'
                                    disabled={isBusy || updatingOrder || !pages_loaded || isLoading}
                                    style={{
                                        backgroundColor: '#ee3124',
                                    }}
                                    onClick={() => setTurnOffAutoship(true)}
                                >
                                    TURN OFF ORDER PLANNER
                                </Button>
                                <Button
                                    size="sm"
                                    variant={isAutoshipPaused ? 'success' : undefined}
                                    style={!isAutoshipPaused ? { backgroundColor: '#f98f50', borderColor: '#f98f50' } : {}}
                                    className="text-white"
                                    disabled={isBusy || updatingOrder || !pages_loaded || isLoading}
                                    onClick={() => setPauseAutoshipModal(true)}
                                >
                                    {isAutoshipPaused ? 'RESUME ORDER PLANNER' : 'PAUSE ORDER PLANNER'}
                                </Button>
                            </div>
                    }
                </div>
            </Container>
        </>
    )
}

export default AutoShipPage;