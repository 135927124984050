import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import MyAccountPage from '../../MyAccountPage';
import OrderObj from '../../../features/orders/orderObj';
import { selectToken } from '../../../features/user/userSlice';
import MyAccountNav from '../../../components/MyAccountNav';
import OrderDetails from '../../../features/orders/OrderDetails';
import BillingAndShipping from '../../../features/orders/BillingAndShipping';
import OrdersAPI from '../../../API/ordersAPI';
import { useState } from 'react';
import { selectIsMobileRoute } from '../../../features/mobile/mobileSlice';
import { cancelOrderPlannerOrder, selectOrderPlannerOrders, selectUpdatingOrderPlannerOrder, setUpdatingOrderPlannerOrder } from '../core/orderPlannerOrdersSlice';

export default function AutoshipOrderPage() {
    let params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(selectToken);
    const orders = useSelector(selectOrderPlannerOrders);
    const AppURL = useSelector(selectIsMobileRoute);
    const urlParams = AppURL ? `/?${AppURL}` : '';
    const [isCancel, setIsCancel] = useState(false);
    const updatingOrder = useSelector(selectUpdatingOrderPlannerOrder);
    const order = params.orderId ? OrderObj.findOrder(orders, parseInt(params.orderId)) : null;
    const orderDate = order?.getDeliveryDateTime();

    if (!token) {
        return (
            <MyAccountPage />
        )
    }

    const handleCancelOrder = async () => {
        dispatch(setUpdatingOrderPlannerOrder(true));
        try {
            const response = await OrdersAPI.updateOrder(token, Number(params.orderId), { status: 'mmcancelled' });
            OrdersAPI.createOrderNote(token, Number(params.orderId), "Customer cancelled order.");

            if ('id' in response) {
                dispatch(cancelOrderPlannerOrder(response.id));
                setIsCancel(false);
            }

        } catch (error: any) {
            console.log(error.message);
        } finally {
            dispatch(setUpdatingOrderPlannerOrder(false));
            setIsCancel(false);
            navigate(`/autoship/orders${urlParams}`);
        }
    }

    return (
        <Container fluid className='my-order-page'>
            {isCancel &&
                <Modal show={isCancel} onHide={() => setIsCancel(false)}>
                    <Modal.Header className='border-bottom-0' closeButton>
                        <Modal.Title>Cancel This Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='mb-0 fs-18px'>Canceling this order cannot be undone, please confirm by clicking "Yes"</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" disabled={updatingOrder} onClick={() => setIsCancel(false)}>
                            No
                        </Button>
                        <Button
                            variant="outline-dark"
                            disabled={updatingOrder}
                            onClick={handleCancelOrder}>
                            {updatingOrder ?
                                <>
                                    Canceling ...&nbsp;&nbsp;
                                    <Spinner animation="border" as="span" size="sm" />
                                </> : 'Yes'}
                        </Button>
                    </Modal.Footer>
                </Modal>}
            <Row>
                <Col sm={12} md={3}>
                    <MyAccountNav />
                </Col>
                <Col sm={12} md={9}>
                    {order &&
                        <Card className='my-order my-3' body>
                            <Row className='mt-0 my-3'>
                                <Col xs={12}>
                                    <Alert variant='secondary' className='fs-4'>
                                        Your Delivery Date
                                        is <span className='fw-bold'>{order.getDeliveryDate('long')}</span>
                                    </Alert>
                                </Col>
                            </Row>
                            <OrderDetails order={order} showActions={false} autoshipOrder={true} />
                            <div className='d-flex justify-content-center align-items-center'>
                                <Button
                                    size="sm"
                                    variant="dark"
                                    className='mt-0 my-2 w-100'
                                    onClick={() => navigate(`/my-account/payment-method${urlParams}`, { state: { autoShip: true } })}
                                >
                                    EDIT PAYMENT METHOD
                                </Button>
                            </div>
                            <BillingAndShipping order={order} />
                            <Row className='d-flex justify-content-center align-items-center'>
                                <Col className='d-flex justify-content-center align-items-center flex-wrap'>
                                    <Button
                                        variant="danger"
                                        className='mt-0 my-2 me-2 text-white'
                                        onClick={() => setIsCancel(true)}
                                    >
                                        CANCEL THIS ORDER
                                    </Button>
                                    <Button
                                        onClick={() => navigate(`/my-account/address${urlParams}`, {
                                            state: {
                                                isEditOrder: true,
                                                orderId: order.data.id
                                            }
                                        })}
                                        variant="dark"
                                        className='mt-0 my-2 me-2'
                                    >
                                        EDIT ADDRESS
                                    </Button>
                                    <Button
                                        variant="dark"
                                        className='mt-0 my-2 me-2'
                                        onClick={() => navigate(`/autoship${urlParams}`, {
                                            state: {
                                                changeDate: true,
                                                date: orderDate,
                                                pathName: '/autoship/orders'
                                            }
                                        })}
                                    >
                                        CHANGE DATE
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    );
}