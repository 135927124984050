import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table, Image } from 'react-bootstrap';
import { selectIsMobileRoute } from '../../../features/mobile/mobileSlice';
import Loader from '../../../components/Loader';
import OrderObj from '../../../features/orders/orderObj';
import { useState } from 'react';
import { selectCustomer } from '../../../features/customer/customerSlice';
import { setNotifications, setOrderPlanType, setPreviousDate, setSelectedDate, setSelectedZipCode, setTurnOnAutoship } from '../core/autoShipSlice';
import AutoShipAPI from '../core/autoshipAPI';
import { selectOrderPlannerOrders, selectOrderPlannerOrdersPagesLoaded } from '../core/orderPlannerOrdersSlice';

export default function AutoshipOrdersListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const custData = useSelector(selectCustomer);
  const pages_loaded: number = useSelector(selectOrderPlannerOrdersPagesLoaded);
  const orders: Array<Record<string, any>> = useSelector(selectOrderPlannerOrders);
  const autoshipOrders = orders.filter((order, index, self) => index === self.findIndex((o) => o.id === order.id));
  const [turningOn, setTurningON] = useState(false);
  const order = OrderObj.findRecentOrder(autoshipOrders);
  const zip = custData?.shipping?.postcode ? custData?.shipping?.postcode : custData?.billing?.postcode;
  const orderDate = order?.getDeliveryDateTime();
  const OrderPlannerImage = 'https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-mobile.jpg';
  const zipCode = order?.data.shipping.postcode ? order?.data.shipping.postcode :
    order?.data.billing.postcode ? order?.data.billing.postcode : zip;
  const plannedOrders = autoshipOrders.filter((order: any) => {
    const orderObj = new OrderObj(order);
    return orderObj.data.status === 'autoship';
  });
  const latestOrder = autoshipOrders && OrderObj.findRecentOrder(autoshipOrders);
  const lastOrderedDate = latestOrder && latestOrder.getDeliveryDateTime();

  const handleTurnOnAutoship = async () => {
    setTurningON(true);
    try {
      await AutoShipAPI.postUserNotifications({
        userId: custData.id,
        autoshipAutoAddWeeks: '1',
        autoshipEmailNotifications: '1',
        autoshipTextMsgNotifications: '1',
      }).then((response) => {
        if (response.status === "success") {
          dispatch(setNotifications(response.data));
        }
      });
    } catch (e: any) {
      console.log(e, "Error while Turning on Autoship");
    } finally {
      dispatch(setTurnOnAutoship(true));
      dispatch(setOrderPlanType("pick_for_myself"));
      dispatch(setPreviousDate(orderDate));
      dispatch(setSelectedDate(orderDate));
      dispatch(setSelectedZipCode(zipCode));
      navigate(`/autoship${urlParams}`);
      setTurningON(false);
    }
  }

  const handleAutoshipNoOrder = () => {
    if (plannedOrders.length > 0) {
      dispatch(setPreviousDate(lastOrderedDate));
      dispatch(setSelectedDate(lastOrderedDate));
      dispatch(setSelectedZipCode(zip));
      navigate(`/autoship${urlParams}`);
    } else {
      handleTurnOnAutoship();
    }
  }

  return (
    <Card body className='my-orders my-5 flex-fill'>
      {!pages_loaded ?
        <div className='text-center my-5 h-100'>
          <Loader />
        </div> :
        <>
          {autoshipOrders.length === 0 ? (
            <div className='autoShip'>
              {turningOn ?
                <div className='text-center my-5 h-100'>
                  <Loader />
                </div> :
                <Image
                  src={OrderPlannerImage}
                  className='cursor-pointer'
                  alt='orderPlanner'
                  onClick={handleTurnOnAutoship} />}
            </div>
          ) : (
            <>
              <div className='d-flex justify-content-center align-items-center'>
                <Button
                  onClick={handleAutoshipNoOrder}
                  size="sm"
                  variant="success"
                  className='mx-3 w-50 text-white mb-3'
                >
                  View Order Planner Calendar
                </Button>
              </div>
              <Table
                striped
                responsive
                className='table-borderless table-sm'
              >
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Delivery Date</th>
                    <th>Total</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {autoshipOrders.slice().sort((a, b) => {
                    const orderA = new OrderObj(a);
                    const orderB = new OrderObj(b);
                    const dateA = new Date(orderA.getDeliveryDate()).getTime();
                    const dateB = new Date(orderB.getDeliveryDate()).getTime();
                    if (isNaN(dateA)) return 1;
                    if (isNaN(dateB)) return -1;
                    return dateA - dateB;
                  }).map((orderData, index: number) => {
                    const order = new OrderObj(orderData);
                    const OrderStatus = order.data.status === 'autoship' ? 'Pending' :
                      order.data.status === 'mmfailpayment' ? 'Failed' :
                        order.data.status === 'mmcancelled' ? 'Cancelled' :
                          order.data.status === 'autoship-onhold' ? 'On hold' : order.data.status;

                    return (
                      <tr key={index} className='position-relative'>
                        <td>
                          <Link to={'/autoship/orders/' + order.data.id + urlParams} className='text-black'>
                            #{order.data.id}
                          </Link>
                        </td>
                        <td>{order.getDate()}</td>
                        <td>{OrderStatus}</td>
                        <td>{order.getDeliveryDate()}</td>
                        <td>
                          <span className='fw-bold'>${order.data.total}</span><br />
                          for {order.getTotalItems()} items
                        </td>
                        <td>
                          <div className='vstack gap-1'>
                            <Link to={'/autoship/orders/' + order.data.id + urlParams}>
                              <Button
                                size="sm"
                                variant="success"
                                className='text-white mt-0 my-2 w-100'
                              >
                                View
                              </Button>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>)}
        </>}
    </Card>
  );
}