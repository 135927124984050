import type { RootState } from '../../../app/store';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AutoShipAPI from '../../../pages/AutoshipPages/core/autoshipAPI';

interface OrdersState {
    op_orders: Array<Record<string, any>>;
    op_pages_loaded: number;
    op_total_pages: number;
    op_load_complete: boolean;
    op_load_error: boolean;
    op_updatingOrder: boolean;
}

interface OrderDeliveryDatePayload {
    op_order_id: number;
    op_delivery_date: string;
}

interface MetaItem {
    id: number;
    key: string;
    value: any;
}

export const loadOrderPlannerOrders = createAsyncThunk(
    'op_orders/loadOrderPlannerOrders',
    async (customerId: any) => {

        let result = await AutoShipAPI.getAutoshipOrders(customerId);

        const orders = [...result.json]

        return {
            op_orders: orders,
            op_total_pages: result.total_pages
        }
    }
);

const initialState: OrdersState = {
    op_orders: [],
    op_pages_loaded: 0,
    op_total_pages: 0,
    op_load_complete: false,
    op_load_error: false,
    op_updatingOrder: false
};

export const orderplannerordersSlice = createSlice({
    name: "op_orders",
    initialState: initialState,
    reducers: {
        addOrderPlannerOrder: (state, action: PayloadAction<Record<string, any>>) => {
            return {
                ...state,
                op_orders: [...state.op_orders, action.payload]
            }
        },
        cancelOrderPlannerOrder: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                op_orders: state.op_orders.filter(order => order.id !== action.payload)
            };
        },
        resetOrderPlannerOrders: (state, action: PayloadAction<undefined>) => {
            return initialState;
        },
        setUpdatingOrderPlannerOrder: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                op_updatingOrder: action.payload
            }
        },
        updateOrderPlannerOrder: (state, action: PayloadAction<Record<string, any>>) => {
            return {
                ...state,
                op_orders: state.op_orders.map((order) => {
                    if (order.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return order;
                    }
                })
            }
        },
        updateOrderPlannerOrderDeliveryDate: (state, action: PayloadAction<OrderDeliveryDatePayload>) => {
            return {
                ...state,
                op_orders: state.op_orders.map((order) => {
                    if (order.id === action.payload.op_order_id) {
                        return {
                            ...order,
                            meta_data: order.meta_data.map((meta_item: MetaItem) => {
                                if (meta_item.key === '_delivery_date') {
                                    return { ...meta_item, value: action.payload.op_delivery_date }
                                }
                                return meta_item;
                            })
                        }
                    }
                    return order;
                })
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(loadOrderPlannerOrders.pending, (state, action) => {
            state.op_load_complete = false;
            state.op_load_error = false;
        });
        builder.addCase(loadOrderPlannerOrders.fulfilled, (state, action) => {
            let orders;
            if (action.payload.op_orders !== undefined && action.payload.op_total_pages !== undefined) {
                if (state.op_pages_loaded === 0) {
                    orders = [...action.payload.op_orders]
                } else {
                    orders = [...state.op_orders, ...action.payload.op_orders]
                }
                return {
                    ...state,
                    op_total_pages: action.payload.op_total_pages,
                    op_pages_loaded: state.op_pages_loaded + 1,
                    op_load_complete: (state.op_pages_loaded + 1 >= action.payload.op_total_pages),
                    op_orders: orders
                }
            }
        });
        builder.addCase(loadOrderPlannerOrders.rejected, (state, action) => {
            state.op_load_error = true;
        });
    }
});

export const { addOrderPlannerOrder,
    cancelOrderPlannerOrder,
    resetOrderPlannerOrders,
    setUpdatingOrderPlannerOrder,
    updateOrderPlannerOrder,
    updateOrderPlannerOrderDeliveryDate } = orderplannerordersSlice.actions;
export const selectOrderPlannerOrders = (state: RootState) => state.op_orders.op_orders;
export const selectOrderPlannerOrdersLoadComplete = (state: RootState) => state.op_orders.op_load_complete;
export const selectOrderPlannerOrdersTotalPages = (state: RootState) => state.op_orders.op_total_pages;
export const selectOrderPlannerOrdersPagesLoaded = (state: RootState) => state.op_orders.op_pages_loaded;
export const selectOrderPlannerOrderLoadError = (state: RootState) => state.op_orders.op_load_error;
export const selectUpdatingOrderPlannerOrder = (state: RootState) => state.op_orders.op_updatingOrder;
export default orderplannerordersSlice.reducer;