import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import MyAccountNav from '../components/MyAccountNav';
import BillingAndShipping from '../features/orders/BillingAndShipping';
import DeliveryDetails from '../features/orders/DeliveryDetails';
import OrderDetails from '../features/orders//OrderDetails';
import { selectOrders } from '../features/orders/ordersSlice';
import OrderObj from '../features/orders/orderObj';
import { selectToken } from '../features/user/userSlice';
import MyAccountPage from './MyAccountPage';

export default function MyOrderPage() {
  let params = useParams();
  const orders = useSelector(selectOrders);
  const order = params.orderId ? OrderObj.findOrder(orders, parseInt(params.orderId)) : null;
  const token = useSelector(selectToken);

  if (!token) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Container fluid className='my-order-page'>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9}>
          {order &&
            <Card className='my-order my-3' body>
              <Row>
                <Col xs={12} className='fs-5'>
                  Order <span className='fw-bold'>#{order.data.id}</span> was placed on&nbsp;
                  <span className='fw-bold'>{order.getDate('long')}</span> and is currently&nbsp; 
                  <span className='fw-bold'>{order.data.status}</span>.
                </Col>
              </Row>
              <Row className='my-3'>
                <Col xs={12}>
                  <Alert variant='secondary' className='fs-4'>
                    Your Delivery Date 
                    is <span className='fw-bold'>{order.getDeliveryDate('long')}</span>
                  </Alert>
                </Col>
              </Row>
              <OrderDetails order={order} showActions={true} />
              <BillingAndShipping order={order} />
              <DeliveryDetails order={order} />
            </Card>
          }
        </Col>
      </Row>
    </Container>
  );
}