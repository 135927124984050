import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCustomer } from '../customer/customerSlice';
import { selectProducts } from '../products/productsSlice';
import CouponObj from '../coupons/couponObj';
import CartObj from './cartObj';
import CustomerObj from '../customer/customerObj';
import { mightyPointsToCurrency } from '../customer/mightyPointsUtils';
import { useLocation } from 'react-router-dom';

interface Props {
  cart: CartObj;
  cartCoupon: CouponObj | null;
}

export default function OrderSummary({ cart, cartCoupon }: Props) {
  const location = useLocation();
  const products = useSelector(selectProducts);
  const customer = new CustomerObj(useSelector(selectCustomer));
  const isAutoShipOrder = location.pathname.startsWith('/autoship/menu') && cartCoupon;
  const maxUsablePoints = customer.getMaxUsablePoints(
    cart.getSubtotal(null, null, null) as number
  );
  const isGiftCardProduct = (cart.hasProductWithCategory('gift-card', products) ||
    cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
    cart.hasProductWithCategory('giftcard-donation', products));
  const meta_data = cartCoupon?.data?.meta_data;
  const freeProductsMeta = meta_data?.find((meta: any) => meta.key === '_wjecf_free_product_ids');
  const matchedFreeProducts = freeProductsMeta?.value.split(',').map((id: string) => parseInt(id, 10));
  const discountedPrice = (cart.getSubtotal(null, null, null, false) as number) -
    (Math.round((cart.getSubtotal(cartCoupon, products, customer, false) as number) * 100) / 100);
  const roundFigureDiscountedPrice = Math.round((discountedPrice) * 100) / 100;

  return (
    <div className='order-summary'>
      <Row className="py-1">
        <Col className='order-summary-label'>Order Summary</Col>
      </Row>
      <Row className="py-1">
        <Col xs={7}>{cart.getItemCount()} item(s)</Col>
        <Col xs={5}>{isGiftCardProduct ?
          cart.getSubtotal(null, null, null, true) :
          cart.getSubtotal(null, products, customer, true)}
        </Col>
      </Row>
      {isAutoShipOrder && cartCoupon.isPointsCoupon(customer) &&
        <Row>
          <Col xs={7} className='mobileHide'>Points redemption: </Col>
          <Col xs={5} className='mobileTd' data-name="Points redemption:">
            {mightyPointsToCurrency(maxUsablePoints, true)}
          </Col>
        </Row>
      }
      {isAutoShipOrder &&
        ['fixed_cart', 'fixed_product', 'percent', 'percent_product', 'smart_coupon'].includes(cartCoupon.data.discount_type) &&
        <Row>
          <Col xs={7} className='mobileHide' >Coupon: </Col>
          <Col xs={5}>
            <span className='fw-normal'>
              {cartCoupon.data.code.length > 10
                ? `${cartCoupon.data.code.slice(0, 10)}...`
                : cartCoupon.data.code}
            </span>
          </Col>
        </Row>
      }
      {matchedFreeProducts && cartCoupon &&
        <Row className="py-1">
          <Col xs={7}>Discounts:</Col>
          <Col xs={5}>
            ${roundFigureDiscountedPrice}
          </Col>
        </Row>
      }
      {!matchedFreeProducts && cartCoupon &&
        ['fixed_cart', 'fixed_product', 'percent', 'percent_product'].includes(cartCoupon.data.discount_type) &&
        <Row className="py-1">
          <Col xs={7}>Discounts:</Col>
          <Col xs={5}>
            {cartCoupon.getCartSubtotalDiscount(cart, products, true)}
          </Col>
        </Row>
      }
      {!matchedFreeProducts && cartCoupon && cartCoupon.data.discount_type === 'smart_coupon' &&
        <Row className="py-1">
          <Col xs={7}>Discounts:</Col>
          <Col xs={5}>
            {cartCoupon.getSmartCouponAmount(
              cart.getSubtotal(null, null, null) as number, true)}
          </Col>
        </Row>
      }
      <Row className="py-1">
        <Col xs={7} className="subtotal-label">
          Subtotal
        </Col>
        <Col xs={5} className="fw-bold">
          {isGiftCardProduct && cartCoupon ?
            (cart.getSubtotal(cartCoupon, products, null, true)) :
            isGiftCardProduct ? cart.getSubtotal(null, null, null, true) :
              cartCoupon ?
                cart.getSubtotal(cartCoupon, products, customer, true) :
                cart.getSubtotal(null, products, customer, true)}
        </Col>
      </Row>
    </div>
  );
}