import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loadDetails, selectDetailsLoading } from '../core/detailsSlice'
import { Button, Container, Modal, Spinner } from 'react-bootstrap'
import DetailsWrapper from './DetailsWrapper'
import { selectToken } from '../../../features/user/userSlice'
import { selectIsMobileRoute } from '../../../features/mobile/mobileSlice'
import { selectOrders, selectOrdersLoadComplete, selectOrdersPagesLoaded } from '../../../features/orders/ordersSlice'
import { selectCustomerDetailsIsLoaded, selectCustomerDetailsIsLoading } from '../../../features/customer/customerDetailSlice'
import moment from 'moment'
import OrderObj from '../../../features/orders/orderObj'

export default function DetailsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(selectToken);
    const paramId = useParams().workoutId;
    const detailsLoading = useSelector(selectDetailsLoading);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false);
    const AppURL = useSelector(selectIsMobileRoute);
    const allOrders: Array<Record<string, any>> = useSelector(selectOrders);
    const ordersLoaded = useSelector(selectOrdersLoadComplete);
    const pages_loaded: number = useSelector(selectOrdersPagesLoaded);
    const customerLoading = useSelector(selectCustomerDetailsIsLoading);
    const customerLoaded = useSelector(selectCustomerDetailsIsLoaded);
    const urlParams = AppURL ? `/?${AppURL}` : '/';
    const orders = allOrders.filter((order: any) => {
        return order.status === 'completed' || order.status === 'processing';
    });
    const latestOrder = orders && OrderObj.findRecentOrder(orders);
    const isWaiting = ((!customerLoading && !customerLoaded) || (customerLoading && !customerLoaded) ||
        (!pages_loaded && !ordersLoaded)) ? true : false;

    useEffect(() => {
        if (latestOrder && latestOrder.data && latestOrder?.data?.date_paid) {
            const lastOrderDate = moment(latestOrder?.data?.date_paid).toDate();
            const twoMonthsAgo = moment().subtract(2, 'months').toDate();

            if (lastOrderDate < twoMonthsAgo) {
                setIsModalShow(true);
            }
        } else if (allOrders.length === 0) {
            setIsModalShow(true);
        }
    }, [allOrders, latestOrder, ordersLoaded, pages_loaded])

    useEffect(() => {
        if (!customerLoading && customerLoaded && latestOrder && latestOrder.data) {
            const lastOrderDateLastLength = moment(latestOrder?.data.date_paid).toDate();
            const lastOrderDateFirstLength = moment(latestOrder?.data.date_paid).toDate();
            const twoMonthsAgo = moment().subtract(2, 'months').toDate();

            if (!(lastOrderDateLastLength < twoMonthsAgo) || !(lastOrderDateFirstLength < twoMonthsAgo)) {
                setIsModalShow(false);
            }
        }
    }, [customerLoading, customerLoaded, latestOrder, ordersLoaded, pages_loaded])

    useEffect(() => {
        if (!token) {
            navigate(`${urlParams}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, token])

    useEffect(() => {
        if (detailsLoading) {
            setIsLoaded(true);
        }
    }, [detailsLoading])

    useEffect(() => {
        fetchDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramId])

    const fetchDetails = async () => {
        await dispatch(loadDetails(Number(paramId)))
    }

    return (
        <Container>
            {!token ?
                <Modal
                    show={true}
                    backdrop="static"
                >
                    <Modal.Body className='font-barlow'>
                        <div className='text-center fs-3'>
                            <p className='mt-2'>Please Log-in/Register<br /> To Access MightyFit.</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={() => { navigate(`${urlParams}`); }}>
                            Cancel
                        </Button>
                        <Button
                            variant="outline-dark"
                            onClick={() => navigate(`/my-account${urlParams}`)}>
                            Log-in or Register
                        </Button>
                    </Modal.Footer>
                </Modal> :
                <Modal
                    show={isModalShow}
                    onHide={() => setIsModalShow(false)}
                    backdrop="static"
                >
                    <Modal.Body className='font-barlow'>
                        <div className='text-center fs-3'>
                            {isWaiting ?
                                <p>Please wait while we checking your meals from the past two months.</p> :
                                <p className='mt-2'>Sorry, your MightyFit access is temporarily suspended due to no meal purchase in 2 months.
                                    Please buy our healthy and delicious food to reactivate.</p>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" disabled={isWaiting} onClick={() => { navigate(`${urlParams}`); }}>
                            Cancel
                        </Button>
                        <Button
                            variant="outline-dark"
                            onClick={() => { setIsModalShow(false); navigate(`/order${urlParams}`); }}
                            disabled={isWaiting}>
                            {isWaiting ?
                                <>Please wait... &nbsp;&nbsp;<Spinner animation="border" as="span" size="sm" /></> : 'Explore menu'}
                        </Button>
                    </Modal.Footer>
                </Modal>}
            <section className="detail-page mb-5">
                {detailsLoading &&
                    <div className='text-center my-5 h-100'>
                        <Spinner
                            variant="dark"
                            animation="border"
                            as="span"
                            className='spinner-xl my-25px'
                        />
                    </div>}
                {isLoaded && !detailsLoading &&
                    <DetailsWrapper />}
            </section>
        </Container>
    )
}
