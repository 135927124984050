import { useMemo } from 'react';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectToken } from '../features/user/userSlice';
import MyAccountNav from '../components/MyAccountNav';
import {
  selectCouponLoadError,
  selectCoupons,
  selectCouponsLoaded,
  selectCouponsLoading
} from '../features/coupons/couponsSlice';
import CouponList from '../features/coupons/CouponList';
import { selectCustomer } from '../features/customer/customerSlice';
import CustomerObj from '../features/customer/customerObj';
import CouponObj from '../features/coupons/couponObj';
import CouponUtils from '../features/coupons/couponUtils';
import MyAccountPage from './MyAccountPage';



export default function GiftCardsPage() {
  const token = useSelector(selectToken);
  const customerData = useSelector(selectCustomer);
  const customer = useMemo(() => {
    return new CustomerObj(customerData);
  }, [customerData]);
  const coupons: Array<Record<string, any>> = useSelector(selectCoupons);
  const displayableCoupons = useMemo(() => {
    return CouponUtils.getDisplayableCoupons(coupons, customer);
  }, [coupons, customer]);
  const couponsLoaded = useSelector(selectCouponsLoaded);
  const couponsLoading = useSelector(selectCouponsLoading)
  const couponLoadError = useSelector(selectCouponLoadError);

  if (!token) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Container fluid="lg" className='gift-cards-page'>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9}>
          <Card className='my-5 font-barlow' body>
            <div className='fs-2 mt-2 mb-4'>Available Coupons &amp; Gift Cards</div>
            {couponsLoading &&
              <div className='d-flex justify-content-center my-2'>
                <Spinner className='text-black my-2' animation="grow" />
              </div>
            }
            {couponLoadError &&
              <div className='fs-4 text-danger mb-5'>
                We're sorry. An unexpected error has occurred. Please try again later.
              </div>
            }
            {couponsLoaded && displayableCoupons.length > 0 &&
              <div>
                <CouponList />
                <div className='d-flex justify-content-end fs-5'>
                  Total Credit Amount:
                </div>
                <div className='d-flex justify-content-end fs-3 fw-bold'>
                  {CouponObj.getTotal(displayableCoupons, true)}
                </div>
              </div>
            }
            {couponsLoaded && displayableCoupons.length <= 0 &&
              <p className='fs-5 my-5'>Sorry, there are no available coupons for you.</p>
            }
          </Card>
        </Col>
      </Row>
    </Container>
  )
}