import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Spinner, Image } from 'react-bootstrap';
import { selectOrders } from '../../../features/orders/ordersSlice';
import OrderObj from '../../../features/orders/orderObj';
import { setNotifications, setOrderPlanType, setPreviousDate, setSelectedDate, setSelectedZipCode, setTurnOnAutoship } from '../core/autoShipSlice';
import { selectCustomer } from '../../../features/customer/customerSlice';
import { useState } from 'react';
import { selectIsMobileRoute } from '../../../features/mobile/mobileSlice';
import AutoShipAPI from '../core/autoshipAPI';
import Window from '../../../utils/Window';
import { selectOrderPlannerOrders } from '../core/orderPlannerOrdersSlice';

export default function AutoshipOrderListMobile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const AppURL = useSelector(selectIsMobileRoute);
    const urlParams = AppURL ? `/?${AppURL}` : '';
    const [turningOn, setTurningON] = useState(false);
    const custData = useSelector(selectCustomer);
    const orders: Array<Record<string, any>> = useSelector(selectOrders);
    const allOPorders: Array<Record<string, any>> = useSelector(selectOrderPlannerOrders);
    const autoshipOrders = allOPorders.filter((order, index, self) => index === self.findIndex((o) => o.id === order.id));
    const order = OrderObj.findRecentOrder(orders);
    const lastOrderedDate = order && order.getDeliveryDateTime();
    const zip = custData?.shipping?.postcode ? custData?.shipping?.postcode : custData?.billing?.postcode;
    const orderDate = order?.getDeliveryDateTime();
    const OrderPlannerImage = Window.isMobile() ?
        'https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-mobile.jpg' :
        'https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-desktop.jpg';
    const zipCode = order?.data.shipping.postcode ? order?.data.shipping.postcode :
        order?.data.billing.postcode ? order?.data.billing.postcode : zip;

    const handleTurnOnAutoship = async () => {
        setTurningON(true);
        try {
            await AutoShipAPI.postUserNotifications({
                userId: custData.id,
                autoshipAutoAddWeeks: '1',
                autoshipEmailNotifications: '1',
                autoshipTextMsgNotifications: '1',
            }).then((response) => {
                if (response.status === "success") {
                    dispatch(setNotifications(response.data));
                }
            });
        } catch (e: any) {
            console.log(e, "Error while Turning on Autoship");
        } finally {
            dispatch(setTurnOnAutoship(true));
            dispatch(setOrderPlanType("pick_for_myself"));
            dispatch(setPreviousDate(orderDate));
            dispatch(setSelectedDate(orderDate));
            dispatch(setSelectedZipCode(zipCode));
            navigate(`/autoship${urlParams}`);
            setTurningON(false);
        }
    }

    return (
        <div className='my-orders-mobile my-5'>
            {autoshipOrders.length > 0 &&
                <div className='d-flex justify-content-center align-items-center'>
                    <Button
                        onClick={() => {
                            dispatch(setPreviousDate(lastOrderedDate));
                            dispatch(setSelectedDate(lastOrderedDate));
                            dispatch(setSelectedZipCode(zip));
                            navigate(`/autoship${urlParams}`);
                        }}
                        size="sm"
                        variant="success"
                        className='mx-3 w-100 text-white mb-3'
                    >
                        View Order Planner Calendar
                    </Button>
                </div>}
            {autoshipOrders.length <= 0
                ? <Card body className='my-1'>
                    <div className='autoShip'>
                        {turningOn ?
                            <div className="loader">
                                <Spinner
                                    variant="dark"
                                    animation="border"
                                    as="span"
                                    size='sm'
                                    className='spinner-xl my-25px'
                                />
                            </div> :
                            <Image
                                src={OrderPlannerImage}
                                className='cursor-pointer'
                                alt='orderPlanner'
                                onClick={handleTurnOnAutoship} />}
                    </div>
                </Card>
                : <>
                    {autoshipOrders.map((orderData) => {
                        const order = new OrderObj(orderData);
                        return (
                            <Card body className='my-1' key={order.data.id}>
                                <Row className='my-1'>
                                    <Col xs={6} className='fw-bold'>Order</Col>
                                    <Col xs={6}>
                                        <Button
                                            as={Link as any}
                                            to={'/autoship/orders/' + order.data.id + urlParams}
                                            variant="link"
                                            order={order}
                                            className='text-black p-0'
                                        >#{order.data.id}</Button>
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col xs={6} className='fw-bold'>Date</Col>
                                    <Col xs={6}>
                                        {order.getDate()}
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col xs={6} className='fw-bold'>Delivery Date</Col>
                                    <Col xs={6}>{order.getDeliveryDate()}</Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col xs={6} className='fw-bold'>Total</Col>
                                    <Col xs={6}>
                                        <span className='fw-bold'>${order.data.total}</span> for&nbsp;
                                        {order.getTotalItems()} items
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col xs={6} className='my-1 fw-bold'>Actions</Col>
                                    <Col xs={6} className='vstack gap-1'>
                                        <Link to={'/autoship/orders/' + order.data.id + urlParams}>
                                            <Button
                                                size="sm"
                                                variant="success"
                                                className='text-white mt-0 my-2 w-100'
                                            >
                                                View
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}
                </>}
        </div>
    );
}