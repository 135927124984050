import React from 'react'
import { Accordion } from 'react-bootstrap'

interface Props {
    accordionItems: Array<{
        tutorial_title: string;
        tutorial_video_url: string;
    }>;
}


export default function AccordionFAQs({ accordionItems }: Props) {
    return (
        <Accordion flush className='w-100'>
            {accordionItems.slice(1).map((item, index) => (
                <Accordion.Item eventKey={(index + 1).toString()} key={index + 1}>
                    <Accordion.Header>{item.tutorial_title}</Accordion.Header>
                    <Accordion.Body>
                        <div className="d-flex justify-content-center">
                            <video width="100%" controls>
                                <source src={item.tutorial_video_url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    )
}
