import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import pickmyself from '../../../assets/images/person-icon.svg';
import pickforme from '../../../assets/images/chefs-choice.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Window from '../../../utils/Window';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedDate, selectSelectedDates, setEditCouponLines, setExistingCartItems, setIsOrderChanged, setSelectedDates } from '../core/autoShipSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { selectToken } from '../../../features/user/userSlice';
import { selectProducts } from '../../../features/products/productsSlice';
import { selectCustomer } from '../../../features/customer/customerSlice';
import { setCart } from '../../../features/cart/cartSlice';
import CustomerObj from '../../../features/customer/customerObj';
import OrderObj from '../../../features/orders/orderObj';
import { format } from 'date-fns';
import OrdersAPI from '../../../API/ordersAPI';
import { selectCustomerDetails } from '../../../features/customer/customerDetailSlice';
import CouponForm from '../../../features/coupons/CouponForm';
import { selectIsMobileRoute } from '../../../features/mobile/mobileSlice';
import { cancelOrderPlannerOrder, selectOrderPlannerOrders, selectUpdatingOrderPlannerOrder, setUpdatingOrderPlannerOrder, updateOrderPlannerOrder } from '../core/orderPlannerOrdersSlice';

interface Props {
  orderId?: string;
  autoshipSelectPage: boolean;
  setAutoshipSelectPage: (autoshipSelectPage: boolean) => void;
  setMultiDates: (Dates: Date[]) => void;
}

const AutoshipSelectPage = ({ orderId, autoshipSelectPage, setAutoshipSelectPage, setMultiDates }: Props) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const orderPlannerOrders = useSelector(selectOrderPlannerOrders);
  const products = useSelector(selectProducts);
  const customerData = useSelector(selectCustomer);
  const customerDetails = useSelector(selectCustomerDetails);
  const selectedDate = useSelector(selectSelectedDate);
  const selectedDates = useSelector(selectSelectedDates);
  const updatingOrder = useSelector(selectUpdatingOrderPlannerOrder);
  const [isUpdating, setIsUpdating] = useState(false);
  const [pauseResumeOrder, setPauseResumeOrder] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const currentOrderId = orderId ? orderId : params.orderId;
  const customerUserData = customerDetails?.user_data;
  const customerImage = customerUserData?.imageUrl;
  const customer = useMemo(() => {
    return new CustomerObj(customerData);
  }, [customerData]);
  const currentOrder = currentOrderId ? OrderObj.findOrder(orderPlannerOrders, parseInt(currentOrderId)) : null;
  const pickForMyselfOrder = currentOrder && currentOrder.data.meta_data.find((meta: any) => meta.key === '_autoship_order_type' && meta.value === 'pick_for_myself');
  const pickForMeOrder = currentOrder && currentOrder.data.meta_data.find((meta: any) => meta.key === '_autoship_order_type' && meta.value === 'pick_for_me');
  const onHoldOrder = currentOrder && currentOrder.data.status === "autoship-onhold";
  const imageUrl = customerImage && customerImage.includes('userdefault.png') ? pickmyself : customerImage;
  const isNotAutoshipSelectPage = location.pathname !== '/autoship/select';

  useEffect(() => {
    if (selectedDate === null || selectedDate === undefined) {
      navigate(`/autoship${urlParams}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const formatDate = (date: any) => {
    if (!date) return '';
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const handleEditOrder = async (isNeedToChange: boolean) => {
    setIsUpdating(true);
    dispatch(setIsOrderChanged(!isNeedToChange));

    if (currentOrder) {
      try {
        const cartItems = currentOrder.lineItemsToCartItems(products, customer, true);
        await Promise.all([
          dispatch(setCart({ token, cart_items: cartItems })),
          dispatch(setExistingCartItems(cartItems)),
          dispatch(setEditCouponLines(currentOrder.data.coupon_lines))
        ]);
      } catch (e) {
        console.error("Error while updating cart:", e);
      } finally {
        navigate(`/autoship/menu/${currentOrder.data.id}${urlParams}`, {
          state: {
            isEditOrder: true,
            shippingId: currentOrder.data.shipping_lines[0].id,
            pickedForMyself: false,
            date: currentOrder?.getDeliveryDateTime(),
            changeToSelect: isNeedToChange
          }
        });
      }
    } else {
      navigate(`/autoship/menu${urlParams}`, {
        state: {
          pickedForMyself: true,
          date: selectedDate
        }
      });
    }

    setIsUpdating(false);
    setAutoshipSelectPage(false);
  };

  const handleCancelOrder = async () => {
    setIsUpdating(true);
    const selectedDateString = format(new Date(selectedDate), 'yyyy-MM-dd');
    const updatedDates = await selectedDates.filter((date: Date) => format(date, 'yyyy-MM-dd') !== selectedDateString);
    setMultiDates(updatedDates);
    await dispatch(setSelectedDates(updatedDates));
    if (currentOrderId) {
      dispatch(setUpdatingOrderPlannerOrder(true));
      try {
        const response = await OrdersAPI.updateOrder(token, Number(currentOrderId), { status: 'mmcancelled' });
        OrdersAPI.createOrderNote(token, Number(currentOrderId), "Customer cancelled order.");

        if ('id' in response) {
          dispatch(cancelOrderPlannerOrder(response.id));
        }

      } catch (error: any) {
        console.log(error.message);
      } finally {
        dispatch(setUpdatingOrderPlannerOrder(false));
      }
    }
    setIsUpdating(false);
    setAutoshipSelectPage(false);
    navigate(`/autoship${urlParams}`);
  }

  const handlePickForMeOption = async () => {
    setIsSelected('Forme');
    if (pickForMyselfOrder) {
      setIsUpdating(true);
      try {
        const cartItems = currentOrder.lineItemsToCartItems(products, customer, true);
        //await dispatch(setCart({ token: token, cart_items: cartItems }));
        await dispatch(setExistingCartItems(cartItems));
        await dispatch(setEditCouponLines(currentOrder.data.coupon_lines));
      } catch (e: any) {
        console.log("Error while Updating cart: ", e);
      } finally {
        navigate(`/autoship/settings/${currentOrder.data.id}${urlParams}`, {
          state: {
            isSetting: true,
            isEditOrder: true,
            shippingId: currentOrder.data.shipping_lines[0].id,
            pickedForMyself: false,
            date: currentOrder?.getDeliveryDateTime(),
            changeToSelect: true
          }
        });
      }
      setIsUpdating(false);
    } else {
      navigate(`/autoship/settings${urlParams}`, { state: { pickedForMe: true, date: selectedDate, isSetting: true } });
    }
    setAutoshipSelectPage(false);
  }

  const handlePauseResumeOrder = async () => {
    setPauseResumeOrder(true);
    try {
      const orderStatus = onHoldOrder ? 'autoship' : 'autoship-onhold';
      const orderData: Record<string, any> = { status: orderStatus };
      const response = await OrdersAPI.updateOrder(token, Number(currentOrderId), orderData);
      if ('id' in response) {
        dispatch(updateOrderPlannerOrder(response));
      }
    } catch (error) {
      console.log(error, "Error While Pause/Resume Autoship Order.");
    } finally {
      setPauseResumeOrder(false);
      setAutoshipSelectPage(false);
    }
  }

  return (
    <Container className='autoshipContainer'>
      <div className='autoshipselect'>
        <div className='d-flex justify-content-center align-items-center autoshipPage mb-2'>
          {!isNotAutoshipSelectPage &&
            <div className='selectcrossicon'>
              <FontAwesomeIcon
                color='black'
                className='cursor-pointer'
                onClick={() => navigate(`/autoship${urlParams}`)}
                icon={faClose}
              ></FontAwesomeIcon>
            </div>}
          <span className='editingtext'>Editing&nbsp;
            <span className='editedDate'>
              {formatDate(new Date(selectedDate))}
            </span>
          </span>
        </div>
        <Row className='d-flex justify-content-center w-auto autoshipselect-row'>
          <Col md={6}>
            <div className='autoshipselect-col'>
              <div className='d-flex justify-content-center'>
                <Image
                  src={imageUrl}
                  alt="pickmyself"
                  className={`${customerImage && !customerImage.includes('userdefault.png') ? 'customerImageIcon' : ''}`}
                />
              </div>
              <div className='d-flex justify-content-center'>
                <span className='headerText'>Pick for Myself</span>
              </div>
              <div className={`${customerImage && customerImage.includes('userdefault.png') ?
                'for-me-text' :
                'for-myself-text'} d-flex justify-content-center`}>
                <span>Select meals of your choosing for this delivery date.</span>
              </div>
              <div className='d-flex justify-content-center'>
                <Button
                  size="sm"
                  variant={pickForMyselfOrder ? 'success' : 'dark'}
                  style={(onHoldOrder && pickForMyselfOrder) ? { backgroundColor: '#f98f50', borderColor: '#f98f50' } : {}}
                  className='mx-3 w-75'
                  disabled={isUpdating || pickForMyselfOrder || pauseResumeOrder}
                  onClick={() => {
                    setIsSelected('Myself');
                    handleEditOrder(true);
                  }}
                >
                  {(isUpdating && isSelected === 'Myself') ?
                    <>
                      Please wait ...&nbsp;
                      <Spinner
                        animation="border"
                        as="span"
                        size="sm"
                      />
                    </> :
                    pickForMyselfOrder ?
                      'Selected' :
                      pickForMeOrder ?
                        'Change to Pick For Myself' :
                        'Select'}
                </Button>
              </div>
              {pickForMyselfOrder && orderId &&
                <div>
                  {(currentOrder?.data.coupon_lines && currentOrder?.data.coupon_lines?.length > 0) ?
                    <span>Coupon "{currentOrder?.data.coupon_lines[0]?.code}" applied to order.</span> :
                    <>
                      <Row className="py-1">
                        <Col className='fw-bold'>Have a discount?</Col>
                      </Row>
                      <CouponForm isAutoshipOrder={true} latestOrder={currentOrder?.data} orderId={orderId} />
                    </>
                  }
                </div>
              }
            </div>
          </Col>
          <Col md={6}>
            <div className='autoshipselect-col'>
              <div className='d-flex justify-content-center'>
                <Image
                  src={pickforme}
                  alt="pickforme"
                />
              </div>
              <div className='d-flex justify-content-center'>
                <span className='headerText'>Chef's Choice</span>
              </div>
              <div className='for-me-text d-flex justify-content-center'>
                <span>Select this option if you would like us to pick your meals for you for this delivery date.</span>
              </div>
              <div className='d-flex justify-content-center'>
                <Button
                  size="sm"
                  variant={pickForMeOrder ? 'success' : 'dark'}
                  style={(onHoldOrder && pickForMeOrder) ? { backgroundColor: '#f98f50', borderColor: '#f98f50' } : {}}
                  className='mx-3 w-75'
                  disabled={isUpdating || pickForMeOrder || pauseResumeOrder}
                  onClick={handlePickForMeOption}
                >
                  {(isUpdating && isSelected === 'Forme') ?
                    <>
                      Please wait ...&nbsp;
                      <Spinner
                        animation="border"
                        as="span"
                        size="sm"
                      />
                    </> :
                    pickForMeOrder ?
                      'Selected' :
                      pickForMyselfOrder ?
                        `Change to Chef's Choice` :
                        'Select'}
                </Button>
              </div>
              {pickForMeOrder && orderId &&
                <div>
                  {(currentOrder?.data.coupon_lines && currentOrder?.data.coupon_lines?.length > 0) ?
                    <span>Coupon "{currentOrder?.data.coupon_lines[0]?.code}" applied to order.</span> :
                    <>
                      <Row className="py-1">
                        <Col className='fw-bold'>Have a discount?</Col>
                      </Row>
                      <CouponForm isAutoshipOrder={true} latestOrder={currentOrder?.data} orderId={orderId} />
                    </>
                  }
                </div>
              }
            </div>
          </Col>
          <Col md={12} className='both-part'>
            {currentOrderId &&
              <>
                <Col className="btn-both-part mt-2 d-flex align-items-center">
                  <div className={`d-flex ${Window.isMobile() ? 'justify-content-center' : 'justify-content-start'}`}>
                    <Button
                      size="sm"
                      variant={onHoldOrder ? 'success' : undefined}
                      style={!onHoldOrder ? { backgroundColor: '#f98f50', borderColor: '#f98f50' } : {}}
                      className="text-white"
                      disabled={pauseResumeOrder || updatingOrder || isUpdating}
                      onClick={handlePauseResumeOrder}
                    >
                      {pauseResumeOrder ? <>
                        Please wait ...&nbsp;
                        <Spinner
                          animation="border"
                          as="span"
                          size="sm"
                        />
                      </> :
                        onHoldOrder ? 'RESUME ORDER' : 'PAUSE ORDER'}
                    </Button>
                  </div>
                </Col>
                <Col className="btn-both-part">
                  <div className={`d-flex ${Window.isMobile() ? 'justify-content-center ps-2' : 'justify-content-end'}`}>
                    <Button
                      size="sm"
                      variant="danger"
                      className='ms-3 text-white my-2'
                      disabled={updatingOrder || isUpdating || pauseResumeOrder}
                      onClick={handleCancelOrder}
                    >
                      {updatingOrder ?
                        <>
                          Please wait ...&nbsp;
                          <Spinner
                            animation="border"
                            as="span"
                            size="sm"
                          />
                        </> : 'CANCEL THIS ORDER'}
                    </Button>
                  </div>
                </Col>
                <Col className={`btn-both-part ${(pickForMyselfOrder || pickForMeOrder) ? 'center' : 'right'}`}>
                  <div className={`d-flex ${Window.isMobile() ? 'justify-content-center ps-2' : 'justify-content-start'}`}>
                    <Button
                      size="sm"
                      variant="dark"
                      className='ms-3 my-2'
                      disabled={isUpdating || pauseResumeOrder}
                      onClick={() => {
                        navigate(`/autoship${urlParams}`, { state: { changeDate: true, date: selectedDate } });
                        setAutoshipSelectPage(false);
                      }}
                    >
                      CHANGE DATE
                    </Button>
                  </div>
                </Col>
                <Col className="btn-both-part right">
                  <div className={`d-flex ${Window.isMobile() ? 'justify-content-center ps-2' : 'justify-content-start'}`}>
                    <Button
                      size="sm"
                      variant="success"
                      className='mx-3 my-2 text-white'
                      disabled={isUpdating || pauseResumeOrder}
                      onClick={() => handleEditOrder(false)}
                    >
                      {(isUpdating && isSelected === '' && !updatingOrder) ?
                        <>
                          Please wait ...&nbsp;
                          <Spinner
                            animation="border"
                            as="span"
                            size="sm"
                          />
                        </> :
                        'VIEW CART'
                      }
                    </Button>
                  </div>
                </Col>
              </>}
          </Col>
        </Row>
      </div>
    </Container >
  )
}

export default AutoshipSelectPage;