import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import MyAccountNav from '../components/MyAccountNav';
import { selectMealPlans } from '../features/meal_plans/mealPlansSlice';
import MealPlanObj from '../features/meal_plans/mealPlanObj';
import MealPlanItemRow from '../features/meal_plans/MealPlanItemRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { selectCustomer } from '../features/customer/customerSlice';
import CustomerObj from '../features/customer/customerObj';
import { selectProducts } from '../features/products/productsSlice';
import { selectToken } from '../features/user/userSlice';
import { setCart, selectCartUpdateInProgress } from '../features/cart/cartSlice';
import MyAccountPage from './MyAccountPage';
import { selectIsMobileRoute } from '../features/mobile/mobileSlice';

export default function MyMealPlanPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let params = useParams();
  const [showModal, setShowModal] = useState(false);
  const token = useSelector(selectToken);
  const mealPlans = useSelector(selectMealPlans);
  const mealPlan = params.mealPlanId ?
    MealPlanObj.getById(mealPlans, parseInt(params.mealPlanId)) : null;
  const customerData = useSelector(selectCustomer);
  const customer = useMemo(() => {
    return new CustomerObj(customerData);
  }, [customerData]);
  const products = useSelector(selectProducts);
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';

  const handleAddToCart = () => {
    if (!mealPlan) {
      console.error("Meal plan is null. Can't add to cart");
      return;
    }
    const cartItems = mealPlan.toCartItems(products, customer);

    dispatch(setCart({ token: token, cart_items: cartItems }));
    setShowModal(true);
  }

  const handleCheckout = () => {
    setShowModal(false);
    navigate(`/checkout${urlParams}`);
  }

  if (!token) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Container fluid="lg" className='my-meal-plan-page my-5'>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className='border-bottom-0' closeButton>
          <Modal.Title>MightyMeals Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Available items have been added to your cart.</p>
          <div className='d-flex justify-content-end gap-2'>
            <Button variant="outline-dark" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="dark" onClick={handleCheckout}>Checkout</Button>
          </div>
        </Modal.Body>
      </Modal>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9}>
          <Card className='py-5 my-meal-plan h-100 font-barlow'>
            <div className='p-3 pb-4'>
              <Button
                variant="link"
                onClick={() => navigate(`/my-account/my-meal-plans${urlParams}`)}
                className='text-black fs-20px td-none text-start'
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  size={"sm" as SizeProp}
                  className="me-1"
                />Back to My Favorite Meals
              </Button>
            </div>
            <Table
              responsive
              hover
              borderless
              className='fs-6'
            >
              <thead className="d-none d-md-block">
                <tr className='table-secondary fs-5 d-flex'>
                  <th className='ps-4 pe-3 col-7'>Product</th>
                  <th className='px-3 col-2'>Unit Price</th>
                  <th className='px-3 col-3 text-end'>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {mealPlan?.data.items.map((item, index) => {
                  return (
                    <MealPlanItemRow key={index} mealPlan={mealPlan} item={item} />)
                    ;
                })}
              </tbody>
            </Table>
            <div className='d-flex justify-content-end me-3 my-3'>
              <Button
                variant='dark'
                onClick={handleAddToCart}
                disabled={cartUpdateInProgress}
              >
                Add all to cart
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>

  )
}