import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { selectGuestEmail, setEmail } from './guestSlice';
import { selectCartZipcode, setCartZipcode } from '../../features/cart/cartSlice';
import { selectShippingLoaded, selectShippingMethods } from '../../features/shipping/shippingSlice';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import classnames from 'classnames';
import './mealsInYourAreaForm.scss';
import { selectIsMobileRoute } from '../mobile/mobileSlice';

export default function MealsInYourAreaForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [modalMsg, setModalMsg] = useState('');
  const shippingLoaded = useSelector(selectShippingLoaded);
  const shippingMethods = useSelector(selectShippingMethods);
  const email = useSelector(selectGuestEmail);
  const zip = useSelector(selectCartZipcode);
  const AppURL = useSelector(selectIsMobileRoute);
  const QURLParams = AppURL ? `/?${AppURL}` : '/';
  const AndURLParams = AppURL ? `&${AppURL}` : '';

  const handleModalHide = () => setModalMsg('');

  interface FormValues {
    email: string;
    zip_code: string;
  }

  const validate = (values: FormValues) => {
    return {};
  }

  const formik = useFormik({
    initialValues: {
      email: email,
      zip_code: zip
    },
    validate,
    onSubmit: values => {
      setErrorMsg('');
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        dispatch(setEmail(values.email));
      }

      if (!/^\d{5}$/.test(values.zip_code)) {
        navigate(`/order${QURLParams}`);
        return;
      }

      for(const smData of shippingMethods) {
        const sm = new ShippingMethodObj(smData);
        if (sm.isZipCodeMatch(values.zip_code)) {
          dispatch(setCartZipcode(values.zip_code));
          navigate(`/order/?delivery=yes${AndURLParams}`);
          return;
        }
      }
      setModalMsg('Unfortunately, we do not deliver to your zip code');
    }
  });

  return (
    <Row>
      <Col>
        {errorMsg && (
          <p className='text-danger'>{errorMsg}</p>
        )}
        <Modal show={modalMsg !== ''} onHide={handleModalHide}>
          <Modal.Header className='border-bottom-0' closeButton>
            <Modal.Title>MightyMeals Notice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{modalMsg}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleModalHide}>OK</Button>
          </Modal.Footer>
        </Modal>      
        <Form onSubmit={formik.handleSubmit}>
          <div className={classnames(null, {
            'vstack gap-0': window.innerWidth <= 1100,
            'hstack gap-0': window.innerWidth > 1100
          })}>
              <Form.Group className="form-group required">
                <Form.Control 
                  id="email"
                  type="email"
                  placeholder="Email"
                  size="lg"
                  className='border border-dark'
                  isInvalid={Boolean(formik.errors.email)}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group className="form-group required">
                <Form.Control 
                  id="zip_code"
                  type="text"
                  placeholder="Zip Code"
                  size="lg"
                  maxLength={5}
                  className={classnames('border border-dark', {
                    'border-top-0': window.innerWidth <= 1100,
                    'border-start-0': window.innerWidth > 1100
                  })}
                  isInvalid={Boolean(formik.errors.zip_code)}
                  value={formik.values.zip_code}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Button 
                variant='success'
                id="explore-menu-btn"
                type="submit"
                size="lg"
                disabled={!shippingLoaded}
              >
                EXPLORE MENU
              </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
}