import { Card, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MyAccountNav from '../components/MyAccountNav';
import ReferAFriendForm from '../features/customer/ReferAFriendForm';
import { selectToken } from '../features/user/userSlice';
import MyAccountPage from './MyAccountPage';

export default function ReferAFriendPage() {
  const token = useSelector(selectToken);

  if (!token) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Container fluid="lg" className='refer-a-friend-page'>
      <Row>
        <Col sm={12} md={3}>
          <MyAccountNav />
        </Col>
        <Col sm={12} md={9} className='d-flex flex-column my-5'>
          <Card className='font-barlow flex-fill' body>
            <h1 className='text-8xl text-center fw-700'>REFER A FRIEND</h1>
            <h1 className='display-4 text-center fw-500'>GIVE $100 | GET $50</h1>
            <p className='fs-4 text-center'>Give friends <span className='fw-bold'>$100</span> off
              their first four orders and you'll
              get <span className='fw-bold'>$50</span> worth of Mighty
              Points when they purchase.</p>
            <ReferAFriendForm />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}