import { Link, useLocation } from 'react-router-dom';
import { Badge, Button, CloseButton, Col, Container, Row, Image, Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CartObj from "../features/cart/cartObj";
import {
  clearCart,
  deleteCartCoupon,
  selectCartCoupon,
  selectCartIsLoaded,
  selectCartItems,
  selectCartZipcode,
  selectOrderInProgress,
  setCartItem
} from "../features/cart/cartSlice";
import { selectGeneralOptions, selectIsMobileRoute, selectNewCartItem, selectNotifyItem, selectShowCart, selectUtmShipping, selectUtmThreshold, setIsMobileNavToggled, setNewCartItem, setNotifyItem, setShowProductFilters } from "../features/mobile/mobileSlice";
import { selectProducts } from '../features/products/productsSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faBagShopping, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { setShowCart } from "../features/mobile/mobileSlice";
import CouponObj from "../features/coupons/couponObj";
import CouponForm from "../features/coupons/CouponForm";
import './MobileFooter.scss';
import CartItem from '../features/cart/CartItem';
import { useEffect, useMemo, useState } from 'react';
import { selectToken } from '../features/user/userSlice';
import ShippingMethodObj from '../features/shipping/shippingMethodObj';
import { selectShippingMethods } from '../features/shipping/shippingSlice';
import { selectCustomer } from '../features/customer/customerSlice';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Window from "../utils/Window";
import CustomerObj from '../features/customer/customerObj';

interface Props {
  errorMsg?: string;
  submitAutoshipOrder?: () => void;
}

export default function MobileFooter({ submitAutoshipOrder, errorMsg }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector(selectToken);
  const cart = new CartObj(useSelector(selectCartItems));
  const cartIsLoaded = useSelector(selectCartIsLoaded);
  const cartZip = useSelector(selectCartZipcode);
  const cartCoupon = CouponObj.createCoupon(useSelector(selectCartCoupon));
  const showCart = useSelector(selectShowCart);
  const newCartItem = useSelector(selectNewCartItem);
  const custData = useSelector(selectCustomer);
  const customer = useMemo(() => {
    return new CustomerObj(custData);
  }, [custData]);
  const products = useSelector(selectProducts);
  const orderInProgress = useSelector(selectOrderInProgress);
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const mealOffer = getGeneralOptions?.meal_offer;
  const regionalShipLimit = getGeneralOptions?.regional_shipping_meal_limit;
  const vanThresholdLimit = getGeneralOptions?.van_free_shipping_threshold;
  const upsThresholdLimit = getGeneralOptions?.ups_free_shipping_threshold;
  const shippingMethods = useSelector(selectShippingMethods);
  const [clearing, setClearing] = useState(false);
  const [isNotCheckout, setIsNotCheckout] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const notifiedItem = useSelector(selectNotifyItem);
  const utmThreshold = useSelector(selectUtmThreshold);
  const utmShipping = useSelector(selectUtmShipping);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const isMobileDevice = location.search.includes('?utm_source=app');
  const [mealCountOffer, setMealCountOffer] = useState('');
  const [mealCountDiscount, setMealCountDiscount] = useState('');
  const [regShipLimit, seteRegShipLimit] = useState(0);
  const [shippingMethodbyZip, setShippingMethodByZip] = useState<ShippingMethodObj[]>([]);
  const [upsThreshold, setUpsThreshold] = useState(0);
  const [vanThreshold, setVanThreshold] = useState(0);
  const isAutoShipOrder = location.pathname.startsWith("/autoship/menu");
  let isShipping: ShippingMethodObj[] = [];
  const isMenuPage = (location.pathname.startsWith("/order") || isAutoShipOrder) && !Window.isTablet();
  const meta_data = cartCoupon?.data?.meta_data;
  const freeProductsMeta = meta_data?.find((meta: any) => meta.key === '_wjecf_free_product_ids');
  const matchedFreeProducts = freeProductsMeta?.value.split(',').map((id: string) => parseInt(id, 10));
  const isUtmThreshold = (utmThreshold && utmThreshold.ship_threshold_value) ? true : false;
  const isUtmShipping = (utmShipping && utmShipping.ship_price_value) ? true : false;
  const utmThresholdValue = utmThreshold && utmThreshold.ship_threshold_value;
  const utmShippingValue = utmShipping && utmShipping.ship_price_value;
  const isUPSDeliveryThreshold =
    (shippingMethodbyZip.length > 0 &&
      (shippingMethodbyZip[0]?.data.title === "UPS Free Shipping" ||
        shippingMethodbyZip[0]?.data.title === "UPS Shipping"));

  useEffect(() => {
    if (cartZip) {
      getShippingMethodDates(cartZip)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartZip])

  useEffect(() => {
    async function fetchData() {
      try {
        const hasUPS = shippingMethodbyZip.some(item => item?.data.ups_delivery_method === true);
        if (vanThresholdLimit) {
          setVanThreshold(Number(vanThresholdLimit));
        }
        if (upsThresholdLimit) {
          setUpsThreshold(Number(upsThresholdLimit));
        }
        if (hasUPS) {
          seteRegShipLimit(Number(regionalShipLimit));
        } else {
          seteRegShipLimit(0);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [cartZip, regionalShipLimit, shippingMethodbyZip, upsThresholdLimit, vanThresholdLimit]);

  useEffect(() => {
    const couponMiniMum = cartCoupon?.data?.minimum_amount !== '' &&
      cartCoupon?.data?.minimum_amount !== '0' &&
      cartCoupon?.data?.minimum_amount !== '0.00' &&
      cartCoupon?.data?.minimum_amount !== 0;

    const withFreeProductsSubTotal = (cart.hasProductWithCategory('gift-card', products) ||
      cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
      cart.hasProductWithCategory('giftcard-donation', products)) ?
      cart.getSubtotal(null, null, null, true) :
      cartCoupon && matchedFreeProducts?.length > 0 ?
        cart.getSubtotal(cartCoupon, products, customer, true) :
        cart.getSubtotal(null, products, customer, true);
    const freeCartsubTotal = parseFloat(withFreeProductsSubTotal.toString().replace('$', '').replace(/,/g, ''));

    if (couponMiniMum && matchedFreeProducts && Number(cartCoupon?.data?.minimum_amount) > Number(freeCartsubTotal)) {

      matchedFreeProducts.forEach((productId: any) => {
        const cartItem = cart.items[productId];

        if (cartItem) {
          dispatch(setCartItem({
            token: token,
            cart_item: {
              ...cartItem,
              product_qty: 0,
              product_price: cartItem.product_price,
            }
          }));
        }
      });
      dispatch(deleteCartCoupon(token));
    }

    if (matchedFreeProducts && cartCoupon?.data?.product_ids.length > 0) {
      const productIdsInCoupon = cartCoupon?.data.product_ids;

      productIdsInCoupon.forEach((productId: any) => {
        const cartItem = cart.items[productId];

        if (!cartItem) {
          matchedFreeProducts.forEach((freeProductId: any) => {
            const freeCartItem = cart.items[freeProductId];

            if (freeCartItem) {
              dispatch(setCartItem({
                token: token,
                cart_item: {
                  ...freeCartItem,
                  product_qty: 0,
                }
              }));
            }
          });

          dispatch(deleteCartCoupon(token));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartCoupon, cart.items])

  useEffect(() => {
    if (location.pathname !== '/checkout' && location.pathname !== '/checkout/') {
      setIsNotCheckout(true);
    } else {
      setIsNotCheckout(false);
    }
  }, [location.pathname])

  useEffect(() => {
    if (Array.isArray(mealOffer)) {
      mealOffer?.map((meals: any) => {
        if (cart.getItemCount() === Number(meals.number_of_meals) && shippingMethodbyZip[0]?.data.ups_delivery_method === true) {
          setMealCountOffer(meals.offer_message);
          setMealCountDiscount(meals.meal_coupon_code);
        }
        return null;
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.items, shippingMethodbyZip])

  useEffect(() => {
    if (showCart && cart.isEmpty()) {
      dispatch(setShowCart(false));
      dispatch(setNotifyItem([]));
    }
    if (cart.items) {
      setTimeout(() => {
        dispatch(setNewCartItem(false));
        dispatch(setNotifyItem([]));
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.items])

  const handleHide = () => {
    dispatch(setShowCart(false));
  }

  const handleClear = async () => {
    setClearing(true);
    await dispatch(clearCart(token))
    setClearing(false);
  }

  const goBack = () => {
    window.history.back();
  };

  const getShippingMethodDates = (zipCode: any) => {
    let matchedMethods = [];

    let hasFreeDelivery = false;

    for (const method of shippingMethods) {
      const sm = new ShippingMethodObj(method);
      if (sm.isMatch(zipCode, cart, products, cartCoupon, customer, isUtmThreshold, utmThresholdValue)) {
        if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
          hasFreeDelivery = false;
          matchedMethods.push(sm);
        } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
          if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
            hasFreeDelivery = true;
            matchedMethods.push(sm);
          } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {
            matchedMethods.push(sm);
            if (sm.data.ups_delivery_method === true && isShipping.length === 0) {
              isShipping.push(sm);
            }
          }
        }

      }
    }

    if (hasFreeDelivery) {
      matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
    }

    if (isUtmThreshold || isUtmShipping) {
      if (matchedMethods?.length > 0) {
        const originalMethod = matchedMethods[0];

        const newData = JSON.parse(JSON.stringify({
          ID: originalMethod.data.ID,
          title: originalMethod.data.title,
          cost: originalMethod.data.cost,
          ups_delivery_method: originalMethod.data.ups_delivery_method,
          condition_groups: originalMethod.data.condition_groups
        }));

        if (utmThresholdValue !== "0" && isUtmThreshold) {
          const subtotalGroup = newData.condition_groups[0]?.find((group: { condition: string; }) => group.condition === "subtotal");
          const otherGroups = newData.condition_groups[0]?.filter((group: { condition: string; }) => group.condition !== "subtotal") || [];

          if (subtotalGroup) {
            newData.condition_groups[0] = [
              ...otherGroups,
              { ...subtotalGroup, value: utmThresholdValue }
            ];
          }
        }

        if (utmShippingValue && newData.cost !== "0" && newData.ups_delivery_method === true) {
          newData.cost = utmShippingValue;
        }

        const updatedMethod = new ShippingMethodObj({
          ...originalMethod,
          data: newData
        });

        updatedMethod.data = newData;

        matchedMethods = [updatedMethod];
      }
    }

    setShippingMethodByZip(matchedMethods);
  }

  const handleRemoveCoupon = async () => {
    setIsRemoving(true);
    await dispatch(deleteCartCoupon(token));
    setIsRemoving(false);
  }

  return (
    <Container fluid>
      {showCart && !cart.isEmpty() && isNotCheckout &&
        <div className={`fixed-top bg-black w-100 h-100 zindex-20 opacity-50`}></div>
      }
      <footer
        className='mobile-footer fixed-bottom zindex-30'
      >
        {showCart && cart.isEmpty() && isNotCheckout &&
          <div className="text-center pt-2 bg-light text-danger" style={{ height: 50 }}>
            You have no items in your cart!
          </div>}
        {newCartItem && notifiedItem.length > 0 &&
          <div className='bg-white mobile-footer-cart px-0 py-0'>
            <div className='overflow-scroll bg-success px-4 py-2'>
              <Row className='align-items-center py-1'>
                <Col xs={8} className='font-glegoo'>
                  <span className='d-block fw-700'>{notifiedItem?.productName}</span>
                  <span className='d-block'> ${notifiedItem?.productPrice}</span>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <Image src={notifiedItem?.productIMG} alt="" className='w-60' />
                </Col>
              </Row>
            </div>
          </div>
        }
        <>
          {showCart && !cart.isEmpty() && isNotCheckout &&
            <>
              <div className='bg-white mobile-footer-cart'>
                <div className='d-flex justify-content-center position-absolute start-0 w-100 CloseButton'>
                  <CloseButton className='bg-green rounded-circle p-2 opacity-100' onClick={handleHide} />
                </div>
                <Row>
                  <Col xs={8} >
                    Items Added
                  </Col>
                  {clearing ? <Col xs={4} className="text-end" aria-disabled>
                    Clearing ...
                  </Col> :
                    <Col xs={4} className="text-end" onClick={handleClear}>
                      Clear All
                    </Col>}
                </Row>
                <hr />
                <div className='overflow-auto cart-item-max'>
                  {Object.keys(cart.items).map((product_id) => (
                    <CartItem
                      key={product_id}
                      product_id={parseInt(product_id)}
                      cart_item={cart.items[parseInt(product_id)]}
                    />
                  ))}
                </div>
              </div>
            </>}

          <div className={`px-4 bg-black ${isMobileDevice ? 'cartFooterMobile' : ''}`}>
            {((cart.isEmpty() && isMobileDevice) || !isNotCheckout) ?
              <div className='pt-2 pb-2' style={{ paddingRight: '2.5rem' }}>
                <Row className='align-items-center'>
                  {isMobileDevice && <Col xs={8}>
                    <Button
                      className='border-0 position-relative rounded-5 bg-transparent'
                      onClick={goBack}>
                      <FontAwesomeIcon icon={faCircleArrowLeft} size={'1x' as SizeProp} className={`${isMobileDevice ? 'goBackIcon' : ''}`} />
                    </Button>
                  </Col>}
                  {isMenuPage &&
                    <Col xs={2}>
                      <Button
                        variant='dark'
                        className='font-glegoo fs-16px w-100 bg-transparent border-0 d-flex justify-content-center'
                        onClick={() => dispatch(setShowProductFilters(true))}>
                        <FontAwesomeIcon
                          color='white'
                          icon={faFilter}
                        ></FontAwesomeIcon>
                      </Button>
                    </Col>}
                  <Col xs={2} className={`text-end`}>
                    {isMobileDevice &&
                      <Button
                        aria-controls="navbar-nav"
                        aria-label="Toggle navigation"
                        data-toggle="collapse"
                        onClick={() => { dispatch(setIsMobileNavToggled(true)) }}
                        className="p-0 bg-transparent border-0">
                        <span>
                          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="18" cy="18" r="17.75" stroke="#fff" strokeWidth="1.5" />
                            <circle cx="18" cy="18" r="14" fill="#000" />
                            <path d="M13 18.3334H23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13 15H23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13 21.6666H23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                      </Button>}
                  </Col>
                </Row>
              </div> :
              <>{((showCart || !cart.isEmpty() || (location.pathname.startsWith("/order") || location.pathname.startsWith("/autoship/menu"))) && isNotCheckout) &&
                <div className='py-3'>
                  <Row className='align-items-center'>
                    {isMobileDevice && <Col xs={2}>
                      <Button
                        className='border-0 position-relative rounded-5 bg-transparent'
                        onClick={goBack}>
                        <FontAwesomeIcon icon={faCircleArrowLeft} size={'1x' as SizeProp} className={`${isMobileDevice ? 'goBackIcon' : ''}`} />
                      </Button>
                    </Col>}
                    <Col xs={!cart.isEmpty() ? (isMobileDevice ? 5 : 9) : (isMobileDevice ? 6 : 8)} className={`${isMobileDevice ? 'p-0' : ''}`}>
                      {!cartCoupon ?
                        <CouponForm mealCountDiscount={mealCountDiscount} /> :
                        <div className='text-white d-grid'>
                          <Row>
                            <Col xs={5} sm={4} md={3} className='pe-0'>
                              <span>Code Applied:</span>
                            </Col>
                            <Col xs={6} sm={5} md={4}
                              className='bg-white text-black text-uppercase fw-bold p-2 py-0 coupontextmob'
                              title={cartCoupon.data.code}>
                              <span>
                                {cartCoupon.data.code.length > 10
                                  ? `${cartCoupon.data.code.slice(0, 10)}...`
                                  : cartCoupon.data.code}
                              </span>
                            </Col>
                            <Col xs={1} sm={1} md={1} className='bg-white p-0 coupondltbtnmob'>
                              {isRemoving ?
                                <span className='ps-3'>
                                  <Spinner animation="border" as="span" size="sm" />
                                </span> :
                                <span
                                  className='pb-1'
                                  onClick={handleRemoveCoupon}>
                                  <svg
                                    width="25"
                                    height="20"
                                    viewBox="0 0 37 37"
                                    fill="black"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle cx="18.5" cy="18.5" r="18" stroke="white" />
                                    <path
                                      d="M24.0001 13L13 24.0001"
                                      stroke="white"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round" />
                                    <path
                                      d="M13 13L24.0001 24.0001"
                                      stroke="white"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round" />
                                  </svg>
                                </span>}
                            </Col>
                          </Row>
                          <span>Discount:&nbsp;
                            {(matchedFreeProducts && cartCoupon)
                              ? cartCoupon.data.code
                              : (!matchedFreeProducts && cartCoupon && cartCoupon.data.discount_type === 'smart_coupon')
                                ? cartCoupon.getSmartCouponAmount(cart.getSubtotal(null, null, null) as number, true)
                                : cartCoupon.getCartSubtotalDiscount(cart, products, true)}
                          </span>
                        </div>
                      }
                    </Col>
                    {isMenuPage &&
                      <Col xs={1}>
                        <Button
                          variant='dark'
                          className='font-glegoo fs-16px w-100 bg-transparent border-0 d-flex justify-content-center'
                          onClick={() => dispatch(setShowProductFilters(true))}>
                          <FontAwesomeIcon
                            color='white'
                            icon={faFilter}
                          ></FontAwesomeIcon>
                        </Button>
                      </Col>
                    }
                    <Col xs={isMobileDevice ? 4 : isMenuPage ? 2 : 3} className={`${isMobileDevice ? 'text-center' : 'text-end'}`}>
                      <Button
                        className={`position-relative rounded-circle btn-cart me-3 ${isMobileDevice ? 'bg-black border-white isMobileCartBagbtn' : ''}`}
                        variant="success"
                        disabled={showCart}
                        onClick={() => dispatch(setShowCart(true))}
                      >
                        <FontAwesomeIcon icon={faBagShopping} size={'1x' as SizeProp} className={`${isMobileDevice ? 'isMobileCartBag' : ''}`} />
                        <Badge
                          pill
                          bg='primary'
                          className='position-absolute top-md-0 start-md-100 translate-middle border border-white cart-badge'
                        >
                          {cartIsLoaded ? cart.getItemCount() : 0}
                        </Badge>
                      </Button>
                      {isMobileDevice &&
                        <Button
                          aria-controls="navbar-nav"
                          aria-label="Toggle navigation"
                          data-toggle="collapse"
                          onClick={() => { dispatch(setIsMobileNavToggled(true)) }}
                          className="p-0 bg-transparent border-0">
                          <span>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="18" cy="18" r="17.75" stroke="#fff" strokeWidth="1.5" />
                              <circle cx="18" cy="18" r="14" fill="#000" />
                              <path d="M13 18.3334H23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M13 15H23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M13 21.6666H23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </span>
                        </Button>}
                    </Col>
                  </Row>
                </div>}
              </>}
            {((!cart.isEmpty()) || (!cart.isEmpty() && (location.pathname === '/order' || location.pathname === '/autoship/menu'))) && isNotCheckout &&
              <div className='border-top pt-3 pb-4 pe-3'>
                <Row className='align-items-center'>
                  <Col xs={7} className="fs-12px ps-3 pe-0 text-white">
                    <div className='fs-22px'>
                      Subtotal: <span className='text-warning font-barlow'>
                        {cart.getSubtotal(null, null, null, true)}</span>
                    </div>
                    {(regShipLimit > 0 && regShipLimit > cart.getItemCount()) ?
                      <span>{regShipLimit} meals required to checkout.</span> :
                      mealCountOffer ?
                        <span>{mealCountOffer}</span> :
                        (utmThresholdValue !== "0" && !cart.hasProductWithCategory('gift-card', products) &&
                          Number(cart.getAmtForFreeShipping(false, shippingMethodbyZip[0]?.data.title, vanThreshold, upsThreshold, Number(utmThresholdValue))) > 0 ?
                          <span>{cart.getAmtForFreeShipping(true, shippingMethodbyZip[0]?.data.title, vanThreshold, upsThreshold, Number(utmThresholdValue))} Away from FREE DELIVERY</span> :
                          (utmThresholdValue !== "0" && !isUPSDeliveryThreshold && vanThreshold !== 0) &&
                          <span>FREE DELIVERY has been activated!</span>)
                    }
                  </Col>
                  <Col xs={4}>
                    {errorMsg &&
                      <Alert variant="danger" className='my-3'>{errorMsg}</Alert>}
                    <div className='d-grid'>
                      {isAutoShipOrder ?
                        <>
                          {orderInProgress ? <Button
                            className='checkout-btn bg-success'
                            disabled
                          >
                            Saving Changes ...&nbsp;
                            <Spinner
                              animation="border"
                              as="span"
                              size="sm"
                            />
                          </Button> :
                            <Button
                              className='checkout-btn bg-success'
                              onClick={submitAutoshipOrder}
                            >
                              Apply Changes
                            </Button>}
                        </> :
                        <Button
                          variant='success'
                          as={Link as any}
                          to={`/checkout${urlParams}`}
                          onClick={handleHide}
                        >
                          CHECKOUT
                        </Button>}
                    </div>
                  </Col>
                </Row>
              </div>}
          </div>
        </>
      </footer>
    </Container>
  );
}