import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Col, Container, Row, Spinner, Image } from 'react-bootstrap';
import { selectOrders } from "../features/orders/ordersSlice";
import OrderObj from '../features/orders/orderObj';
import { selectToken } from '../features/user/userSlice';
import OrderDetails from "../features/orders/OrderDetails";
import BillingAndShipping from "../features/orders/BillingAndShipping";
import './orderReceivedPage.scss';
import moment from 'moment';
import { ICartItems } from "../features/cart/interfaces";
import { selectCustomer } from '../features/customer/customerSlice';
import CustomerObj from '../features/customer/customerObj';
import { selectProducts } from '../features/products/productsSlice';
import { setCart } from '../features/cart/cartSlice';
import { selectGeneralOptions, selectIsMobileRoute } from '../features/mobile/mobileSlice';
import BucksCard from './MightyBucks/BucksPages/BucksCard';
import { setCalendarIndex, setNotifications, setOrderPlanType, setPreviousDate, setSelectedDate, setSelectedZipCode, setTurnOnAutoship } from './AutoshipPages/core/autoShipSlice';
import AutoShipAPI from './AutoshipPages/core/autoshipAPI';
import Window from '../utils/Window';
import { selectOrderPlannerOrders } from './AutoshipPages/core/orderPlannerOrdersSlice';

export default function OrderReceivedPage() {
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const products = useSelector(selectProducts);
  const orders = useSelector(selectOrders);
  const orderPlannerOrders = useSelector(selectOrderPlannerOrders);
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const [disableAutoship, setDisableAutoship] = useState('');
  const order = params.orderId ?
    OrderObj.findOrder(orders, Number(params.orderId)) : null;
  const mightyPoints = order ? order.getEarnedPoints() : 0;
  const deliveryDate = order ? order.getDeliveryDate() : null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isTimerOn, setIsTimerOn] = useState(false);
  const [placing, setPlacing] = useState(false);
  const [turningOn, setTurningON] = useState(false);
  const [remainingHours, setRemainingHours] = useState('');
  const [remainingMinutes, setRemainingMinutes] = useState('');
  const [remainingSeconds, setRemainingSeconds] = useState('');
  const [icsFilePath, setICSFilePath] = useState('');
  const [customerOrder, setCustomerOrder] = useState<ICartItems | null>(null);
  const custData = useSelector(selectCustomer);
  const AppURL = useSelector(selectIsMobileRoute);
  const QURLParams = AppURL ? `/?${AppURL}` : '/';
  const AndURLParams = AppURL ? `&${AppURL}` : '';
  const customer = useMemo(() => {
    return new CustomerObj(custData);
  }, [custData]);
  const couponCodes = order?.data?.coupon_lines?.map((item: any) => item.code) || [];
  const plannedOrders = orderPlannerOrders.filter((order: any) => {
    return order.status === 'autoship' || order.status === 'autoship-onhold';
  });
  const OrderPlannerImage = Window.isMobile() ?
    'https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-mobile.jpg' :
    'https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-desktop.jpg';
  const orderDate = order?.getDeliveryDateTime();
  const orderDateIndex = orderDate && orderDate.getDay();
  const zip = custData?.shipping?.postcode ? custData?.shipping?.postcode : custData?.billing?.postcode;
  const zipCode = order?.data.shipping.postcode ? order?.data.shipping.postcode :
    order?.data.billing.postcode ? order?.data.billing.postcode : zip;

  useEffect(() => {
    async function fetchData() {
      try {
        if (getGeneralOptions) {
          setDisableAutoship(getGeneralOptions?.disable_autoship_btn)
          if (getGeneralOptions.ics_file_path) {
            setICSFilePath(getGeneralOptions.ics_file_path);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const cartItems = order?.lineItemsToCartItems(products, customer);
    if (cartItems) {
      setCustomerOrder(cartItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!order?.data.date_paid_gmt) {
      setIsTimerOn(false);
      return;
    }

    if (!customer.hasMembership() && !(
      couponCodes.some((code: string) => /^(meal100|mighty100)\d*(-\d+)?$/.test(code)) ||
      couponCodes.some((code: string) => /^(meal100|mighty100)\d+-\d+$/.test(code))
    )
    ) {
      const countDownDate = moment.utc(order.data.date_paid_gmt).local().add(2, 'hours').toDate().getTime();

      const intervalId = setInterval(() => {
        setIsTimerOn(true);
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const hours = Math.floor(distance / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setRemainingHours(`${hours}`);
        setRemainingMinutes(`${minutes}`);
        setRemainingSeconds(`${seconds}`);

        if (distance < 0) {
          setIsTimerOn(false);
          clearInterval(intervalId);
          setRemainingHours('0');
          setRemainingMinutes('0');
          setRemainingSeconds('0');
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.data.date_paid_gmt]);

  useEffect(() => {
    if (!token) {
      navigate(`${QURLParams}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigate]);

  const handlePlaceOrder = async () => {
    setPlacing(true)
    if (customerOrder) {
      await dispatch(setCart({ token: token, cart_items: customerOrder }));
      navigate(`/checkout/?coupon-code=10offnextorder${AndURLParams}`)
    }
    setPlacing(false)
  }

  const shouldHideAutoShip = (lineItems: any) => {
    return lineItems.some((item: any) => item.name.startsWith("Mighty Bucks Gift Card") || item.name.startsWith('Gift Card') || item.name.startsWith('Donate Gift Gard'));
  };

  const hideAutoShip = shouldHideAutoShip(order?.data?.line_items);

  const handleTurnOnAutoship = async () => {
    if (plannedOrders.length <= 0) {
      setTurningON(true);
      try {
        await AutoShipAPI.postUserNotifications({
          userId: custData.id,
          autoshipAutoAddWeeks: '1',
          autoshipEmailNotifications: '1',
          autoshipTextMsgNotifications: '1',
        }).then((response) => {
          if (response.status === "success") {
            dispatch(setNotifications(response.data));
          }
        });
      } catch (e: any) {
        console.log(e, "Error while Turning on Autoship");
      } finally {
        dispatch(setTurnOnAutoship(true));
        dispatch(setOrderPlanType("pick_for_myself"));
        dispatch(setPreviousDate(orderDate));
        dispatch(setSelectedDate(orderDate));
        dispatch(setCalendarIndex(orderDateIndex));
        dispatch(setSelectedZipCode(zipCode));
        setTurningON(false);
      }
    }
    navigate(`/autoship${QURLParams}`);
  }

  return (
    <Container className='order-received-page mt-5'>
      {!disableAutoship && <>
        {!hideAutoShip && (
          <div className='autoShip'>
            {turningOn ?
              <div className="loader">
                <Spinner
                  variant="dark"
                  animation="border"
                  as="span"
                  className='spinner-xl my-25px'
                />
              </div> :
              <Image
                src={OrderPlannerImage}
                className='cursor-pointer'
                alt='orderPlanner'
                onClick={handleTurnOnAutoship} />}
          </div>
        )}
      </>}
      {
        isTimerOn ?
          (<Alert className='my-3 text-center text-white orderTimer' variant="success">
            <div className='PlaceNextOrder'>
              <Row className="align-items-center">
                <Col md={4} className=" first-col">
                  <span className='PlaceNextOrderText'>Place future orders within 2 hours and receive 10% OFF!</span>
                </Col>
                <Col md={5}>
                  <div className='ot-grid'>
                    <div className="ot-grid-box">
                      <span className='ot-grid-time'>{remainingHours}</span>
                      <span className='ot-grid-label'>HOURS</span>
                    </div>
                    <div className="ot-grid-box">
                      <span className='ot-grid-time'>{remainingMinutes}</span>
                      <span className='ot-grid-label'>MINUTES</span>
                    </div>
                    <div className="ot-grid-box">
                      <span className='ot-grid-time'>{remainingSeconds}</span>
                      <span className='ot-grid-label'>SECONDS</span>
                    </div>
                  </div>
                </Col>
                <Col md={3} className='d-flex justify-content-center align-items-center'>
                  <Button
                    onClick={handlePlaceOrder}
                    disabled={placing}
                    size="sm"
                    variant="dark"
                    className='mx-3'
                  >
                    {placing ?
                      (<>
                        Placing order ...
                        <Spinner
                          animation="border"
                          as="span"
                          size="sm"
                        />
                      </>)
                      : 'Reorder'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Alert>) :
          (<Alert className='thank-you-alert my-3 text-center text-white' variant="success">
            Thank you. Your order has been received.
          </Alert>)
      }
      {
        orders.length >= 4 &&
        <Container className='my-5 product-add'>
          <div className="adddetail px-3">
            <h4 className="head text-uppercase text-bold">introducing mightybucks</h4>
            <p>Purchase MightyBucks Gift Cards and Save More!</p>
          </div>
          <BucksCard />
        </Container>
      }
      <div className='text-center'>
        <div className='font-league-gothic fs-35px me-2 font-barlow'>Never Miss a Deadline!</div>
        <div className='fs-6 mb-3'>Add an order deadline reminder to your calendar.</div>
        <Button
          className='bg-black font-league-gothic text-inherit fs-23px mt-0 m-3'
          size="lg"
          disabled={icsFilePath?.length === 0 ? true : false}
          onClick={() => {
            window.open(`${icsFilePath}`);
          }}
        >
          Apple / Outlook
        </Button>
        <Button
          className='bg-black font-league-gothic text-inherit fs-23px mt-0 m-3'
          size="lg"
          onClick={() => {
            let deliveryDate = order?.getFullDeliveryDate();
            if (deliveryDate) {
              const startDate = new Date(deliveryDate);
              const endDate = new Date(deliveryDate);
              endDate.setMinutes(endDate.getMinutes() + 15);
              const formattedStartDate = startDate.toISOString().replace(/[:-]/g, '');
              const formattedEndDate = endDate.toISOString().replace(/[:-]/g, '');
              const calendarUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${formattedStartDate}/${formattedEndDate}&details=%F0%9F%9A%9A+Order+Deadline+is+Tonight+at+Midnight!+https://mightymeals.com/order%0A%F0%9F%93%9E+Order+with+an+expert+who+can+help+(833)+40+MEALS&text=MightyMeals+Deadline!&recur=RRULE:FREQ=WEEKLY;BYDAY=Mo,Th`;

              window.open(calendarUrl);
            }
          }}
        >
          Google Calendar
        </Button>
      </div>

      <Card className='my-5 border-0'>
        <Card.Header className='bg-white'>
          <div className='d-flex justify-content-between'>
            <div className='font-league-gothic fs-41px lh-50px me-2'>Your order</div>
            <Button
              className='float-end bg-black font-league-gothic fs-23px'
              size="lg"
              as={Link as any}
              to={`/my-account${QURLParams}`}
            >
              My Account
            </Button>
          </div>
        </Card.Header>
        {order &&
          <Card.Body className='fs-5'>
            {deliveryDate &&
              <Alert className='shadow-box bg-white text-black font-barlow fs-20px fw-700'>
                <div className='d-flex'>
                  <div className='p-3 px-0 fs-6 w-60'>DELIVERY DATE:</div>
                  <div className='p-3 px-0 fs-6 fw-bold w-50'>{order.getDeliveryDate()}</div>
                </div>
              </Alert>
            }
            <div className='d-flex'>
              <div className='p-3 w-40'>ORDER NUMBER:</div>
              <div className='p-3 fw-bold w-60'>{order.data.number}</div>
            </div>
            <div className='d-flex'>
              <div className='p-3 w-40'>EMAIL:</div>
              <div className='p-3 fw-bold w-60'>{order.data.billing.email}</div>
            </div>
            <div className='d-flex'>
              <div className='p-3 w-40'>TOTAL:</div>
              <div className='p-3 fw-bold w-60'>${order.data.total}</div>
            </div>
            <div className='d-flex'>
              <div className='p-3 w-40'>PAYMENT METHOD:</div>
              <div className='p-3 fw-bold w-60'>{order.data.payment_method_title}</div>
            </div>
            <OrderDetails order={order} />
            <BillingAndShipping order={order} />
            {mightyPoints > 0 &&
              <Alert className='shadow-box bg-white text-black font-barlow fs-20px'>
                You have earned <span className='fw-bold'>{mightyPoints}</span>
                &nbsp;Mighty Points for this order.
              </Alert>
            }
            {/* {deliveryDate &&
              <DeliveryDetails order={order} />
            } */}
          </Card.Body>}
      </Card>
    </Container >
  );
}